export const lg_joinmeetbtid = {
    lg_joinmeetbtid_th: {
      title: "เข้าร่วมประชุมได้อย่างง่ายดาย",
      subtitle1: "เชื่อมต่อได้จากทุกที่ด้วยแพลตฟอร์มประชุมของเรา ด้วยวิดีโอและเสียงคุณภาพสูง เพื่อการทำงานอย่างมีประสิทธิภาพ",
      subtitle2: "เพียงคลิกเพื่อเข้าร่วม แบ่งปันหน้าจอ ความคิด และทำงานร่วมกันในเวลาเดียวกัน",
      subtitle3: "ทั้งหมดนี้รวมอยู่ใน Dmeets และใช้งานได้ฟรี",
      formlabel:"เข้าร่วมประชุมด้วย Meeting ID",
      formplaceholder:"ระบุ Meeting ID",
      buttonjoin:"เข้าร่วม",
    },
    lg_joinmeetbtid_en: {
      title: "Join Meetings with Ease",
      subtitle1: "Effortlessly connect from anywhere with our seamless meeting platform. Enjoy high-quality video and audio to collaborate effectively.",
      subtitle2: "One click to join. Screen share, brainstorm, and work together in real-time.",
      subtitle3: "All in Dmeets and it's free to use.",
      formlabel:"Join Meeting with ID",
      formplaceholder:"Meeting ID",
      buttonjoin:"Join",
    },
  };
