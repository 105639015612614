<template>
  <!-- Loading Dialog -->
    <v-layout v-if="$store.state.loadMenu" row justify-center>
      <v-dialog
        v-model="$store.state.loadMenu"
        persistent
        fullscreen
        content-class="loading-dialog"
      >
        <v-container fill-height>
          <v-layout column justify-center align-center>
            <v-progress-circular
              indeterminate
              :size="50"
              color="primary"
            ></v-progress-circular>
            <h3 class="primary--text">Loading Data ...</h3>
          </v-layout>
        </v-container>
      </v-dialog>
    </v-layout>
    <!-- Loading Dialog -->
</template>

<script>
export default {
    name:"LoadingDialog"

}
</script>

<style>

</style>