<template>
  <v-container>
    <v-card class="mt-5 ml-5 mr-5">
      <v-card-title primary-title>
        {{ lg_verify.title1 }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form>
          <v-text-field :label="lg_verify.email1" v-model="email" outlined />

          <v-divider></v-divider>

          <!-- <span>{{ account }}</span> -->
          <v-row class="justify-space-between px-3 pt-5 pb-5">
            <v-btn outlined text @click.prevent="$router.push('/main-page')">{{
              lg_verify.btn_cancel1
            }}</v-btn>
            <v-btn
              type="submit"
              color="primary"
              @click.prevent="checkAndSendOTP"
              >{{ lg_verify.btn_confirm1 }}</v-btn
            >
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <v-card class="mt-5 ml-5 mr-5" v-if="otp_box">
      <v-card-title primary-title> {{ lg_verify.title2 }} </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form>
          <v-text-field :label="lg_verify.email2" v-model="email" outlined />
          <v-text-field :label="lg_verify.txt_otp" v-model="otp" outlined />

          <v-divider></v-divider>

          <!-- <span>{{ account }}</span> -->
          <v-row class="justify-space-between px-3 pt-5 pb-5">
            <v-btn outlined text @click.prevent="$router.push('/main-page')">{{
              lg_verify.btn_cancel2
            }}</v-btn>
            <v-btn type="submit" color="primary" @click.prevent="verifyOTP">
              {{ lg_verify.btn_confirm2 }}</v-btn
            >
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <LoadingDialog></LoadingDialog>

    <!-- Alert Dialog -->
    <v-dialog v-model="alertDlg" max-width="60%">
      <v-card>
        <v-card-title class="headline">{{
          this.titleMessageAlert
        }}</v-card-title>

        <v-card-text class="body">
          {{ this.messageAlert }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            text
            @click="alertDlg = false"
            col="12"
            lg="12"
            md="12"
            sm="12"
          >
            {{ lg_verify.dialog_btn_close }}
          </v-btn>

          <!-- <v-btn outlined color="error" text @click="confirmDelete">
            Confirm
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Alert Dialog -->
  </v-container>
</template>

<script>
import LoadingDialog from "@/components/cards/LoadingDialog.vue";
import api from "@/services/api";
// import { server } from "../services/constants";

import { feature } from "../services/constants";
import { lg_verify } from "./language/lg_verify";

export default {
  name: "verify-user",
  beforeCreate() {
    localStorage.getItem(feature.LANGUAGE) === "th"
      ? (this.lg_verify = lg_verify.verify_th)
      : (this.lg_verify = lg_verify.verify_en);
  },
  data() {
    return {
      resultJson: null,
      meet_id: null,
      meet_appid: null,
      email: "",
      otp: null,
      otp_sta: false,
      alertDlg: false,
      titleMessageAlert: "",
      messageAlert: "",
      otp_box: false,
      verify: {
        meet_id: "",
        ver_email: "",
        ver_otp: "",
        ver_expire: "",
      },
    };
  },
  created() {
    this.otp_box = false;
    this.meet_id = this.$route.params.meet_id;
    this.meet_appid = this.$route.params.meet_appid;
  },
  methods: {
    async checkAndSendOTP() {
      this.$store.state.loadMenu = true;

      // this.resultJson = await api.getAccountByEmail(this.email);
      // console.log(JSON.stringify(this.resultJson.data));

      // if (this.resultJson.data != null) {
      //   this.$store.state.verify_status = true;
      //   this.$router.push(`/meet/${this.meet_appid}/${this.meet_id}`);
      // } else {
      this.verify.meet_id = this.meet_id;
      this.verify.ver_email = this.email;
      this.verify.ver_otp = Math.floor(
        100000 + Math.random() * 900000
      ).toString();

      var d1 = new Date(),
        d2 = new Date(d1);
      d2.setMinutes(d1.getMinutes() + 5);
      // alert(d2.toString());
      this.verify.ver_expire = d2.toString();

      // console.log(this.verify);
      try {
        await api.deleteVerify(this.verify.meet_id, this.verify.ver_email);
      } catch (error) {
        console.log(error);
      }

      const res = await api.addVerify(this.verify);
      // console.log("res : " + res.status);
      if (res.status == 201) {
        //   this.titleMessageAlert = "Send OTP Success";
        //   this.messageAlert = "กรุณาตรวจ OTP ที่อีเมล์";
        //   this.alertDlg = true;
        const resotp = await api.sendOTP(this.verify);
        if (resotp.status == 200) {
          // this.titleMessageAlert = "Send OTP Success";
          // this.messageAlert = "กรุณาตรวจ OTP ที่อีเมล์";
          this.titleMessageAlert = this.lg_verify.dialog_popup_success;
          this.messageAlert = this.lg_verify.dialog_popup_success_message;
          this.alertDlg = true;
          this.otp_box = true;
        } else {
          // this.titleMessageAlert = "Send OTP Fail";
          // this.messageAlert = "กรุณาส่ง OTP ใหม่อีกครั้ง";
          this.titleMessageAlert = this.lg_verify.dialog_popup_fail;
          this.messageAlert = this.lg_verify.dialog_popup_fail_message;
          this.alertDlg = true;
        }
      } else {
        // this.titleMessageAlert = "Send OTP Fail";
        // this.messageAlert = "กรุณาส่ง OTP ใหม่อีกครั้ง";
        this.titleMessageAlert = this.lg_verify.dialog_popup_fail;
        this.messageAlert = this.lg_verify.dialog_popup_fail_message;
        this.alertDlg = true;
      }
      //}

      this.$store.state.loadMenu = false;
    },

    async verifyOTP() {
      const resOtp = await api.checkOTP(this.meet_id, this.email, this.otp);

      if (resOtp.data != null) {
        let dn = new Date();
        let dt = new Date(resOtp.data.ver_expire);
        dt.setHours(dt.getHours() + 7);
        dn.setHours(dn.getHours() + 7);
        // console.log(
        //   "dexp str: " + dt.toLocaleString("th-TH", { timeZone: "UTC" })
        // );

        dt = dt.toLocaleString("th-TH", { timeZone: "UTC" });
        dn = dn.toLocaleString("th-TH", { timeZone: "UTC" });

        // console.log("dexp dt: " + dt);
        // console.log("dexp dn: " + dn);

        if (dn < dt) {
          //   alert("Joint Meeting Success");
          this.$store.state.verify_status = true;
          this.$router.push(`/meet/${this.meet_appid}/${this.meet_id}`);
        } else {
          // this.titleMessageAlert = "OTP Expired";
          // this.messageAlert = "OTP หมดอายุ กรุณาส่ง OTP ใหม่อีกครั้ง";
          this.titleMessageAlert = this.lg_verify.dialog_popup_expire;
          this.messageAlert = this.lg_verify.dialog_popup_expire_message;
          this.alertDlg = true;
        }
      }
    },
  },
  components: {
    LoadingDialog,
  },
};
</script>

<style></style>
