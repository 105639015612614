export const lg_menu_mainpage = {
    lg_menu_mainpage_th: [
        ["mdi-account", "หน้าแรก", "/"],
        ["mdi-account-lock", "ฟีเจอร์", "/feature"],
        ["mdi-video", "ติดต่อเรา", "/contact"],
        ["mdi-file-video", "เข้าประขุมด้วย ID", "/joinmeetid"],
      ],
    lg_menu_mainpage_en: [
        ["mdi-home", "Home", "/"],
        ["mdi-file-plus", "Feature", "/feature"],
        ["mdi-phone-in-talk", "Contact", "/contact"],
        ["mdi-video", "Join Meeting with ID", "/joinmeetid"],
      ],
  };
  