<template>
  <v-container>
    <v-card class="mt-8">
      <v-data-table
        :search="search"
        :headers="lg_list_account.headers"
        :items="mDataArray"
        :items-per-page="10"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>{{ lg_list_account.title }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field
              v-model="search"
              append-icon="search"
              :label="lg_list_account.search"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>

        <template v-slot:item="{ item }">
          <tr :class="{ primary: item.id === selectedListUserDataId }">
            <td @click="editItem(item)">{{ item.id }}</td>

            <td @click="editItem(item)">{{ item.username }}</td>
            <td @click="editItem(item)">{{ item.acc_fullname }}</td>
            <td @click="editItem(item)">{{ item.acc_birth }}</td>
            <td @click="editItem(item)">{{ item.acc_id_card }}</td>
            <td @click="editItem(item)">{{ item.tbl_company.company_name }}</td>
            <td @click="editItem(item)">{{ item.role }}</td>
            <td @click="editItem(item)">{{ item.acc_plan }}</td>
            <td v-if="editable(role, item)">
              <v-btn class="mr-2" @click="notify(item)"> Notify </v-btn>
              <v-icon class="mr-2" @click="editItem(item)" color="green">
                edit
              </v-icon>
              <span class="ma-1"></span>
              <v-icon @click="deleteItem(item)" color="red"> delete </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>

      <v-dialog v-model="confirmDeleteDlg" width="unset">
        <v-card>
          <v-card-title class="headline">Confirm Delete</v-card-title>

          <v-card-text class="body">
            Are you sure to delete this user? You cannot restore it after
            clicking confirm.
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="confirmDeleteDlg = false"> Cancel </v-btn>

            <v-btn color="error" text @click="confirmDelete"> Confirm </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- <LoadingDialog></LoadingDialog> -->
    </v-card>
  </v-container>
</template>

<script>
import api from "@/services/api";
import LoadingDialog from "@/components/cards/LoadingDialog.vue";
import { server } from "../services/constants";

import { feature } from "../services/constants";
import { lg_list_account } from "./language/lg_list_account";

export default {
  name: "list-account",
  beforeCreate() {
    localStorage.getItem(feature.LANGUAGE) === "th"
      ? (this.lg_list_account = lg_list_account.list_account_th)
      : (this.lg_list_account = lg_list_account.list_account_en);
  },
  async mounted() {
    localStorage.setItem(server.MEET_STA, "false");
    this.$store.state.loadMenu = true;
    this.role = localStorage.getItem("role");
    this.company_id = localStorage.getItem("company_id");
    // if (this.role == "Admin") {
    //   this.headers = [
    //     {
    //       text: "Id",
    //       sortable: false,
    //       value: "id",
    //     },
    //     { text: "Email", value: "username" },
    //     { text: "FullName", value: "acc_fullname" },
    //     { text: "Birth", value: "acc_birth" },
    //     { text: "IdCard", value: "acc_id_card" },
    //     { text: "Company", value: "company_name" },
    //     { text: "Role", value: "role" },
    //     { text: "Plan", value: "acc_plan" },
    //     { text: "Action", value: "action" },
    //   ];
    // } else {
    //   this.headers = [
    //     {
    //       text: "Id",
    //       sortable: false,
    //       value: "id",
    //     },
    //     { text: "Email", value: "username" },
    //     { text: "FullName", value: "acc_fullname" },
    //     { text: "Birth", value: "acc_birth" },
    //     { text: "IdCard", value: "acc_id_card" },
    //     { text: "Company", value: "company_name" },
    //     { text: "Role", value: "role" },
    //   ];
    // }
    this.loadListUserData();
  },
  components: {
    LoadingDialog,
  },
  data() {
    return {
      search: "",
      role: "",
      company_id: 0,
      selectedListUserDataId: "",
      confirmDeleteDlg: false,
      mDataArray: [],
      headers: [],
    };
  },
  methods: {
    async loadListUserData() {
      this.mDataArray = [];
      let result = await api.listUserData(this.role, this.company_id);
      // console.log(result);
      // let count = result.data.count
      // for (let i=0;i<count;i++){
      //     this.mDataArray.push(result.data.rows.[i]);
      //     // console.log(result.data.rows.[i]);
      // }

      this.mDataArray = result.data.rows;

      this.$store.state.loadMenu = false;
    },
    editItem(item) {
      if (this.editable(this.role, item)) {
        // alert(`/account-edit/${item.id}`);
        this.$router.push(`/account-edit-admin/${item.id}`);
      }
    },
    editable(role, item) {
      let permitted = false;
      if (
        role === "Admin" ||
        (role === "Company Admin" &&
          item.role !== "Admin" &&
          item.tbl_company.id.toString() === this.company_id.toString())
      ) {
        permitted = true;
      }
      return permitted;
    },
    deleteItem(item) {
      this.selectedListUserDataId = item.id;
      this.confirmDeleteDlg = true;
      // alert(`/account-delete/${item.id}`);
    },
    async notify(item) {
      try {
        const expireDate = this.calculateExpireDate(item.created_at);
        this.$swal.showLoading();
        const payload = {
          name: item.acc_fullname,
          email: item.username,
          email_to: item.username,
          phone: "",
          subject: "Notification for Dmeets License",
          message: `ขอบคุณที่ใช้บริการ Dmeets at Law
            \n บัญชีของคุณต่อไปนี้จะหมดอายุเร็วๆนี
            \n [วันหมดอายุ]
            \n ${expireDate}
            \n [ชื่อบัญชี]
            \n ${item.acc_fullname}
            \n บัญชีข้างต้นยังไม่ได้ดำเนินการต่ออายุใช้งาน dmeets at law
            \n กรุณาติดต่อ เจ้าหน้าที่และต่ออายุวันนี้ที่ email : admin@dcm.co.th
            \n\nขอแสดงความนับถือ,
            \nadmin dcm \nc`,
        };
        // const mailResponse = await userRequest(payload);
        // this.$swal.close();
        // if (mailResponse && mailResponse.sent) {
        //   this.$swal.fire("Success", "User Notified Successfully", "success");
        // } else {
        //   this.$swal.fire("Error", mailResponse.text, "error");
        // }
      } catch (error) {
        this.$swal.close();
        this.$swal.fire("Error", error.message || error.text, "error");
      }
    },

    calculateExpireDate(created_at) {
      const created = new Date(created_at);
      created.setMonth(created.getMonth() + 3);
      return `${created.getDate()}/${
        created.getMonth() + 1
      }/${created.getFullYear()}`;
      // const today = new Date();
      // const difference_in_time = today.getTime() - created.getTime();
      // const difference_in_days = difference_in_time / (1000 * 3600 * 24);
      // const daysInt = Math.ceil(difference_in_days);
      // return daysInt;
      // this.daysLeft = daysInt >= 90 ? "Expired" : `${90 - daysInt} days left`;
    },
    async confirmDelete() {
      this.confirmDeleteDlg = false;
      // this.$store.state.loadMenu = true;
      const resdel = await api.deleteAccount(this.selectedListUserDataId);
      if(resdel.status == 200 || resdel.status == 201){
       await this.loadListUserData();
      }
    },
  },
};
</script>

<style></style>
