<template>
  <div>
    <div class="set-profile" v-if="!token">
      <v-card
        :loading="loading"
        class="mx-auto my-12"
        width="500"
        max-width="500"
        v-if="showPasswordForm"
      >
        <v-card-title>{{ lg_meet_page.input_password }}</v-card-title>

        <v-card-text>
          <form @submit.prevent="onSubmitProfileForm">
            <!-- <v-text-field
              v-model="profileForm.name"
              label="ชื่อ"
              outlined
              required
            ></v-text-field> -->

            <v-text-field
              v-model="profileForm.password"
              :label="lg_meet_page.label_password"
              outlined
              type="password"
              autocomplete="new-password"
            ></v-text-field>
            <v-btn color="primary" type="submit" block>{{
              lg_meet_page.btn_save
            }}</v-btn>
          </form>
        </v-card-text>
      </v-card>
    </div>
    <div id="jaas-container"></div>
    <div v-if="this.voteFeature">
      <vote-container
        :roomId="roomId"
        :room="room"
        :userId="userId"
        :user="displayname"
      />
    </div>

    <!-- <div v-if="this.voteFeature">
      <vote-container
        v-if="moderator && token && room"
        :roomId="roomId"
        :room="room"
        :userId="userId"
        :user="displayname"
      />
    </div> -->

    <question-dialog
      :roomId="roomId"
      :userId="userId"
      :visible="isQuestionDialogVisible"
      :questions="questions"
      @close="onCloseQuestionDialog"
      :user="displayname"
    />
    <vote-report-dialog
      :visible="isVoteReportVisible"
      :vote="voteReportData"
      :hideSendButton="true"
      :hideStatus="true"
      @close="closeVoteReport"
    />

    <v-dialog v-model="notAvailableDialog" width="500" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Warning ! </v-card-title>

        <v-container style="text-align: center">
          <v-card-text style="padding: 10px; text-align: left">
            The meeting room is now full of users. Please try again or contact
            Admin.
          </v-card-text>
          <v-btn color="primary" @click="fetchRoomStatus">Try Again</v-btn>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable no-undef */
// import axios from "axios";
import firebase from "firebase/app";
import { isPast, differenceInSeconds } from "date-fns";
import api from "@/services/api";
import { server } from "../services/constants";
import { feature } from "../services/constants";
import jwt_decode from "jwt-decode";
import VoteContainer from "../components/core/VoteContainer.vue";
import QuestionDialog from "../components/core/QuestionDialog.vue";
import VoteReportDialog from "../components/core/VoteReportDialog.vue";
import { getRoomStatus } from "../services/api_jitsi";
import { lg_meet_page } from "./language/lg_meet_page";
import { getByMeetNameOnly } from "@/services/api_meetroom";
export default {
  components: {
    VoteContainer,
    QuestionDialog,
    VoteReportDialog,
  },
  async  beforeCreate() {
    localStorage.getItem(feature.LANGUAGE) === "th"
      ? (this.lg_meet_page = lg_meet_page.meet_page_th)
      : (this.lg_meet_page = lg_meet_page.meet_page_en);
      // this.$router.push("/main-page");
      let url = window.location.href
      let result = url.includes("vpaas-magic-cookie-e205800afeca4002aad705d1994953cb");
       let host = (url.split('/')[0] +"//" +url.split('/')[2]);
      let meetId = url.split('/')[5];
     try{
      if(result && meetId){
          const res = await getByMeetNameOnly(meetId);
        const {
          data: { meet_appid, meet_sta },
        } = res;
        if (meet_sta !== "Y") {
           this.$store.state.checklinkmeeting = true;
            
this.$router.push("/main-page");
       
        }
      }else{
    this.$router.push("/main-page");
       
      }
     }catch(e){
       this.$store.state.checkidmeeting = true;
       this.$router.push("/main-page");
     }
     
  },
  data: () => ({
    profileForm: { name: "", email: "" },
    loading: false,
    token: null,
    showPasswordForm: false,
    roomId: null,
    room: null,
    moderator: false,
    displayname: null,
    userId: null,
    subject: null,
    watchEvent: null,
    isMounted: false,
    questions: [],
    isQuestionDialogVisible: false,
    reportWatcher: null,
    answeredQuestions: [],
    isVoteReportVisible: false,
    voteReportData: null,
    voteFeature: false,
    meet_lobby_sta: "N",
    resultJson: "",
    responseMeet: "",
    toolbarButtonsConfig: [],
    notAvailableDialog: false,
    appId: "",
    log_host2: {
      log_meet_id: "",
      log_end_time: null,
    },
    log_host: {
      log_meet_id: "",
      log_meet_topic: "",
      log_host_name: "",
      log_host_email: "",
      log_user_type: "",
      log_start_time: "",
      log_end_time: null,
      log_duration: 0,
      log_participant: 0,
      log_host_data_center: "Bangkok (TH)",
      log_video: false,
      log_screen_sharing: true,
      log_recording: true,
      log_encryption: true,
    },
    log_participant: {
      log_meet_id: "",
      log_participant_name: "",
      log_enpoint_type: "WEB",
      log_os: "",
      log_ip_address: "",
      log_location: "",
      log_network_type: "",
      log_isp: "",
      log_mic: "",
      log_speaker: "",
      log_camera: "",
      log_data_center: "Bangkok (TH)",
      log_connect_type: "SSL",
      log_join_time: "",
      log_leave_time: null,
      log_version: "",
    },
    record_link: {
      meet_id: "",
      rec_link: "",
    },
    log_host_id: 0,
    log_participant_id: 0,
  }),
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    closeVoteReport() {
      this.isVoteReportVisible = false;
      this.voteReportData = null;
    },
    onCloseQuestionDialog(question) {
      if (this.questions.length === 1) {
        this.questions = [];
        this.isQuestionDialogVisible = false;
      } else {
        this.questions = this.questions.filter((q) => q.id !== question.id);
      }
    },
    setToken(token) {
      const decoded = jwt_decode(token);
      const { context } = decoded;

      this.displayname = context?.user?.name;
      this.userId = context?.user?.id;
      this.token = token;
      this.subject = context?.subject;
    },
    setCurrentUser() {
      let storageName = localStorage.getItem(server.FULLNAME);
      let storeName = this.$store.getters["fullname"];
      let isLogin = this.$store.getters["isLogin"];
      this.profileForm.name = isLogin
        ? storeName
        : storageName
        ? storageName
        : "";
    },
    async onSubmitProfileForm() {
      try {
        // this.loading = true;
        // const { roomId } = this;
        // const {
        //   query: { moderator },
        // } = this.$route;
        // localStorage.setItem(server.MEET_STA, "true");

        // this.setCurrentUser();
        // const res = await api.meetGenerateJWTToken({
        //   ...this.profileForm,
        //   roomId,
        //   moderator: moderator && moderator === "true" ? "true" : "false",
        // });
        // const { token } = res.data;
        // localStorage.setItem(server.MEET_STA, "false");
        // localStorage.setItem("jwt", token);
        // this.initJitsi(token);
        this.generateJWTToken();

        /*this.loading = true;
        const { roomId } = this;
        const {
          query: { moderator },
        } = this.$route;
        localStorage.setItem(server.MEET_STA, "true");
        console.log("token222", "onSubmitProfileForm");
        this.setCurrentUser();

        console.log("token222", "setCurrentUser");

        //moderator && moderator === "true" ? "true" : "false"
        const res = await api.meetGenerateJWTToken({
          ...this.profileForm,
          roomId,
          moderator: localStorage.getItem(server.MODERATOR_STA),
        });

        const { token } = res.data;

        console.log("token222", token);

        localStorage.setItem(server.MEET_STA, "false");
        localStorage.setItem("jwt", token);
        this.initJitsi(token);*/
        this.loading = false;
      } catch (error) {
        this.loading = false;

        this.$swal.close();
        if (error?.response?.data?.message === "ROOM_NOT_FOUND") {
          this.$swal.fire("Error", "URL ไม่ถูกต้อง", "error");
          // this.$router.push("/");
        } else if (error?.response?.data?.message === "INCORRECT_PASSWORD") {
          this.$swal.fire("Error", "รหัสผ่าน ไม่ถูกต้อง", "error");
        } else if (
          error?.response?.data?.message === "data and hash arguments required"
        ) {
          console.log("data and hash arguments required");
        } else {
          this.$swal.fire(
            "Error",
            error?.response?.data?.message ?? error.message,
            "error"
          );
        }
      }
    },
    async generateJWTToken() {
      try {
        const {
          params: { roomName },
          // query: { moderator },
        } = this.$route;
        this.loading = true;

        const { roomId } = this;

        //console.log("token111 : ", "setCurrentUser");
        localStorage.setItem(server.MEET_STA, "true");
        this.setCurrentUser();

        //moderator: moderator && moderator === "true" ? "true" : "false",
        console.log("token111 : ", "meetGenerateJWTToken");
        const res = await api.meetGenerateJWTToken({
          ...this.profileForm,
          moderator: localStorage.getItem(server.MODERATOR_STA),
          roomId,
        });

        console.log("token111 : ", res.data);

        const { token } = res.data;
        localStorage.setItem(server.MEET_STA, "false");
        localStorage.setItem("jwt", token);

        this.initJitsi(token);
        this.loading = false;
      } catch (error) {
        this.loading = false;

        console.log(error);
      }
    },
    async initJitsi(token) {
      await this.setToken(token);
      await this.$loadScript("https://8x8.vc/external_api.js");
      let jwt = token;
      if (!token) {
        jwt = localStorage.getItem("jwt");
        if (!jwt) return;
      }

      const decoded = jwt_decode(token);
      const { context } = decoded;
      const { user } = context;
      const { name } = user;

      // console.log("log_host decoded:", JSON.stringify(decoded));

      if (
        localStorage.getItem(server.USERNAME) != null ||
        localStorage.getItem(server.USERNAME) != ""
      ) {
        this.log_host.log_host_name = localStorage.getItem(server.FULLNAME);
        this.log_participant.log_participant_name = localStorage.getItem(
          server.FULLNAME
        );
        this.log_host.log_host_email = localStorage.getItem(server.USERNAME);
      } else if (name != null || name != "") {
        this.log_host.log_host_name = name;
        this.log_host.log_host_email = name;
        this.log_participant.log_participant_name = name;
      } else {
        this.log_host.log_host_name = "DmeetsUser";
        this.log_host.log_host_email = "DmeetsUser";
        this.log_participant.log_participant_name = "DmeetsUser";
      }

      console.log("log_host log_host_name:", this.log_host.log_host_name);
      console.log("log_host log_host_email:", this.log_host.log_host_email);

      const {
        params: { appId, roomName },
      } = this.$route;

      this.toolbarButtonsConfig = [
        "camera",
        "chat",
        "desktop",
        "download",
        "embedmeeting",
        "etherpad",
        "feedback",
        "filmstrip",
        "fullscreen",
        "hangup",
        "help",
        "invite",
        "livestreaming",
        "microphone",
        "participants-pane",
        "profile",
        "raisehand",
        "select-background",
        "settings",
        "shareaudio",
        "sharedvideo",
        "shortcuts",
        "stats",
        "tileview",
        "toggle-camera",
        "videoquality",
        "__end",
      ];

      /*FEATURE_LOBBY: `feature_lobby`,
        FEATURE_RECORD: `feature_record`,
        FEATURE_SUBTITLE: `feature_subtitle`,
        FEATURE_VOTE: `feature_vote`,
        FEATURE_VOTE_REPORT: `feature_vote_report`,
        FEATURE_MUTE_EVERYONE: `feature_mute-everyone`,
        FEATURE_MUTE_VIDEO_EVERYONE: `feature_mute-video-everyone`,*/

      //LOBBY
      if (
        localStorage.getItem(feature.FEATURE_LOBBY) === "true" ||
        localStorage.getItem(server.ROLE) === "Admin"
      ) {
        this.toolbarButtonsConfig.push("security");
      }

      //RECORD
      if (
        localStorage.getItem(feature.FEATURE_RECORD) === "true" ||
        localStorage.getItem(server.ROLE) === "Admin"
      ) {
        this.toolbarButtonsConfig.push("recording");
      }

      //SUBTITLE
      if (
        localStorage.getItem(feature.FEATURE_SUBTITLE) === "true" ||
        localStorage.getItem(server.ROLE) === "Admin"
      ) {
        this.toolbarButtonsConfig.push("closedcaptions");
      }

      //MUTE_EVERYONE // MUTE_VIDEO_EVERYONE
      if (
        localStorage.getItem(feature.FEATURE_MUTE_EVERYONE) === "true" ||
        localStorage.getItem(server.ROLE) === "Admin"
      ) {
        this.toolbarButtonsConfig.push("mute-everyone");
        this.toolbarButtonsConfig.push("mute-video-everyone");
      }

      const api_jass = new JitsiMeetExternalAPI("8x8.vc", {
        roomName: `${appId}/${roomName}`,
        parentNode: document.querySelector("#jaas-container"),
        jwt: token,
        configOverwrite: {
          toolbarButtons: this.toolbarButtonsConfig,
          apiLogLevels: ["error"],
          subject: this.subject || roomName,
        },
      });

      if (this.meet_lobby_sta == "Y") {
        api_jass.addListener("participantRoleChanged", (data) => {
          if (data.role === "moderator") {
            api_jass.executeCommand("toggleLobby", true);
          }
        });
      }

      api_jass.addListener("readyToClose", () => {
        if (localStorage.getItem(server.MODERATOR_STA) === "true") {
          //HOST
          console.log("log_host close:" + "click close");
          var de = new Date();
          this.log_host2.log_end_time = de;

          console.log("log_host log_end_time:" + this.log_host2.log_end_time);
          console.log("log_host log_host:" + JSON.stringify(this.log_host2));
          api.updateLogHost(this.log_host2.log_meet_id, this.log_host2);
        } else {
          //PARTICIPANT
          var dl = new Date();
          this.log_participant.log_leave_time = dl;

          api.updateLogParticipant(
            this.log_participant_id,
            this.log_participant
          );
        }

        this.$store.state.meetSta = !this.$store.state.meetSta;
        this.$store.state.verify_status = false;
        localStorage.removeItem("jwt");
        this.$router.push("/create-meet-room");
        this.signOut();
      });

      api_jass.addListener("recordingLinkAvailable", (data) => {
        // const { recordingLinkAvailable } = data;

        // console.log("recordingLinkAvailable:" + recordingLinkAvailable);
        console.log("recordingLinkAvailable:" + JSON.stringify(data));
        this.record_link.rec_link = data.link;
        const res_add_record_link = api.addRecordLink(this.record_link);
        console.log(
          "res_add_record_link:" + JSON.stringify(res_add_record_link.data)
        );
      });

      api_jass.addListener("displayNameChange", (data) => {
        const { displayname } = data;
        this.displayname = displayname;

        this.registerMember();
      });

      await api_jass.getAvailableDevices().then((devices) => {
        // console.log("log_participant devices:", JSON.stringify(devices));
        // console.log("log_participant devices:", devices.audioInput);
        // console.log("log_participant devices:", devices.videoInput);
        devices.audioInput.forEach((device) => {
          // console.log("log_participant audioInput:", device.label);
          this.log_participant.log_mic = device.label;
          this.log_participant.log_speaker = device.label;
        });

        devices.videoInput.forEach((device) => {
          // console.log("log_participant videoInput:", device.label);
          this.log_participant.log_camera = device.label;
        });
      });

      // Save Log Data
      if (localStorage.getItem(server.MODERATOR_STA) === "true") {
        //HOST
        // console.log("log_host res_check:", this.log_host.log_meet_id);
        this.log_host.log_user_type = localStorage.getItem(server.ROLE);
        try {
          const res_check = await api.getByMeetId(this.log_host.log_meet_id);
          console.log("log_host res_check:", JSON.stringify(res_check));
        } catch (err) {
          // Add Host
          console.log("log_host res_check:", "404");
          var today = new Date();
          this.log_host.log_start_time = today;
          console.log("log_host log_start_time:", this.log_host.log_start_time);
          await api.addLogHost(this.log_host);
        }
      } else {
        //PARTICIPANT
        //console.log("log_participant log_meet_id:", this.log_host.log_meet_id);

        const res_ipregistry = await api.getLogRegisInfo();
        // console.log(
        //   "log_participant res_ipregistry:",
        //   JSON.stringify(res_ipregistry.data)
        // );

        // console.log("log_participant res_check:", "404_1");
        // console.log(
        //   "log_participant res_check:",
        //   JSON.stringify(res_ipregistry.data.user_agent.header)
        // );

        this.log_participant.log_os = res_ipregistry.data.user_agent.header;
        this.log_participant.log_ip_address = res_ipregistry.data.ip;
        // console.log("log_participant res_check:", "404_2");
        this.log_participant.log_location =
          res_ipregistry.data.location.country.name +
          " " +
          res_ipregistry.data.location.country.capital +
          " " +
          res_ipregistry.data.location.country.code;
        // console.log("log_participant res_check:", "404_3");
        this.log_participant.log_network_type = res_ipregistry.data.type;
        this.log_participant.log_isp =
          res_ipregistry.data.company.name +
          " " +
          res_ipregistry.data.connection.organization;

        // console.log("log_participant res_check:", "404_9");
        var today_p = new Date();
        this.log_participant.log_join_time = today_p;
        // console.log("log_participant res_check:", "404_10");
        if (this.log_participant.log_participant_name == null) {
          this.log_participant.log_participant_name = "Dmeets Link User";
        }
        const res_ptcp = await api.addLogParticipant(this.log_participant);

        if (res_ptcp.status == 201) {
          // get id
          const res_getid = await api.getLPByMeetId(
            this.log_participant.log_meet_id,
            this.log_participant.log_ip_address
          );
          this.log_participant_id = res_getid.data.id;
          // console.log(
          //   "log_participant log_participant_id:",
          //   this.log_participant_id
          // );
        }
      }

      await this.registerMember();
      this.startEventWatcher();
    },
    async registerMember() {
      const currentUser = firebase.auth().currentUser;
      if (!currentUser?.uid) {
        await firebase.auth().signInAnonymously();
      }

      const membersDB = firebase.firestore().collection("members");
      const { roomId, userId, displayname, moderator } = this;
      membersDB.doc(userId).set({
        roomId,
        userId,
        displayname,
        moderator: moderator === "true" ? true : false,
      });
    },
    startEventWatcher() {
      this.watchEvent = firebase
        .firestore()
        .collection(`events`)
        .doc(this.roomId)
        .onSnapshot(async (snapshot) => {
          const data = snapshot.data();
          const { createdDate, userId } = data;

          const seconds = createdDate?.seconds;
          if (!seconds) return;

          const date = new Date(seconds * 1000);
          const diff = differenceInSeconds(new Date(), date);
          if (diff > 5) return;
          const { type } = data;

          if (type === "SEND_VOTE") {
            const { vote } = data;
            const { answeredQuestions } = this;

            if (answeredQuestions.includes(vote.id)) {
              return;
            }

            // check current user is not sender

            if (this.userId === userId) return;
            this.isQuestionDialogVisible = true;
            if (!this.questions?.map((q) => q.id)?.includes(vote.id)) {
              this.questions = [...this.questions, vote];
            }
          }

          if (type === "SHOW_REPORT") {
            const { userId, vote } = data;

            // check current user is not sender
            if (userId === this.userId) return;
            this.isVoteReportVisible = true;
            this.voteReportData = vote;
          }
        });

      this.reportWatcher = firebase
        .firestore()
        .collection("vote-report")
        .where("userId", "==", this.userId)
        .onSnapshot(async (snapshot) => {
          const { docs } = snapshot;
          this.answeredQuestions = await Promise.all(
            docs.map(async (doc) => {
              const data = await doc.data();
              return data.questionId;
            })
          );
        });
    },
    async fetchRoom() {
      try {
        this.$swal.showLoading();

        const {
          params: { roomName: roomId },
          query: { moderator },
        } = this.$route;

        this.moderator = moderator;
        this.roomId = roomId;

        this.resultJson = await api.getByMeetNameOnly(roomId);
        if (this.resultJson && this.resultJson.data) {
          this.room = this.resultJson.data;
        }

        this.log_host.log_meet_topic = this.resultJson.data.meet_topic;

        // console.log("log_host", JSON.stringify(this.resultJson.data));
        console.log("log_host log_meet_topic:", this.log_host.log_meet_topic);

        console.log(`created: ${JSON.stringify(this.resultJson.data)}`);

        this.meet_lobby_sta = this.resultJson.data.meet_lobby_sta;
        // console.log(`created : ${this.meet_lobby_sta}`);

        localStorage.setItem(server.MEET_STA, "true");
        const res = await api.getMeetRoom(roomId);
        localStorage.setItem(server.MEET_STA, "false");

        this.$swal.close();
        return res.data;
      } catch (error) {
        this.$swal.close();
        if (error?.response?.data?.message === "ROOM_NOT_FOUND") {
          this.$swal.fire("Error", "URL ไม่ถูกต้อง", "error");
          this.$router.push("/");
        } else {
          this.$swal.fire(
            "Error",
            error?.response?.data?.message ?? error.message,
            "error"
          );
        }
      }
    },
    async signOut() {
      const { userId } = this;
      await firebase.firestore().collection("members").doc(userId).delete();
      firebase.auth().signOut();

      if (this.watchEvent) {
        this.watchEvent();
      }

      if (this.reportWatcher) {
        this.reportWatcher();
      }
    },
    checkPasswordAndGenToken(room) {
      const { password, id: roomId } = room;

      if (!password) {
        this.generateJWTToken();
      } else {
        this.showPasswordForm = true;
        this.roomId = roomId;
      }
    },
    async fetchRoomStatus() {
      const {
        params: { roomName },
      } = this.$route;
      const res = await getRoomStatus(roomName);
      const { data } = res;
      this.notAvailableDialog = !data.available;

      console.log(data.available, "<---- data.available");
      if (!data.available) {
        return;
      }

      console.log("this.isMobile():" + this.isMobile());
      if (this.isMobile() == true) {
        window.location.href = `https://apidmeets.dcm.co.th/weblink/deeplink/${this.$route.params.appId}/${this.$route.params.roomName}`;
      } else {
        window.addEventListener("beforeunload", this.signOut);
        const room = await this.fetchRoom();
        if (!room) {
          this.$router.push("/");
          return;
        }
        const token = localStorage.getItem("jwt");
        if (token) {
          console.log("token: " + "is token");
          const {
            params: { roomName },
          } = this.$route;
          const decoded = jwt_decode(token);
          const { exp } = decoded;
          const expDate = new Date(exp * 1000);
          const invalidToken = isPast(expDate);
          console.log(decoded);

          if (!invalidToken && decoded && decoded.room) {
            if (decoded.room === roomName) {
              this.initJitsi(token);
              return;
            }
          }
          return this.checkPasswordAndGenToken(room);
        } else {
          console.log("token: " + "checkPasswordAndGenToken");
          return this.checkPasswordAndGenToken(room);
        }
      }
    },
  },
  mounted() {
    console.log("mounted");

    // this.$store.state.meetSta = !this.$store.state.meetSta;
    this.$store.state.navMenu = false;
  },
  computed: {},

  async created() {
    console.log("created");

    this.$store.state.meetSta = !this.$store.state.meetSta;
    this.$store.state.navMenu = false;

    const {
      params: { roomName: roomId },
      query: { moderator },
    } = this.$route;

    this.moderator = moderator;
    this.roomId = roomId;
    this.appId = this.$route.params.appId;

    this.log_host.log_meet_id = roomId;
    this.log_host2.log_meet_id = roomId;
    this.record_link.meet_id = roomId;
    this.log_participant.log_meet_id = roomId;

    console.log("log_host log_meet_id:", this.log_host.log_meet_id);
    console.log("log_participant log_meet_id:", this.log_host.log_meet_id);

    this.resultJson = await api.getByMeetNameOnly(roomId);
    // if (this.resultJson && this.resultJson.data) {
    //   this.room = this.resultJson.data;
    // }

    // console.log(`room created: ${JSON.stringify(this.resultJson.data)}`);

    this.acc_plan = this.resultJson.data.meet_room_license;

    this.responseMeet = await api.getByMeetNameOnly(roomId);

    console.log(`check_verify : ${JSON.stringify(this.responseMeet.data)}`);

    if (this.isMobile() == false) {
      if (
        this.responseMeet.data.meet_verify_sta == "Y" &&
        this.$store.state.verify_status == false
      ) {
        this.$store.state.meetSta = !this.$store.state.meetSta;
        this.$router.push(`/verify-user/${this.appId}/${roomId}`);
      }
    }
    const response_package = await api.getPackageConfig(this.acc_plan);

    // console.log(`package config : ${JSON.stringify(response_package.data)}`);

    this.mDataPackageArray = response_package.data;
    let count = this.mDataPackageArray.length;

    for (let i = 0; i < count; i++) {
      let key = this.mDataPackageArray[i].key;

      if (key == "WAITING_ROOM") {
        localStorage.setItem(
          feature.FEATURE_LOBBY,
          this.mDataPackageArray[i].valueBool
        );
      } else if (key == "RECORDING") {
        localStorage.setItem(
          feature.FEATURE_RECORD,
          this.mDataPackageArray[i].valueBool
        );
      } else if (key == "SUBTITLE") {
        localStorage.setItem(
          feature.FEATURE_SUBTITLE,
          this.mDataPackageArray[i].valueBool
        );
      } else if (key == "VOTE") {
        localStorage.setItem(
          feature.FEATURE_VOTE,
          this.mDataPackageArray[i].valueBool
        );
      } else if (key == "VOTE_REPORT") {
        localStorage.setItem(
          feature.FEATURE_VOTE_REPORT,
          this.mDataPackageArray[i].valueBool
        );
      } else if (key == "CONTROL_MIC_CAMERA") {
        localStorage.setItem(
          feature.FEATURE_MUTE_EVERYONE,
          this.mDataPackageArray[i].valueBool
        );
      }
    }

    // console.log(`package config vote :${localStorage.getItem(feature.FEATURE_VOTE)}`);

    if (
      localStorage.getItem(feature.FEATURE_VOTE) === "true" ||
      localStorage.getItem(feature.FEATURE_VOTE) == true
    ) {
      this.voteFeature = true;
    } else {
      this.voteFeature = false;
    }

    this.fetchRoomStatus();
  },
  beforeDestroy() {
    this.$store.state.verify_status = false;

    if (this.reportWatcher) {
      this.reportWatcher();
    }
    if (this.watchEvent) {
      this.watchEvent();
    }
  },
};
</script>

<style scoped>
html,
body,
#jaas-container {
  height: 100vh;
  background: #000;
}

.set-profile {
  height: 100vh;
  background: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.app-logo {
  width: 150px;
}
</style>
