import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getAllMeetJoin = () => {
  return httpClient.get(server.MEETROOM_JOIN_URL);
};

export const getMeetJoinByID = (id) => {
  return httpClient.get(server.MEETROOM_JOIN_URL + `/id/${id}`);
};

export const checkByNameMeetJoin = (meet_join_name) => {
  return httpClient.get(
    server.MEETROOM_JOIN_URL + `/checkbyname?meet_join_name=${meet_join_name}`
  );
};

export const getAllMeetJoinByMeetId = (meet_id) => {
  return httpClient.get(
    server.MEETROOM_JOIN_URL + `/getmeetdetail?meet_id=${meet_id}`
  );
};

export const checkNameForUpdateMeetJoin = (id, meet_join_name) => {
  return httpClient.get(
    server.MEETROOM_JOIN_URL +
      `/checknameforupdate?id=${id}&meet_join_name=${meet_join_name}`
  );
};

export const addMeetJoin = (data) => {
  return httpClient.post(server.MEETROOM_JOIN_URL, data);
};

export const deleteMeetJoin = (id) => {
  return httpClient.delete(server.MEETROOM_JOIN_URL + `/${id}`);
};

export const updateMeetJoin = (id, data) => {
  return httpClient.put(server.MEETROOM_JOIN_URL + `/${id}`, data);
};
