<template>
  <v-footer  dark padless app absolute inset
      height="70">
    <!-- <v-card class="flex" color="#078fc3" flat tile>
      <v-card-text class="py-2 white--text text-center">
        <h3>Powered by DCM © 2021</h3>
      </v-card-text>
    </v-card> -->
    <!-- 
     <router-link  color="white" to="/account-detail">Contact</router-link> -->

    <v-card class="flex" flat tile>
      <v-card-text :class="textposition" class="footer-wrapper">
        <!-- <v-btn
          color="white"
          text
          rounded
          class="my-2"
          href="https://www.dcm.co.th/wp-content/uploads/2021/12/DCMPrivacyPolicy.pdf"
          target="_blank"
          >{{ this.lg_footer.privacy_policy }}</v-btn
        > -->

        <v-btn
          color="white"
          text
          rounded
          class="my-2"
          @click="showPrivacyPolicy_1"
          target="_blank"
          >{{ this.lg_footer.privacy_policy }}</v-btn
        >
        <!-- <v-btn
          color="white"
          text
          rounded
          class="my-2"
          href="https://www.dcm.co.th/wp-content/uploads/2021/12/InformationSecurityPolicy.pdf"
          target="_blank"
          >{{ this.lg_footer.information_security_policy }}</v-btn
        > -->

        <v-btn
          color="white"
          text
          rounded
          class="my-2"
          @click="showInformationSecurityPolicy_2"
          target="_blank"
          >{{ this.lg_footer.information_security_policy }}</v-btn
        >

        <!-- <v-btn
          color="white"
          text
          rounded
          class="my-2"
          href="https://www.dcm.co.th/wp-content/uploads/2021/12/InformationSecurityPolicy.dmeetsdocx.pdf"
          target="_blank"
          >{{ this.lg_footer.information_technology_security_policy }}
        </v-btn> -->

        <v-btn
          color="white"
          text
          rounded
          class="my-2"
          @click="showTermandConditions_3"
          target="_blank"
          >{{ this.lg_footer.information_technology_security_policy }}
        </v-btn>

        <!-- <v-btn
          color="white"
          text
          rounded
          class="my-2"
          href="https://www.dcm.co.th/wp-content/uploads/2021/12/TermandConditions.pdf"
          target="_blank"
          >{{ this.lg_footer.terms_and_conditions }}</v-btn
        > -->

        <v-btn
          color="white"
          text
          rounded
          class="my-2"
          @click="showDmeetsdocx_4"
          target="_blank"
          >{{ this.lg_footer.terms_and_conditions }}</v-btn
        >

        <v-btn
          color="white"
          text
          rounded
          class="my-2"
          @click.prevent="$router.push('/User-request')"
          >{{ this.lg_footer.request_form }}</v-btn
        >
      </v-card-text>

      <v-card-text class="py-0 white--text text-center">
        <strong>Powered by DCM ©</strong>-{{ new Date().getFullYear() }}
        <strong>Version {{ this.version }}</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
// import api from "../../services/api";
import { server } from "../../services/constants";
import { feature } from "../../services/constants";
import { lg_footer } from "../../views/language/lg_footer.js";

export default {
  name: "Footer",
  data() {
    return {
      title: "Footer",
      version: "1.0",
      acc_plan: "FREE",
      date_exire: "",
    };
  },
  created() {
    this.getVersion();
    // console.log("Footer created:" + this.getAccPlan());
  },
    computed: {
  textposition() {
    if(this.$vuetify.breakpoint.name === "xs"){
 return "py-0 white--text";
    }else{
       return "py-0 white--text text-center";
    }
  
    },
    },
  methods: {
    showPrivacyPolicy_1() {
      // window.open(`http://${window.location.host}/1.DCMPrivacyPolicy.pdf`);
      window.open(`http://${window.location.host}/privacy-policy`);
      // alert(window.location.host);
    },
    showInformationSecurityPolicy_2() {
      window.open(
        `http://${window.location.host}/2.InformationSecurityPolicy.pdf`
      );
      // alert(window.location.host);
    },
    showTermandConditions_3() {
      window.open(`http://${window.location.host}/3.TermandConditions.pdf`);
      // alert(window.location.host);
    },
    showDmeetsdocx_4() {
      window.open(
        `http://${window.location.host}/4.InformationSecurityPolicy.dmeetsdocx.pdf`
      );
      // alert(window.location.host);
    },
    getVersion() {
      // console.log("Footer created:" + localStorage.getItem(server.ACC_PLAN_STA));
      if (localStorage.getItem(server.ACC_PLAN_STA) == "FREE") {
        this.version = server.VERSION_FREE;
      } else {
        this.version = server.VERSION_PREMIUM;
      }
    },
  },
  async beforeCreate() {
    this.$store.state.page_reload = false;

    // alert(localStorage.getItem("language"))
    // console.log("language login : " + localStorage.getItem(feature.LANGUAGE));

    localStorage.getItem(feature.LANGUAGE) === "th"
      ? (this.lg_footer = lg_footer.lg_footer_th)
      : (this.lg_footer = lg_footer.lg_footer_en);

    // console.log("lg_login: " + JSON.stringify(this.lg_login));
  },
};
</script>
<style type="text/css">
@media only screen and (max-width: 600px) {
  .v-btn:not(.v-btn--round).v-size--default {
    height: 20px;
  }
}
.footer-wrapper {
    background-color: #205295;
}
</style>
