import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getAllApiKey = () => {
  return httpClient.get(server.APIKEY_URL);
};

export const getApiKeyByID = (id) => {
  return httpClient.get(server.APIKEY_URL + `/id/${id}`);
};

export const checkByNameApiKey = (api_key_name) => {
  return httpClient.get(
    server.APIKEY_URL + `/checkbyname?api_key_name=${api_key_name}`
  );
};

export const checkNameForUpdateApiKey = (id, api_key_name) => {
  return httpClient.get(
    server.APIKEY_URL +
      `/checknameforupdate?id=${id}&api_key_name=${api_key_name}`
  );
};

export const addApiKey = (data) => {
  return httpClient.post(server.APIKEY_URL, data);
};

export const deleteApiKey = (id) => {
  return httpClient.delete(server.APIKEY_URL + `/${id}`);
};

export const updateApiKey = (id, data) => {
  return httpClient.put(server.APIKEY_URL + `/${id}`, data);
};
