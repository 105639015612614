<template>
  <v-dialog v-model="dialog" width="500" scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn text v-bind="attrs" v-on="on">
        <v-icon>mdi-plus</v-icon> Create Vote
      </v-btn>
    </template>

    <form @submit.prevent="onSubmit">
      <v-card dark>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Create Vote</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text class="py-5">
          <v-text-field
            v-model="form.question"
            label="Question"
            required
            outlined
            class="mb-2"
          ></v-text-field>

          <h3 class="mb-2">Answer</h3>
          <v-text-field
            v-for="(option, i) in form.options"
            :key="`option-${i}`"
            v-model="option.value"
            required
            outlined
            dense
            prepend-icon="mdi-circle-outline"
            @click:append="() => removeOption(i)"
          >
            <template v-slot:append>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <a v-on="on" @click="() => removeOption(option.id)">
                    <v-icon color="red">
                      mdi-close-circle-outline
                    </v-icon>
                  </a>
                </template>
                Remove option
              </v-tooltip>
            </template>
          </v-text-field>
          <v-btn block color="primary" small outlined @click="addOption">
            <v-icon>mdi-plus</v-icon> Add Option</v-btn
          >

          <v-checkbox
            v-model="form.isMultiple"
            label="Multiple Choice"
          ></v-checkbox>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text type="submit">
            Save
          </v-btn>
          <v-btn color="red" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </v-dialog>
</template>

<script>
import firebase from "firebase/app";
import uuid from "short-uuid";

export default {
  props: {
    roomId: {
      type: String
    },
    userId: {
      type: String
    },
    user: {
      type: String
    }
  },
  data: () => ({
    dialog: false,
    form: {
      question: null,
      options: [{ value: null, id: uuid().new() }],
      isMultiple: false
    }
  }),
  methods: {
    close() {
      this.$emit("close");
    },
    addOption() {
      this.form = {
        ...this.form,
        options: [...this.form.options, { value: null, id: uuid().new() }]
      };
    },
    async onSubmit() {
      try {
        this.$swal.showLoading();
        const { roomId } = this;
        const body = {
          ...this.form,
          roomId,
          createByUserId: this.userId,
          createdAt: new Date(),
          createdBy: this.user
        };
        await firebase
          .firestore()
          .collection("votes")
          .add(body);

        this.$swal.close();
        this.dialog = false;
        this.$emit("success");
      } catch (error) {
        this.$swal.close();
        this.$swal.fire("Error", error.message, "error");
      }
    },
    removeOption(id) {
      this.form = {
        ...this.form,
        options: this.form.options.filter((o) => o.id !== id)
      };
    }
  },
  created() {
    this.$swal.close();
  }
};
</script>
