export const lg_account_detail = {
  account_detail_th: {
    title: "ข้อมูลส่วนตัว",
    txt_company: "บริษัท",
    txt_name: "ชื่อ-นามสกุล",
    txt_email: "อีเมล",
    txt_birthday: "วัน-เดือน-ปี เกิด",
    txt_phone: "เบอร์โทรศัพท์",
    txt_permission: "สิทธิ์การใช้งาน",
    btn_cancel: "ยกเลิก",
    btn_edit: "แก้ไข",
  },
  account_detail_en: {
    title: "Account Information",
    txt_company: "Company",
    txt_name: "Name",
    txt_email: "Email",
    txt_birthday: "Birthday (YYYY-MM-DD)",
    txt_phone: "Phone",
    txt_permission: "Permissions",
    btn_cancel: "Cancel",
    btn_edit: "Edit",
  },
};

export const lg_account_detail_edit = {
  account_detail_th: {
    title: "แก้ไขข้อมูลส่วนตัว",
    txt_name: "ชื่อ-นามสกุล",
    txt_email: "อีเมล",
    txt_birthday: "วัน-เดือน-ปี (ค.ศ.) เกิด",
    txt_phone: "เบอร์โทรศัพท์",
    btn_cancel: "ยกเลิก",
    btn_save: "บันทึก",
    dialog_select_company: "กรุณาเลือกบริษัท",
    dialog_check_data: "กรุณาตรวจสอบข้อมูล",
    dialog_check_save: "บันทึกข้อมูล",
    dialog_check_save_success: "บันทึกข้อมูลสำเร็จ",
    messageAlert: "Update ข้อมูลส่วนตัวสำเร็จ",
    oldmessageAlert: "หลังจาก Update ข้อมูลส่วนตัว ท่านควรทำการ LogOut แล้ว LogIn ใหม่",
  },
  account_detail_en: {
    title: "Edit Account Information",
    txt_name: "Name",
    txt_email: "Email",
    txt_birthday: "Birthday (YYYY-MM-DD)",
    txt_phone: "Phone",
    btn_cancel: "Cancel",
    btn_save: "Save",
    dialog_select_company: "Please select a company",
    dialog_check_data: "Please check your information",
    dialog_check_save: "Save",
    dialog_check_save_success: "Save successful",
    messageAlert: "Update successful",
    oldmessageAlert: "After updating the account information, you must logout and login again",
  },
};
