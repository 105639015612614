<template>
  <v-container class="register-container">
    <v-row class="justify-center align-end pt-10 pb-10">
      <v-card :style="widthcard">
        <v-card-title class="justify-center" primary-title>
          {{ this.lg_register.title }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form @submit.prevent="submit" ref="form">
            <v-text-field
              dense
              :label="this.lg_register.txt_company_number"
              :placeholder="this.lg_register.txt_company_number"
              v-model="company_code"
              outlined
              :rules="codeRules"
              @blur="checkCompany"
              append-outer-icon="mdi-information"
              v-tooltip="'' + this.lg_register.company_tooltip + ''"
            ></v-text-field>

            <v-select
              v-validate="'required'"
              :items="mDataArrayDepartment"
              :label="this.lg_register.sl_department"
              item-text="department"
              item-value="id"
              v-model="account.department_id"
              outlined
              dense
              v-if="show_department"
              @change="changeDepartment"
              required
              :rules="commonRules"
            ></v-select>

            <v-text-field
              dense
              :label="this.lg_register.txt_fname"
              :placeholder="this.lg_register.txt_fname"
              outlined
              v-model="account.acc_fname"
              required
              :rules="commonRules"
            ></v-text-field>

            <v-text-field
              dense
              :label="this.lg_register.txt_lname"
              :placeholder="this.lg_register.txt_lname"
              v-model="account.acc_lname"
              outlined
              required
              :rules="commonRules"
            ></v-text-field>

            <v-text-field
              dense
              :label="this.lg_register.email"
              :placeholder="this.lg_register.email_place"
              v-model="account.username"
              outlined
              required
              :rules="emailRules"
            ></v-text-field>

            <v-row>
              <v-col cols="12" lg="6" md="6" sm="6" xs="12">
                <v-text-field
                  dense
                  :label="this.lg_register.txt_phone"
                  :placeholder="this.lg_register.txt_phone"
                  type="number"
                  outlined
                  v-model="account.acc_tel"
                  required
                  :rules="phoneRules"
                ></v-text-field>
              </v-col>

              <v-col cols="12" lg="6" md="6" sm="6" xs="12">
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <!-- v-model="dateFormatted" -->
                  <!-- v-model="account.acc_birth" -->
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      v-model="dateFormatted"
                      :label="lg_register.dtp_birthday"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      outlined
                      @blur="date = parseDate(dateFormatted)"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    no-title
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-text-field
              dense
              v-model="account.password"
              :label="this.lg_register.txt_password"
              :placeholder="this.lg_register.txt_password"
              outlined
              :rules="passwordRules"
              :append-icon="e1 ? 'visibility' : 'visibility_off'"
              @click:append="e1 = !e1"
              :type="e1 ? 'password' : 'text'"
            ></v-text-field>

            <v-text-field
              dense
              :label="this.lg_register.txt_password_confirm"
              :placeholder="this.lg_register.txt_password_confirm"
              :append-icon="e2 ? 'visibility' : 'visibility_off'"
              @click:append="e2 = !e2"
              :type="e2 ? 'password' : 'text'"
              outlined
              v-model="account.password_confirm"
              :rules="passwordConfirmRules"
              required
            ></v-text-field>

            <v-divider></v-divider>

            <!-- <span>{{ account }}</span> -->
            <v-layout justify-center class="mt-5">
              <v-btn :small="buttonsmall" type="submit" v-bind:style="{ backgroundColor: '#205295', color: '#ffffff' }">{{
                this.lg_register.btn_register
              }}</v-btn>
              <!-- <v-btn @click.prevent="registerMember" color="success"
                >Register</v-btn
              > -->
            </v-layout>
          </v-form>
        </v-card-text>
      </v-card>
    </v-row>

    <!-- Alert Dialog -->
    <v-dialog v-model="alertDlg" width="unset">
      <v-card>
        <v-card-title class="headline">{{
          this.titleMessageAlert
        }}</v-card-title>

        <v-card-text class="body">
          {{ this.messageAlert }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            text
            @click="alertDlg = false"
            col="12"
            lg="12"
            md="12"
            sm="12"
          >
            Close
          </v-btn>

          <!-- <v-btn outlined color="error" text @click="confirmDelete">
            Confirm
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Alert Dialog -->

    <!-- Complete Dialog -->
    <v-dialog v-model="completeDlg" max-width="500">
      <v-card>
        <v-card-title class="headline">{{
          this.titleMessageAlert
        }}</v-card-title>

        <v-card-text class="body">
          {{ this.messageAlert }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            text
            @click="onclickcompleteDlg()"
            col="12"
            lg="12"
            md="12"
            sm="12"
          >
            Close
          </v-btn>

          <!-- <v-btn outlined color="error" text @click="confirmDelete">
            Confirm
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Complete Dialog -->

    <LoadingDialog></LoadingDialog>
  </v-container>
</template>

<script>
import api from "@/services/api";
import LoadingDialog from "@/components/cards/LoadingDialog";
import { server } from "../services/constants";
import { feature } from "../services/constants";
import { lg_register } from "./language/lg_register";

export default {
  data(vm) {
    // const d = new Date();
    // let nd = (d.getFullYear()+543) + "-" + (d.getMonth() + 1) + "-" + d.getDate();
    // alert(text);
    return {
      isRegister: false,
      alertDlg: false,
      completeDlg: false,
      myToggle: true,
      geust_id: "",
      geust_name: "",
      geust_ckeck: false,
      company_code: "",
      show_department: false,
      account: {
        username: "",
        password: "",
        password_confirm: "",
        company_id: 0,
        department_id: 10,
        acc_fname: "",
        acc_lname: "",
        acc_fullname: "",
        acc_id_card: "",
        acc_tel: "",
        acc_plan:"FREE",
        acc_birth: null,
        role: "Member",
      },
      titleMessageAlert: "",
      messageAlert: "",
      date: vm.formatDateshowselect(new Date().toISOString().substr(0, 10)),
      dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
      menu1: false,
      menu2: false,
      mDataArray: [],
      mDataArrayGuest: [],
      mDataArrayDepartment: [],
      valid: false,
      e1: true,
      e2: true,
      isShowPassword: false,
      commonRules: [(v1) => !!v1 || this.lg_register.rules_commond],
      passwordRules: [
        (v1) => !!v1 || this.lg_register.rules_password,
        (v2) =>
          !!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
            v2
          ) || this.lg_register.rules_password2,
      ],
      passwordConfirmRules: [
        (v1) =>
          this.account.password == this.account.password_confirm ||
          this.lg_register.rules_password_confirm,
      ],
      phoneRules: [(v1) => /^\d{10}$/.test(v1) || this.lg_register.rules_phone],
      codeRules:"",
      emailRules: [
        (v1) =>
          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
            v1
          ) || this.lg_register.rules_email,
      ],
    };
  },

  beforeCreate() {
    this.$store.state.page_reload = false;

    localStorage.getItem(feature.LANGUAGE) === "th"
      ? (this.lg_register = lg_register.register_th)
      : (this.lg_register = lg_register.register_en);

    // console.log("lg_register: " + JSON.stringify(this.lg_register));
  },

  computed: {
    widthcard() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "width:80%";
        case "sm":
          return "width:70%";
        case "md":
          return "width:70%";
        case "lg":
          return "width:60%";
        case "xl":
          return "width:50%";
      }
    },
    buttonsmall() {
      if (this.$vuetify.breakpoint.name === "xs") {
        return true;
      }
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  components: {
    LoadingDialog,
  },
  async mounted() {
    localStorage.setItem(server.MEET_STA, "false");
    this.loadGuest();
    this.loadCompany();
  },

  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  methods: {
    onChangeDTP() {
      // alert(this.value);
    },
    async checkCompany() {
      if(this.company_code.length > 0) {
      //    this.codeRules = [
      //   (v1) =>
      //     /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/.test(v1) ||
      //     this.lg_register.rules_code,
      // ];
    this.codeRules = [
        (v1) =>
          /^(?=.*[A-Za-z])[A-Za-z\d]{6,}$/.test(v1) ||
          this.lg_register.rules_code,
      ];
      }
      
      
      // alert("checkCompany");
      // console.log("checkCompany:" + JSON.stringify(this.mDataArray));
      // console.log("checkCompany company_code:" + this.company_code);
      const result_code = await api.checkByCompanyCode(this.company_code);
      // console.log("checkCompany company_code:" + JSON.stringify(result_code));
      if (result_code.data != null) {
        // alert("Company:" + result_code.data.id);
        this.account.company_id = result_code.data.id;
        this.account.acc_plan = "PREMIUM";
        this.account.role = "Member";
        this.show_department = true;
        this.loadDepartment(result_code.data.id);
      } else {
        // alert("null");
        this.account.acc_plan = "FREE";
        this.account.role = "Guest";
        this.account.company_id = this.geust_id;
        this.show_department = false;
      }
      // this.mDataArray.forEach((element) => {
      //   if (element.company_code == this.company_code) {
      //     // alert("Company:" + element.id);
      //     this.account.company_id = element.id;
      //     this.account.role = "Member";
      //     this.show_department = true;
      //     this.loadDepartment(element.id);
      //   }
      // });
      // if (this.account.company_id == 0) {
      //   this.account.role = "Guest";
      //   this.account.company_id = this.geust_id;
      //   this.show_department = false;
      // }
      // console.log("checkCompany company_code:" + this.account.company_id);
    },
    changeDepartment(selectObj) {
      // console.log(selectObj);
      // alert(selectObj);
      this.account.department_id = selectObj;
    },
    async changeCompany(selectObj) {
      // console.log(selectObj)
      this.loadDepartment(selectObj);
    },
    async loadDepartment(id) {
      // console.log("loadDepartment")
      let result = await api.getAllDepartmentByCompanyId(id);
      // console.log(result)
      this.mDataArrayDepartment = result.data;
      // console.log(JSON.stringify(result.data));
    },
    async loadGuest() {
      this.mDataArrayGuest = [];

      let result = await api.getCompanyGuestId();
      // console.log("loadGuest : " + JSON.stringify(result.data));
      this.mDataArrayGuest = result.data.rows;

      // console.log("loadGuest : " + this.mDataArrayGuest[0].id);
      // console.log("loadGuest : " + this.mDataArrayGuest[0].company_name);
      // console.log("loadGuest2 : " + JSON.stringify(result.data.id));
      this.geust_id = this.mDataArrayGuest[0].id;
      this.geust_name = this.mDataArrayGuest[0].company_name;
      this.department_id = 10;
    },
    onChangeToggle() {
      if (this.myToggle == true) {
        this.account.role = "Member";
      } else {
        this.account.role = "Guest";
        this.account.company_id = this.geust_id;
      }
      // alert(this.myToggle);
      // alert(this.account.role);
    },
    onclickcompleteDlg() {
      this.completeDlg = false;
      this.$router.push("/main-page");
    },
    async submit() {
      //Code_DCM
  //     console.log(this.date);
  // console.log(this.dateFormatted);
      // console.log("submitCompany :" + JSON.stringify(this.account));

      if (this.$refs.form.validate()) {
        this.account.acc_fullname = `${this.account.acc_fname}  ${this.account.acc_lname}`;
        this.$store.state.loadMenu = true;

        this.titleMessageAlert = this.lg_register.dialog_check_data;

        // alert(this.company_code.length); 
        if (this.company_code.length != 6 && this.company_code.length > 0) {
          this.messageAlert = this.lg_register.dialog_company_code;
          this.alertDlg = true;
          this.$store.state.loadMenu = false;
          return;
        }

        const result = await api.checkRegister(this.account.username);
        // alert(JSON.stringify(result.data));
        if (result.data != null) {
          this.messageAlert = this.lg_register.dialog_email_used;
          this.alertDlg = true;
          this.$store.state.loadMenu = false;
          return;
        }
        this.account.acc_birth = this.date;
        // alert(JSON.stringify(this.account));
        // if (this.account.company_id == 0 && this.account.role == "Member") {
        //   this.messageAlert = "กรุณาเลือกบริษัท";
        //   this.alertDlg = true;
        //   this.$store.state.loadMenu = false;
        //   return;
        // }

        if (this.account.username == "") {
          this.messageAlert = this.lg_register.dialog_empty_email;
          this.alertDlg = true;
          this.$store.state.loadMenu = false;
          return;
        }

        if (this.account.password == "") {
          this.messageAlert = this.lg_register.dialog_empty_password;
          this.alertDlg = true;
          this.$store.state.loadMenu = false;
          return;
        }

        if (this.account.password != this.account.password_confirm) {
          this.messageAlert = this.lg_register.dialog_empty_password_confirm;
          this.alertDlg = true;
          this.$store.state.loadMenu = false;
          return;
        }

        const result_resister = await api.register(this.account);

        // alert(JSON.stringify(result_resister));
        if (result_resister != null) {
          // alert("result_resister.data != null");
          this.isRegister = true;
          this.titleMessageAlert = this.lg_register.dialog_save;
          this.messageAlert = this.lg_register.dialog_save_success;
          this.completeDlg = true;
          // this.$router.push("/main-page");
          this.$store.state.loadMenu = false;
        } else {
          // alert("result_resister.data == null");
          this.titleMessageAlert = this.lg_register.dialog_save;
          this.messageAlert = this.lg_register.dialog_save_fail;
          this.$store.state.loadMenu = false;
        }
      }
    },
    async loadCompany() {
      let result = await api.getCompanyNotGuest();
      this.mDataArray = result.data;
      // console.log(JSON.stringify(result.data));
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${parseInt(year)+0}`;
    },
    formatDateshowselect(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${parseInt(year)+0}-${month}-${day}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}`;
    },
   
  },
};
</script>

<style>
.v-progress-circular {
  margin: 1rem;
}

.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: rgb(59, 163, 236);
  color: white;
  border-radius: 16px;
  padding: 20px 20px 20px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: rgb(59, 163, 236);
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, 0.1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden="true"] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden="false"] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}

@media (min-width: 960px) {
  .register-container {
    margin-top: 2rem
  }
}
</style>
