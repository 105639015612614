import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getAllAppId = () => {
  return httpClient.get(server.APPID_URL);
};

export const getAppIdByID = (id) => {
  return httpClient.get(server.APPID_URL + `/id/${id}`);
};

export const checkByNameAppId = (app_id_name) => {
  return httpClient.get(
    server.APPID_URL + `/checkbyname?app_id_name=${app_id_name}`
  );
};

export const checkNameForUpdateAppId = (id, app_id_name) => {
  return httpClient.get(
    server.APPID_URL + `/checknameforupdate?id=${id}&app_id_name=${app_id_name}`
  );
};

export const addAppId = (data) => {
  return httpClient.post(server.APPID_URL, data);
};

export const deleteAppId = (id) => {
  return httpClient.delete(server.APPID_URL + `/${id}`);
};

export const updateAppId = (id, data) => {
  return httpClient.put(server.APPID_URL + `/${id}`, data);
};
