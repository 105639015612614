<template>
  
  <v-app>
    <div class="homemain">
      <v-row class="mt-16 mb-16 ml-4 mr-4" justify="center" v-bind:style="{ align:'center'}">
        <v-col justify="center" cols="12" xl="4" lg="4" md="5" sm="7" xs="12" v-bind:style="{alignContent: 'center',align:'center' }">
          <v-row justify="center" v-bind:style="{align:'left' }">
          <h1 class="display-6 fw-bold lh-1 mb-3" v-bind:style="{ color: '#ffffff',fontSize:'38px'}">
            {{ this.lg_header_contract.title }}
          </h1>

          <h2 v-bind:style="{ color: '#ffffff',fontWeight:'300 !important',align:'left' }">
            {{ this.lg_header_contract.title1 }}
          </h2>

    <v-row v-bind:style="{ alignContent:'center',align:'left' }" class="ma-1 mt-4 d-flex justify-center align-center justify-md-start align-md-start"  >
          <v-btn
                class="mb-1 black--text"
                height="45"
                @click="onClickMenu('/register-account')"
                v-bind:style="{ minWidth: 'auto', backgroundColor: '#65CCFF',marginRight: '10px' }"
              >
                <h2>{{ this.lg_header_contract.btn_start }}</h2>
              </v-btn>

              <v-btn
                class="mb-1"
                height="45"
                @click="onClickMenu('/feature')"
                v-bind:style="{ minWidth: 'auto', backgroundColor: '#4D4D4D',color:'#65CCFF',borderStyle:'solid',borderColor:'#65CCFF' }"
              >
                <h2>{{ this.lg_header_contract.btn_learn_more }}</h2>
              </v-btn>
            </v-row>

           </v-row>
      </v-col>
      <v-col align="center" cols="12" xl="4" lg="4" md="5" sm="5" xs="12" v-bind:style="{align:'center'}">
        <v-img 
            :max-height="widthheightimage"
          :max-width="widthheightimage"
            align-center 
            justify-center
             src="../assets/contact_us.jpeg" 
             contain
             class="pricingimg"
             v-bind:style="{backgroundPosition: 'center',backgroundRepeat: 'no-repeat',backgroundSize: 'cover'}" />
     
      </v-col>
    </v-row>
    </div>
    
    <div class="homepricing">
      <v-row justify="center" v-bind:style="{ align:'center'}" class="mt-8 mb-8 ml-4 mr-4 flex-wrap-reverse">
      <v-col justify="center" cols="12" xl="6" lg="6" md="6" sm="12" xs="12" v-bind:style="{align:'center' }">
        <h1 class="display-6 fw-bold lh-1 mb-4" v-bind:style="{ color: '#000000',fontSize:'38px'}">
            {{ this.lg_header_contract.title }}
          </h1>

  <ul id="myUL">
  <li class="mb-4"><span class="caret"> {{ this.lg_header_contract.question1 }}</span>
    <ul class="nested">
      <li>{{ this.lg_header_contract.subquestion1 }}</li>
    </ul>
  </li>

  <li class="mb-4"><span class="caret">{{ this.lg_header_contract.question2 }}</span>
    <ul class="nested">
      <li>{{ this.lg_header_contract.subquestion2 }}</li>
    </ul>
  </li>

  <li class="mb-4"><span class="caret">{{ this.lg_header_contract.question3 }}</span>
    <ul class="nested">
      <li>{{ this.lg_header_contract.subquestion3 }}</li>
    </ul>
  </li>

  <li class="mb-4"><span class="caret">{{ this.lg_header_contract.question4 }}</span>
    <ul class="nested">
      <li>{{ this.lg_header_contract.subquestion4 }}</li>
    </ul>
  </li>
</ul>

      </v-col>
    </v-row>
    </div>
    <div class="homegettotouch">
      <v-row justify="center" class="mt-8 mb-8 ml-4 mr-4">
      <v-col justify="center"  cols="12" xl="4" lg="4" md="7" sm="7" xs="12" v-bind:style="{alignContent: 'center',align:'center' }">
        <!-- <v-row justify="center" v-bind:style="{align:'center' }"> -->
        <h1 class="display-6 fw-bold lh-1" v-bind:style="{ color: '#ffffff',fontSize:'38px'}">
            {{ this.lg_header_contract.get_in_touch }}
          </h1>

          <h2 v-bind:style="{ color: '#ffffff',fontWeight:'300 !important',align:'left' }">
            {{ this.lg_header_contract.get_in_touch_title }}
          </h2>
          <br/>
          <h2 v-bind:style="{ color: '#ffffff',fontWeight:'300 !important',align:'left' }">
            {{ this.lg_header_contract.email_contact }}: admin@dcm.co.th
          </h2>
          <br/>
          <h2 v-bind:style="{ color: '#ffffff',fontWeight:'300 !important',align:'left' }">
            {{ this.lg_header_contract.phone }}: +66 2233-0055 , +66 2233-0068
          </h2>
          <br/>
          <h2 v-bind:style="{ color: '#ffffff',fontWeight:'300 !important',align:'left' }">
            {{ this.lg_header_contract.address }}:  {{ this.lg_header_contract.address_details }}
          </h2>
          <br/>
          <h2 v-bind:style="{ color: '#ffffff',fontWeight:'300 !important',align:'left' }">
            {{ this.lg_header_contract.main_company_website }}: www.dcm.co.th
          </h2>


     <!-- </v-row> -->
      </v-col>
      <v-col align="center" justify="center" cols="12" xl="4" lg="4" md="5" sm="5" xs="12" v-bind:style="{align:'center' }">
        <v-img 
            :max-height="widthheightimage"
          :max-width="widthheightimage"
            align-center 
            justify-center
             src="../assets/home_gettotouch.jpg" 
             contain
             class="pricingimg"
             v-bind:style="{backgroundPosition: 'center',backgroundRepeat: 'no-repeat',backgroundSize: 'cover' }" />
     
      </v-col>
    </v-row>
    </div>
    
    <!-- Privacy Dialog -->
    <v-dialog v-if="showpop" v-model="privacyDlg" :max-width="maxwidthdialog">
      <v-card v-bind:style="{ padding: '1rem' }">
        <v-img height="200" contain src="../assets/privacypolicy4.png"></v-img>

        <v-card-title primary-title class="justify-center">
          {{ this.lg_policy.privacy_consent }}</v-card-title
        >

        <v-divider class="mx-4"></v-divider>

        <v-card-text>
          <div class="mt-3">
            {{ this.lg_policy.text1 }}
          </div>
          <div class="mt-3">
            {{ this.lg_policy.text2 }}
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :small="buttonsmall"
            outlined
            color="success"
            text
            @click="onclickPrivacyDlg()"
            col="12"
            lg="12"
            md="12"
            sm="12"
          >
            {{ this.lg_policy.accept }}
          </v-btn>

          <v-btn
            :small="buttonsmall"
            outlined
            color="error"
            text
            @click="onclickNonPrivacyDlg()"
            col="12"
            lg="12"
            md="12"
            sm="12"
          >
            {{ this.lg_policy.notaccept }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</v-app>
</template>

<script>
import { feature } from "../services/constants";
import { lg_header_contract } from "./language/lg_header_contract.js";
import { lg_header_feature } from "./language/lg_header_feature.js";
export default {
  name: "Contact",
  data: () => ({
    showpop: false,
    dateNow: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .replace("T", " ")
      .substr(0, 16),
    margincardpriceplans: "mx-auto ml-0 mt-0 mr-0 mb-0",
    width: 420,
    width_emp: 600,
    text_fontsize: 30,
    privacy_sta: "false",
    privacyDlg: false,
  }),
   async beforeCreate() {
      this.$store.state.page_reload = false;

      // alert(localStorage.getItem("language"))
      // console.log("language login : " + localStorage.getItem(feature.LANGUAGE));

      localStorage.getItem(feature.LANGUAGE) === "th"
        ? this.lg_header_contract = lg_header_contract.lg_header_contract_th
        : this.lg_header_contract = lg_header_contract.lg_header_contract_en;

        localStorage.getItem(feature.LANGUAGE) === "th"
      ? (this.lg_header_feature = lg_header_feature.lg_header_feature_th)
      : (this.lg_header_feature = lg_header_feature.lg_header_feature_en);

        // console.log("lg_login: " + JSON.stringify(this.lg_login));

       
    },
    async mounted() {
      var toggler = document.getElementsByClassName("caret");
const removeAllactiveclass = (indexnotremove)=> {
  for (var i = 0; i < toggler.length; i++) {
    if(i !== indexnotremove){
      document.querySelectorAll(".nested")[i].classList.remove("active");
      toggler[i].classList.remove("caret-down");
    }
}
}

const indexIn = (selector, element)=> {
  for (var i = 0; i < selector.length; ++i)
    if (toggler[i] === element) return i;
  return -1;
}

for (var i = 0; i < toggler.length; i++) {
  toggler[i].addEventListener("click", function() {
    removeAllactiveclass(parseInt(indexIn("." + this.classList[i], this)));

     if(!document.querySelectorAll(".nested")[parseInt(indexIn("." + this.classList[i], this))].classList.contains("active")){
      document.querySelectorAll(".nested")[parseInt(indexIn("." + this.classList[i], this))].classList.add("active");
    }else{
      document.querySelectorAll(".nested")[parseInt(indexIn("." + this.classList[i], this))].classList.remove("active");
    }

    if(toggler[parseInt(indexIn("." + this.classList[i], this))].classList.contains("caret-down")){
      toggler[parseInt(indexIn("." + this.classList[i], this))].classList.remove("caret-down");
    }else{
      toggler[parseInt(indexIn("." + this.classList[i], this))].classList.add("caret-down");
    }
  });
}

    
    },
    computed: {
  buttonsmall() {
     if(this.$vuetify.breakpoint.name === "xs"){
       return true;
     }
      },
    widthbutton() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "75%";
        case "sm":
          return "76%";
        case "md":
          return "60%";
        case "lg":
          return "40%";
        case "xl":
          return "40%";
      }
    },
    widthheightimage(){
 switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "300";
        case "sm":
          return "400";
        case "md":
          return "400";
        case "lg":
          return "400";
        case "xl":
          return "400";
      }
      },
    },
    methods: {
      onClickMenu(link) {
      // console.log(link);
      this.$router.push(link).catch((err) => {});
    },
    },
};
</script>


<style scoped>
/*
@media only screen and (max-width: 600px) {
  .v-card{
    margin-top:40px;
     margin-bottom:40px;
      margin-right:40px;
       margin-left:40px;
}
}
*/
@media only screen and (max-width: 320px)  {
  .v-application {
    margin-top:-50px;
  }
  /* .homemain{
    height:80vh !important;
  }
  .homefeature{
    height:150vh !important;
  }
  .homepricing{
    height:90vh !important;
  }
  .homegettotouch{
    height:100vh !important;
  } */
}
  @media only screen and (min-width: 320px) and (max-width: 599px)  {
  /* .mainpage-container {
    margin-top:-150px;
  } */
  .v-application {
    margin-top:-50px;
    /* height: 110% !important; */
  }
  /* .homemain{
    height:75vh !important;
  }
  .homefeature{
    height:130vh !important;
  }
  .homepricing{
    height:70vh !important;
  }
  .homegettotouch{
    height:90vh !important;
  } */
}

/* @media (max-width: 959px) {
  .homefeature{
    height: 40% !important;
  }
} */

@media only screen and (min-width: 600px) and (max-width: 960px)  {
  .homefeature{
    height:80vh !important;
  }
}

@media (min-width: 960px) {
  .mainpage-container {
    margin-top: 3rem
  }
}

.v-main {
    overflow:auto;
    /* max-width: 100%;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to right top, #cbe9f8, #dcedfc, #ebf2fd, #f7f8fe, #ffffff) !important; */
}
/* .mainpage-container {
    margin-top: -20px;
  } */

.homemain{
  background-image: linear-gradient(to right top, #3e6272, #506574, #5e6875, #696c75, #717174) !important;
  /* height: 50vh; */
  width: 100%;
  align-content: center;
}
.homefeature{
  background-image: linear-gradient(to right top, #cbe9f8, #dcedfc, #ebf2fd, #f7f8fe, #ffffff) !important;
    /* height: 60vh; */
    width: 100%;
}
.homepricing{
  background-image: linear-gradient(to bottom, #95bef2, #99c0f2, #9dc2f2, #a0c4f2, #a4c6f2) !important;
    /* height: 50vh; */
    width: 100%;
    align-content: center;
}
.homegettotouch{
  background-color: #19396A !important;
    /* height: 50vh; */
    width: 100%;
    align-content: center;
}

.homepricing_paragraph{
  transform: translateY(20px);
  opacity: 0;
  transition: 2s ease;
}
.homepricing_paragraph--visible{
  transform: translateY(0);
  opacity: 1;
}
/* .pricingimg{
  background-position: 'center';
  background-repeat: 'no-repeat';
  background-size: 'cover';
}
.fadein1s{
  animation: '1s fadeIn' 
} */
ul, #myUL {
  list-style-type: none;
}

#myUL {
  margin: 0;
  padding: 0;
}

.caret {
  font-size:19.5px;
  font-weight:300;
  cursor: pointer;
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
}

.caret::before {
  content: "\25B6";
  color: black;
  display: inline-block;
  margin-right: 6px;
}

.caret-down::before {
  -ms-transform: rotate(90deg); 
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);  
}

.nested {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size:19.5px;
  font-weight:300;
  display: none;
}

.active {
  display: block;
}
</style>
