export const lg_verify = {
  verify_th: {
    title1: "ยืนยันตัวตน/ส่งอีเมล OTP",
    email1: "อีเมล",
    btn_confirm1: "ตรวจสอบอีเมล / ส่งอีเมล OTP",
    btn_cancel1: "ยกเลิก",
    title2: "ยืนยัน OTP / เข้าร่วมการประชุม",
    email2: "ยืนยันอีเมล",
    txt_otp: "OTP",
    btn_confirm2: "ยืนยัน OTP / เข้าร่วมการประชุม",
    btn_cancel2: "ยกเลิก",
    dialog_title: "ส่ง OTP สำเร็จ",
    dialog_message: "ส่ง OTP สำเร็จ กรุณาตรวจสอบอีเมลเพื่อดำเนินการต่อ",
    dialog_btn_close: "ปิด",
    dialog_popup_success: "ส่ง OTP สำเร็จ",
    dialog_popup_success_message: "กรุณาตรวจ OTP ที่อีเมล์",
    dialog_popup_fail: "ส่ง OTP ล้มเหลว",
    dialog_popup_fail_message: "กรุณาส่ง OTP ใหม่อีกครั้ง",
    dialog_popup_expire: "OTP หมดอายุ",
    dialog_popup_expire_message: "OTP หมดอายุ กรุณาส่ง OTP ใหม่อีกครั้ง",
  },
  verify_en: {
    title1: "Verify Identify / Send Email OTP",
    email1: "Email",
    btn_confirm1: "Check Email / Send Email OTP",
    btn_cancel1: "Cancel",
    title2: "Verify OTP / Join Meeting",
    email2: "Email Verification",
    txt_otp: "OTP",
    btn_confirm2: "Verify OTP / Join Meeting",
    btn_cancel2: "Cancel",
    dialog_title: "Sent OTP successful",
    dialog_message: "Sent OTP successful, Please check your email to continue",
    dialog_btn_close: "Close",
    dialog_popup_success: "Sent OTP successful",
    dialog_popup_success_message: "Please check OTP in your email",
    dialog_popup_fail: "Send OTP failed",
    dialog_popup_fail_message: "Please send OTP again",
    dialog_popup_expire: "OTP Expired",
    dialog_popup_expire_message: "OTP has expired, please send the OTP again",
  },
};
