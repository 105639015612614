<template>
  <v-dialog v-model="visible" width="800" scrollable persistent>
    <v-card>
      <v-card-text class="py-5" v-if="vote">
        <p class="question-text mb-3">{{ vote.question }}</p>
        <v-simple-table fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Name
                </th>
                <th class="text-left">
                  Role
                </th>
                <th class="text-left">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(member, index) in members" :key="`member-${index}`">
                <td width="300">{{ member.displayname }}</td>
                <td width="100">
                  {{ member.moderator ? "Moderator" : "Member" }}
                </td>
                <td width="100">
                  <v-icon
                    v-if="answeredUsers.includes(member.userId)"
                    color="green"
                    >mdi-check-circle</v-icon
                  >
                  <v-icon v-else color="grey">mdi-check-circle</v-icon>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="onClose">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import firebase from "firebase/app";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    vote: {
      type: Object,
      default: () => null
    },
    roomId: {
      type: String
    },
    userId: {
      type: String
    }
  },

  data: () => ({
    answeredUsers: [],
    members: [],
    reportWatcher: null,
    memberWatcher: null
  }),
  watch: {
    visible(visible) {
      if (visible) {
        this.reportWatcher = firebase
          .firestore()
          .collection("vote-report")
          .where("questionId", "==", this.vote.id)
          .onSnapshot(async (snapshot) => {
            const { docs } = snapshot;
            this.answeredUsers = await Promise.all(
              docs.map(async (doc) => {
                const data = await doc.data();
                return data.userId;
              })
            );
          });
        this.memberWatcher = firebase
          .firestore()
          .collection("members")
          .where("roomId", "==", this.roomId)
          .onSnapshot(async (snapshot) => {
            const { docs } = snapshot;
            const members = await Promise.all(
              docs.map(async (doc) => {
                const data = await doc.data();
                return data;
              })
            );
            this.members = members.filter((m) => m.userId !== this.userId);
          });
      } else {
        if (this.reportWatcher) {
          this.reportWatcher();
        }
        if (this.memberWatcher) {
          this.memberWatcher();
        }
      }
    }
  },
  methods: {
    onClose() {
      this.$emit("close");
    }
  },
  created() {
    this.$swal.close();
  }
};
</script>

<style lang="scss">
.question-text {
  font-size: 20px;
}
</style>
