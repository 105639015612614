import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const listUserData = (role, company_id) => {
  return httpClient.get(
    server.ACCOUNT_URL + `/listUserData?role=${role}&company_id=${company_id}`
  );
};

export const sendOTP = (verify) => {
  return httpClient.post(server.ACCOUNT_URL + `/sendotp`, verify);
};

export const getAccountById = (id) => {
  return httpClient.get(server.ACCOUNT_URL + `/getaccountbyid?id=${id}`);
};

export const getAccountByEmail = (email) => {
  return httpClient.get(
    server.ACCOUNT_URL + `/getaccountbyemail?email=${email}`
  );
};

export const getDateExpire = (id) => {
  return httpClient.get(server.ACCOUNT_URL + `/dateExpre?userId=${id}`);
};

export const deleteAccount = (id) => {
  return httpClient.delete(server.ACCOUNT_URL + `/${id}`);
};

export const updateAccount = (id, data) => {
  return httpClient.put(server.ACCOUNT_URL + `/${id}`, data);
};

export const checkRegister = (username) => {
  return httpClient.get(
    server.ACCOUNT_URL + `/checkregister?username=${username}`
  );
};

export const resetPassword = (id, data) => {
  return httpClient.put(server.ACCOUNT_URL + `/resetpassword/${id}`, data);
};

export const resetPasswordByUsername = (data) => {
  return httpClient.post(server.ACCOUNT_URL + `/resetpassword`, data);
};

export const onUserRequest = (data) => {
  return httpClient.post(server.ACCOUNT_URL + `/user-request`, data);
};
