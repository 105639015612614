var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"padding-top":"20px"}},[_c('div',{staticClass:"title-wrapper"},[_c('h3',{staticClass:"page-title"},[_vm._v(_vm._s(_vm.lg_meeting_recording.title))])]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.lg_meeting_recording.headers,"items":_vm.reports,"loading":_vm.loading,"items-per-page":_vm.itemsPerPage},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mx-4",attrs:{"value":_vm.renderDateSearch,"clearable":"","label":_vm.lg_meeting_recording.search_date,"readonly":""},on:{"click:clear":function($event){_vm.dateSearch = null}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenuVisible),callback:function ($$v) {_vm.dateMenuVisible=$$v},expression:"dateMenuVisible"}},[_c('v-date-picker',{on:{"change":function($event){_vm.dateMenuVisible = false}},model:{value:(_vm.dateSearch),callback:function ($$v) {_vm.dateSearch=$$v},expression:"dateSearch"}})],1),_c('v-text-field',{staticClass:"mx-4",attrs:{"label":_vm.lg_meeting_recording.search_topic,"clearable":true},model:{value:(_vm.topicSearch),callback:function ($$v) {_vm.topicSearch=$$v},expression:"topicSearch"}})],1)]},proxy:true},{key:"item.options",fn:function(ref){
var item = ref.item;
return _vm._l((item.options),function(o,i){return _c('p',{key:((o.id) + "-" + i),staticStyle:{"margin":"0"}},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(i + 1)+".")]),_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(o.value))]),_vm._v(" ( คะแนน "+_vm._s(o.answers)+" ) ")])})}},{key:"item.answerMembers",fn:function(ref){
var item = ref.item;
return _vm._l((item.answerMembers),function(m,i){return _c('p',{key:("member-" + i),staticStyle:{"margin":"0"}},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(i + 1)+".")]),_vm._v(" "+_vm._s(m)+" ")])})}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"rounded-pill",staticStyle:{"margin":"auto"},attrs:{"color":"#3E73BB","dark":""},on:{"click":function($event){return _vm.Downloaditemrecording(item)}}},[_vm._v(" Download ")])]}},{key:"item.index",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_vm._v(" "+_vm._s(index+1)+" ")]}},{key:"item.started_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.started_time).toLocaleString('en-US', { timeZone: "Asia/Bangkok" }))+" ")]}},{key:"item.stopped_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.stopped_time).toLocaleString('en-US', { timeZone: "Asia/Bangkok" }))+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.createdAt))+" ")]}}])}),_c('p',[_vm._v(_vm._s(_vm.dateSearch))]),[(_vm.setdatavote)?_c('VotePdfDialog',{key:_vm.setdatavoteshow,attrs:{"datavote":_vm.setdatavote,"visible":_vm.pdfDialog},on:{"on-cancel":function($event){_vm.pdfDialog = false}}}):_vm._e()],_c('ListRoom',{ref:"listroom",style:({ display: 'none' })})],2)}
var staticRenderFns = []

export { render, staticRenderFns }