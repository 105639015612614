<template>
  <v-container>
    <v-card class="mt-8">
      <v-data-table
        :search="search"
        :headers="headers"
        :items="mDataArray"
        :items-per-page="10"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Meeting</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>

        <template v-slot:item="{ item }">
          <tr :class="{ primary: item.id === selectedListUserDataId }">
            <td>{{ item.id }}</td>

            <td>{{ item.meet_name }}</td>
            <td>
              {{ item.tbl_meetroom_joins[0].meet_join_name }}
            </td>
            <td>{{ item.company_name }}</td>
            <td>
              <div v-if="item.meet_sta === 'Y'" class="green--text">
                Active
              </div>
              <div v-else class="red--text">
                InActive
              </div>
            </td>
            <td>
              <v-icon class="mr-2" @click="itemInfo(item)" color="green">
                info
              </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>

      <!-- Detail Dialog -->
      <v-dialog v-model="detailDialog" max-width="60%">
        <v-card>
          <v-card-title class="headline">{{
            this.titleMessageAlert
          }}</v-card-title>

          <template>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Id ห้องประชุม
                    </th>
                    <th class="text-left">
                      ชื่อผู้เข้าร่วมประชุม
                    </th>
                    <th class="text-left">
                      บริษัท
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in mDialogArray" :key="item.meet_id">
                    <td>{{ item.meet_id }}</td>
                    <td>{{ item.meet_join_name }}</td>
                    <td>{{ item.tbl_account.tbl_company.company_name }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              text
              @click="detailDialog = false"
              col="12"
              lg="12"
              md="12"
              sm="12"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Detail Dialog -->

      <LoadingDialog></LoadingDialog>
    </v-card>
  </v-container>
</template>

<script>
import api from "@/services/api";
import LoadingDialog from "@/components/cards/LoadingDialog.vue";
import { server } from "../services/constants";

export default {
  name: "listmeeting",
  async mounted() {
    localStorage.setItem(server.MEET_STA, "false");
    this.$store.state.loadMenu = true;
    this.loadListRoomMeetingData();
  },
  components: {
    LoadingDialog,
  },
  data() {
    return {
      search: "",
      role: "",
      company_id: 0,
      selectedListUserDataId: "",
      mDataArray: [],
      detailDialog: false,
      mDialogArray: [],
      titleMessageAlert: "ผู้เข้าร่วมประชุม",
      headers: [
        {
          text: "Id",
          sortable: false,
          value: "id",
        },
        { text: "ชื่อห้องประชุม", value: "meet_name" },
        { text: "ผู้สร้าง", value: "acc_fullname" },
        { text: "บริษัท", value: "company_name" },
        { text: "Status", value: "status" },
        { text: "Action", value: "action" },
      ],
    };
  },
  methods: {
    async loadListRoomMeetingData() {
      this.mDataArray = [];
      let company_id = localStorage.getItem(server.COMPANYID);
      let role = localStorage.getItem(server.ROLE);
      let result  = null
      if (role == "Admin") {
        result = await api.listRoomMeetingAllStatus();
      } else {
        result = await api.listRoomMeetingAllStatusByCompany(company_id);
      }

      this.mDataArray = result.data.rows;
      //   alert(JSON.stringify(this.mDataArray));
      // console.log(JSON.stringify(this.mDataArray));
      this.$store.state.loadMenu = false;
    },
    async itemInfo(item) {
      // alert(item.id);
      this.$store.state.loadMenu = true;
      this.loadMeetJoinDetailDialog(item.id);
    },

    async loadMeetJoinDetailDialog(meet_id) {
      this.mDialogArray = [];
      let result = await api.getAllMeetJoinByMeetId(meet_id);
      this.mDialogArray = result.data;
      // alert(JSON.stringify(this.mDialogArray));
      // console.log(JSON.stringify(this.mDialogArray));
      this.$store.state.loadMenu = false;
      this.detailDialog = true;
    },
  },
};
</script>

<style></style>
