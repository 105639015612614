<template>
  <div class="text-center">
    <v-dialog v-model="visible" :max-width="maxwidthdialog" scrollable>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          <!-- hidden-sm-and-down -->
          <v-img
      class="mr-5"
      src="../../assets/logo.png"
      max-height="50"
      max-width="50"
      contain
      sm="12"
      cols="12"
    ></v-img>
          {{ lg_vote_pdf_dialog.title }}
        </v-card-title>

        <v-card-text class="pt-2">
          <!--  style="display: inline-block;"   for not scroll-->
          <!-- <pdf  @error="onError" :src="pdfBytes"></pdf> -->
          <template>
            <div>
              <v-text-filde class="text-left black--text">
                <h3 class="pl-2">
                  {{ lg_vote_pdf_dialog.vote_topic }}
                  <small class="body-1">{{ datavote.question }}</small>
                </h3>
              </v-text-filde>

              <v-text-filde class="text-left black--text">
                <h3 class="pl-2">
                  {{ lg_vote_pdf_dialog.from_meeting }}
                  <small class="body-1">{{ datavote.meet_topic }}</small>
                </h3>
              </v-text-filde>

              <v-text-filde class="text-left black--text">
                <h3 class="pl-2">
                  {{ lg_vote_pdf_dialog.vote_count }}
                  <small class="body-1">{{ JSON.parse(datavote.voted).length  }}</small>
                </h3>
              </v-text-filde>

              <v-text-filde class="text-left black--text">
                <h3 class="pl-2 pb-2">
                  {{ lg_vote_pdf_dialog.as_of }}
                  <small class="body-1"> {{ new Date(datavote.created_at).toLocaleDateString('en-GB',{
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    })}}</small>
                </h3>
              </v-text-filde>

              <div id="showchart"></div>
            </div>

            <div align="center">
            <table>
  <tr>
    <th>{{ lg_vote_pdf_dialog.tbl_vote_option }}</th>
    <th>{{ lg_vote_pdf_dialog.tbl_vote_count }}</th>
  </tr>
  <tr
          v-for="(o, i) in JSON.parse(datavote.options)"
          :key="`${o.id}-${i}`"
        >
          <td>{{ o.text }}</td>
          <td>{{ o.count }}</td>
        </tr>
</table>
</div>
<div v-if="!datavote.anonymous && setshowdata.datavotes.length > 0" align="center" class="mt-5">
            <table>
  <tr>
    <th> {{ lg_vote_pdf_dialog.tbl_participant_name }}</th>
  </tr>
 
  <tr
        v-for="(item, index) in setshowdata.datavotes"
        >
        <td  >{{ item.name }}</td>
        </tr>
</table>
</div>
          </template>
          <template>
            <div>
              <vue-html2pdf
                :show-layout="false"
                :float-layout="true"
                :enable-download="true"
                :preview-modal="false"
                :paginate-elements-by-height="1100"
                filename="meeting_vote_report"
                :pdf-quality="2"
                :manual-pagination="false"
                pdf-format="a4"
                pdf-orientation="portrait"
                pdf-content-width="21cm"
                @progress="onProgress($event)"
                @hasStartedGeneration="hasStartedGeneration()"
                @hasGenerated="hasGenerated($event)"
                ref="html2Pdf"
              >
                <section slot="pdf-content">
                  <template>
                    <v-card-title style="height:50px">
          <img
          style="max-height:50px;max-width:50px;margin-right:20px;"
      src="../../assets/logo.png"
    ></img>
         
          <v-text-filde class="text-left black--text">
                        <h3 class="pl-2">
                          {{ lg_vote_pdf_dialog.title }}
                        </h3>
                      </v-text-filde>
        </v-card-title>
                    <div class="paddingdownload" style='font-family:"Roboto", sans-serif'>
                      <v-text-filde class="text-left black--text">
                        <h3 class="pl-2">
                          {{ lg_vote_pdf_dialog.vote_topic }}
                          <small class="body-1" style="font-weight:400;font-size: 16px;">{{ datavote.question }}</small>
                        </h3>
                      </v-text-filde>

                      <v-text-filde class="text-left black--text">
                        <h3 class="pl-2">
                          {{ lg_vote_pdf_dialog.from_meeting }}
                          <small class="body-1" style="font-weight: 400;font-size: 16px;">{{
                            datavote.meet_topic
                          }}</small>
                        </h3>
                      </v-text-filde>

                      <v-text-filde class="text-left black--text">
                        <h3 class="pl-2">
                          {{ lg_vote_pdf_dialog.vote_count }}
                          <small class="body-1" style="font-weight: 400;font-size: 16px;">{{ JSON.parse(datavote.voted).length  }}</small>
                        </h3>
                      </v-text-filde>

                      <v-text-filde class="text-left black--text">
                        <h3 class="pl-2">
                          {{ lg_vote_pdf_dialog.as_of }}
                          <small class="body-1" style="font-weight: 400;font-size: 16px;">{{ new Date(datavote.created_at).toLocaleDateString('en-GB',{
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    })}}</small>
                        </h3>
                      </v-text-filde>

                      <div id="chart"></div>
                    </div>
                    <div align="center">
            <table>
  <tr>
    <th>{{ lg_vote_pdf_dialog.tbl_vote_option }}</th>
    <th>{{ lg_vote_pdf_dialog.tbl_vote_count }}</th>
  </tr>
  <tr
          v-for="(o, i) in JSON.parse(datavote.options)"
          :key="`${o.id}-${i}`"
        >
          <td>{{ o.text }}</td>
          <td>{{ o.count }}</td>
        </tr>
</table>
</div>
<div v-if="!datavote.anonymous" align="center" style="margin-top:20px">
            <table>
  <tr>
    <th> {{ lg_vote_pdf_dialog.tbl_participant_name }}</th>
  </tr>
  <tr
        v-for="(item, index) in setshowdata.datavotes"
        >
        <td  >{{ item.name }}</td>
        </tr>
</table>
</div>
                  </template>

                  <!-- PDF Content Here -->
                </section>
              </vue-html2pdf>
            </div>
          </template>
        </v-card-text>

        <!--   <v-divider></v-divider> -->

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="generateReport()">
            {{ lg_vote_pdf_dialog.btn_download }}
          </v-btn>
          <v-btn color="primary" text @click="$emit('on-cancel')">
            {{ lg_vote_pdf_dialog.btn_close }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { feature } from "../../services/constants";
import { lg_vote_pdf_dialog } from "../../views/language/lg_vote_pdf_dialog";
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import ApexCharts from "apexcharts";
import VueHtml2pdf from "vue-html2pdf";
import imglogo from "../../assets/logo.png"

import pdf from "vue-pdf";
export default {
  data(vm) {
    return {
      setshowdata: {
        datagrgraph: [],
        dataanswer: [],
        datavotes: [],
        numberparticipants: "",
      },
      chartData: {
        labels: ["January", "February", "March"],
        datasets: [
          {
            label: "Data One",
            backgroundColor: "#f87979",
            data: [40, 20, 12],
          },
        ],
      },
    };
  },
  props: {
    visible: {
      default: false,
      type: Boolean,
    },
    pdfBytes: {
      default: null,
    },
    datavote: {
      default: null,
    },
  },
  components: {
    pdf,
    Bar,
    VueHtml2pdf,
  },
  computed: {
    maxwidthdialog() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100%";
        case "sm":
          return "70%";
        case "md":
          return "50%";
        case "lg":
          return "40%";
        case "xl":
          return "40%";
      }
    },
  },
  mounted() {
    // console.log(this.datavote)
    this.setshowdata.numberparticipants = this.lg_vote_pdf_dialog.number_of_vote;

   JSON.parse(this.datavote.options).filter((x, index) => {
      this.setshowdata.datagrgraph.push(x.count);
      this.setshowdata.dataanswer.push(`${x.text}`);
      if(x.votes.length > 0) {
        x.votes.filter(d=>{
          this.setshowdata.datavotes.push(d);
        })

      }
      
    });
  
    // Overwriting base render method with actual data.
    var options = {
      series: [
        {
          data: this.setshowdata.datagrgraph,
        },
      ],
      chart: {
        redrawOnParentResize:true,
        redrawOnWindowResize: true,
        fontFamily: '"Roboto", sans-serif',
        type: "bar",
        height: 380,
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: false, // show export file
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true | '<img src="/static/icons/reset.png" width="20">',
            customIcons: [],
          },
          export: {
            csv: {
              filename: "gg",
              columnDelimiter: ",",
              headerCategory: "category",
              headerValue: "value",
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString();
              },
            },
            svg: {
              filename: undefined,
            },
            png: {
              filename: undefined,
            },
            pdf: {
              filename: "gg",
            },
          },
          autoSelected: "zoom",
        },
        // sparkline: {
        //       enabled: true,
        //     },
      },

      plotOptions: {
        bar: {
          barHeight: "100%",
          distributed: true,
          horizontal: true,
          borderRadius: 1,
          barHeight: "50%",
          dataLabels: {
            position: "bottom",
          },
        },
      },

      colors: ['#3E73BB'],
      dataLabels: {
        enabled: false,
        textAnchor: "start",
        style: {
          colors: ["#fff"],
        },
        formatter: function (val, opt) {
          return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
        },
        offsetX: 0,
        dropShadow: {
          enabled: true,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },

      // xaxis: {
      //   categories: ['South Korea', 'Canada', 'United Kingdom', 'Netherlands', 'Italy', 'France', 'Japan',
      //     'United States', 'China', 'India'
      //   ],

      // },
      grid: {
        show: true,
        borderColor: "#90A4AE",
        strokeDashArray: 0,
        position: "back",
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
        row: {
          colors: undefined,
          opacity: 0.5,
        },
        column: {
          colors: undefined,
          opacity: 0.5,
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
      stroke: {
        show: true,
        curve: ["smooth", "straight", "stepline"],
        lineCap: "round",
        colors: undefined,
        width: 2,
        dashArray: 0,
      },

      //format show categories
      legend: {
        show: false,
        position: "bottom",
        horizontalAlign: "center",
        showForSingleSeries: false,
        fontWeight: 900,
        fontSize: "12px", // fontsize answer
        // height: 100,
        //offsetX: 40,

        // categories circle
        //      markers: {
        //     width: 12,
        //     height: 12,
        //     strokeWidth: 0,
        //     strokeColor: '#fff',
        //     fillColors: undefined,
        //     radius: 12,
        //     customHTML: undefined,
        //     onClick: undefined,
        //     offsetX: 0,
        //     offsetY: 0
        // },
      },

      xaxis: {
        type: "category",
        categories: this.setshowdata.dataanswer,
        tickAmount: 1,
        tickPlacement: "between",
        min: undefined,
        max: undefined,
        range: undefined,
        floating: undefined,
        decimalsInFloat: 0,
        overwriteCategories: undefined,
        position: "top",
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: false,
          hideOverlappingLabels: true,
          showDuplicates: false,
          trim: false,
          minHeight: undefined,
          maxHeight: 30,
          style: {
            colors: [],
            fontSize: "12px",
            fontFamily: '"Roboto", sans-serif',
            fontWeight: 400,
            cssClass: "apexcharts-xaxis-label",
          },
          offsetX: 0,
          offsetY: 0,
          format: undefined,
          datetimeUTC: true,
          datetimeFormatter: {
            year: "yyyy",
            month: "MMM 'yy",
            day: "dd MMM",
            hour: "HH:mm",
          },
          formatter: function (val) {
            return val.toFixed(0);
          },
        },
        axisBorder: {
          show: true,
          color: "#78909C",
          height: 1,
          width: "100%",
          offsetX: 0,
          offsetY: 0,
        },
        axisTicks: {
          show: true,
          borderType: "solid",
          color: "#78909C",
          height: 6,
          offsetX: 0,
          offsetY: 0,
        },

        title: {
          text: this.setshowdata.numberparticipants,
          offsetX: 0,
          offsetY: -70,
          style: {
            fontSize: "14px", 
            fontFamily: '"Roboto", sans-serif',
            fontWeight: 900,
            cssClass: "apexcharts-xaxis-title",
          },
        },

        crosshairs: {
          show: true,
          width: 1,
          position: "back",
          opacity: 0.9,
          stroke: {
            color: "#b6b6b6",
            width: 0,
            dashArray: 0,
          },
          fill: {
            type: "solid",
            color: "#B1B9C4",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
          dropShadow: {
            enabled: false,
            top: 0,
            left: 0,
            blur: 1,
            opacity: 0.4,
          },
        },
        tooltip: {
          enabled: false,
          offsetY: 0,
          style: {
            fontSize: 0,
            fontFamily: 0,
          },
        },
      },
      
      yaxis: {
        show: true,
        title: {
          text:this.lg_vote_pdf_dialog.tbl_vote_options,
          offsetX: 25,
          offsetY: 0,
          style: {
            fontSize: "14px",
            fontFamily: '"Roboto", sans-serif',
            fontWeight: 900,
          },
        },
        categories: this.setshowdata.dataanswer,
        labels: {
          show: true,
          rotate:0,
          rotateAlways: false,
          hideOverlappingLabels: true,
          showDuplicates: false,
          trim: false,
          minWidth: undefined,
          maxHeight: undefined,
          minWidth:60,
          maxWidth:100,
        
        },
      },
      title: {
        text: undefined,
        align: "left",
        // rotate: -90,
        // offsetX: 0,
        // offsetY: 0,
      },
      subtitle: {
        // text: 'Category Names as DataLabels inside bars',
        align: "left",
      },
      tooltip: {
        theme: "dark",
        x: {
          show: false,
        },
        y: {
          title: {
            formatter: function (val) {
              return "";
            },
          },
          //format labels when hover on data
          formatter: function (val) {
            return val.toFixed(0);
          },
        },
      },
    };



    var options2 = {
          series: [{
          data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380]
        }],
          chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            borderRadiusApplication: 'end',
            horizontal: true,
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: ['South Korea', 'Canada', 'United Kingdom', 'Netherlands', 'Italy', 'France', 'Japan',
            'United States', 'China', 'Germany'
          ],
        }
        };

    var chart = new ApexCharts(document.querySelector("#chart"), options);
    chart.render();
    var showchart = new ApexCharts(
      document.querySelector("#showchart"),
      options
    );
    showchart.render();
    this.datavote.created_at = new Date(datavote.created_at).toLocaleDateString('en-GB',{
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    })
  },

  beforeCreate() {
    localStorage.getItem(feature.LANGUAGE) === "th"
      ? (this.lg_vote_pdf_dialog = lg_vote_pdf_dialog.vote_pdf_dialog_th)
      : (this.lg_vote_pdf_dialog = lg_vote_pdf_dialog.vote_pdf_dialog_en);
  },
  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    onError(e) {
      console.log(e);
    },
    download() {
      try {
        const pdfBytes = this.pdfBytes;
        var blob = new Blob([pdfBytes], { type: "application/pdf" });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "meeting_vote_report.pdf";
        link.click();
      } catch (error) {
        alert(error.message);
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
};
</script>

<style scoped>
.paddingdownload{
  margin-left: 10px;
  margin-right: 10px;
  padding-left:70px;
  padding-top:50px;
}
table {
  color: #000000;
  border-collapse: collapse;
  width: 80%;
}
 th {
  border: 1px solid #000000;
  text-align: center;
  padding: 6px;
}
td{
  border: 1px solid #000000;
  text-align: left;
  padding: 6px;
}
</style>
