export const lg_create_meetroom = {
  create_meetroom_th: {
    title: "ยินดีต้อนรับเข้าสู่ระบบ Dmeets",
    create_room_now: "สร้างห้องตอนนี้",
    create_room_schedule: "สร้างห้องล่วงหน้า",
    meet_topic: "หัวข้อการประชุม",
    meet_date_start: "วันที่เริ่ม",
    meet_time_start: "เวลาที่เริ่ม",
    meet_password: "รหัสผ่าน (ใส่หรือไม่ใส่ก็ได้)",
    meet_create: "สร้างห้อง",
    meet_option: "ตัวเลือกการประชุม",
    meet_option_lobby: "คัดกรองผู้เข้าร่วมประชุม",
    meet_option_verify_identify: "ยืนยันตัวตน",
    meet_option_share_screen: "อนุญาตให้ผู้เข้าร่วมแชร์หน้าจอ",
    titlemeeterror:"ผิดพลาด",
    contentmeeterror:"สิ้นสุดการประชุม",
    contentmeetiderror:"Meeting ID ไม่ถูกต้อง",
    enter_meeting_details:"กรุณากรอกรายละเอียดการประชุม",
  },
  create_meetroom_en: {
    title: "Welcome to Dmeets",
    create_room_now: "Create a room now",
    create_room_schedule: "Create a schedule room",
    meet_topic: "Meeting Topic",
    meet_date_start: "Start Date",
    meet_time_start: "Start Time",
    meet_password: "Password (optional)",
    meet_create: "Create a room",
    meet_option: "Meeting Options",
    meet_option_lobby: "Create a Lobby",
    meet_option_verify_identify: "Verify user's identity",
    meet_option_share_screen: "Allow share screen",
    titlemeeterror:"Error",
    contentmeeterror:"The meeting was finished",
    contentmeetiderror:"Meeting ID Incorrect",
    enter_meeting_details:"Please enter meeting details",
  },
};
