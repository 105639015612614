<template>
 <HeaderisLogin v-if="$store.getters['isLogin']" />
   <HeaderResponsive v-else="!$store.getters['isLogin']" />
  
</template>

<script>
import api from "@/services/api";
import { feature } from "../../services/constants";
import SwitchLang from "vue-switch-lang";
import HeaderResponsive from "../core/HeaderResponsive"
import HeaderisLogin from "../core/HeaderisLogin"
export default {
  name: "Header",
  data: () => ({
   
  }),
  components: {
    SwitchLang,
    HeaderResponsive,
    HeaderisLogin,
  },
  async mounted() {
    this.$forceUpdate();
    // this.$router.go(); // Refreshes page
    this.acc_plan = localStorage.getItem("acc_plan");
    this.role = localStorage.getItem("role");
    this.id = localStorage.getItem("user_id");
    // alert(this.id);
    // alert(this.acc_plan);
    // alert(this.role);
    const res = await api.getDateExpire(this.id);
    this.date_exire = res.data.value_expire;
    // alert(this.date_exire);
    // console.log("date_exire : " + this.date_exire);
  },
  computed: {
    version() {
      return process.env.VUE_APP_VERSION;
    },
    title() {
      return process.env.VUE_APP_TITLE;
    },

  },
  methods: {
    onChange(lang) {
      // Do something
      // alert(lang.title);
      localStorage.setItem(feature.LANGUAGE, lang.title);
      // alert(localStorage.getItem(feature.LANGUAGE));
      // console.log("language header : " + localStorage.getItem(feature.LANGUAGE));
      this.$router.go();
    },
  
    onClickLogOff() {
      this.$store.dispatch("doLogout");
      // alert("Test");
    },
    onClickMenu(link) {
      this.$store.state.navMenu = false;
      this.$router.push(link).catch((err) => {});
    },
  },
};
</script>

<style></style>