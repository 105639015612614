import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const addLogParticipant = (data) => {
  return httpClient.post(server.LOG_PARTICIPANT, data);
};

export const getLPByMeetId = (log_meet_id, log_ip_address) => {
  return httpClient.get(
    server.LOG_PARTICIPANT + `/${log_meet_id}/${log_ip_address}`
  );
};

export const updateLogParticipant = (id, data) => {
  return httpClient.put(server.LOG_PARTICIPANT + `/${id}`, data);
};

export const getAllLogParticipant = () => {
  return httpClient.get(server.LOG_PARTICIPANT);
};
