import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const addRecordLink = (data) => {
  return httpClient.post(server.RECORD_LINK_URL, data);
};

export const getAllRecordLink = () => {
  return httpClient.get(server.RECORD_LINK_URL);
};
