export const lg_header_contract = {
    lg_header_contract_th: {
        company_name: "บริษัท ดิจิทัล คอร์ปอเรท เมเนจเม้นท์ จำกัด",
        address: "942/69 อาคารชาญอิสสระทาวเวอร์ ชั้น 2 ถนนพระราม 4 แขวงสุริยวงศ์ เขตบางรัก กรุงเทพมหานคร 10500",
        county:"แขวงสุริยวงศ์ เขตบางรัก กรุงเทพมหานคร 10500",
      tel: "โทรศัพท์:  (66) 2233-0055 , (66) 2233-0068",
      email: "อีเมล์: admin@dcm.co.th",
      buttonclick:"วิธีการใช้งาน Dmeet",
      title:"เชื่อมต่อได้ทุกที่",
      title1:"การประชุมผ่านแพลตฟอร์มของเรา มีความปลอดภัยและคุณภาพสูง จึงทำให้การทำงานร่วมกันไม่ว่าจะจากระยะไหนก็เป็นเรื่องง่าย",
      btn_start:"เริ่มทดลองใช้ฟรี",
      btn_learn_more:"เรียนรู้เพิ่มเติม",
      frequently:"คำถามที่พบบ่อย",
      question1:"ราคาของคุณเป็นแบบไหน?",
      subquestion1:"ราคาของเราจะปรับตามให้เข้ากับความต้องการของลูกค้าแต่ละราย เรามีโมเดลการตั้งราคาที่ยืดหยุ่น เพื่อให้คุณได้รับความคุ้มค่าที่ดีที่สุด",
      question2:"ใช้เวลากี่วันในการเปิดบัญชีโปร?",
      subquestion2:"ระยะเวลาจะแตกต่างกันไป ขึ้นอยู่กับความต้องการของคุณ แต่เราทำงานอย่างเต็มที่เพื่อเปิดบัญชีของคุณโดยเร็วที่สุด",
      question3:"คุณมีบริการหลังการขายและการบำรุงรักษาต่อเนื่องหรือไม่?",
      subquestion3:"แน่นอน! เราให้มีบริการหลังการขาย คุณสามารถติดต่อเราได้ 24 ชั่วโมง เรามีการบำรุงรักษาที่ครอบคลุมอย่างต่อเนื้อง เพื่อให้แน่ใจว่าบัญชีของคุณยังคงทำงานได้ตลอดเวลา",
      question4:"มีมาตรการด้านความปลอดภัยอะไรบ้าง?",
      subquestion4:"เราใส่ใจเรื่องความปลอดภัยเป็นอย่างมาก แพลตฟอร์มของเราใช้โปรโตคอลตัวล่าสุดและคอยอัปเดตอย่างตลอดเวลาเพื่อปกป้องข้อมูลและการสื่อสารของคุณ",
      get_in_touch:"ติดต่อเรา",
      get_in_touch_title:"ติดต่อทีมงานของเราเพื่อเรียนรู้เพิ่มเติมเกี่ยวกับบริการของเราและวิธีที่เราสามารถช่วยคุณได้",
      email_contact:"อีเมล",
      phone:"โทรศัพท์",
      address:"ที่อยู่",
      address_details:"942/69 อาคารชาญอิสระ 1 ชั้น 2 ถนนพระราม 4 แขวงสุริยวงศ์ เขตบางรัก กรุงเทพมหานคร 10500",
      main_company_website:"เว็บไซต์หลักของบริษัท",
    },
    lg_header_contract_en: {
        company_name: "Digital Corporate Management Co.,Ltd.",
        address: "942/69 Charn Issara Tower, 2nd Floor, Rama 4 Road Suriyawong Sub-district, Bang Rak District, Bangkok 10500",
        county:"Suriyawong Sub-district, Bang Rak District, Bangkok 10500",
        tel: "Tel: (66) 2233-0055 , (66) 2233-0068",
        email: "Email: admin@dcm.co.th",
        buttonclick:"How to use Dmeets",
        title:"Connect Face-to-Face, Anywhere",
        title1:"Our secure, high-quality video conferencing makes remote collaboration easy.",
        btn_start:"Start a Free Trial",
        btn_learn_more:"Learn More",
        frequently:"Frequently Asked Questions",
        question1:"What is your pricing structure?",
        subquestion1:"Our pricing is tailored to the specific needs of each client. We offer flexible, transparent pricing models to ensure you get the best value for your investment.",
        question2:"How long does it take to set up a pro account?",
        subquestion2:"The timeline can vary depending on the scope and complexity of your requirements, but we work diligently to open your account in a timely manner.",
        question3:"Do you offer ongoing support and maintenance?",
        subquestion3:"Absolutely! We provide comprehensive support and maintenance services to ensure your solutions continue to perform at their best.",
        question4:"What security measures do you have in place?",
        subquestion4:"We take security very seriously. Our platform uses the latest encryption and authentication protocols to protect your data and communications.",
        get_in_touch:"Get in Touch",
        get_in_touch_title:"Reach out to our friendly team to learn more about our services and how we can help you.",
        email_contact:"Email",
        phone:"Phone",
        address:"Address",
        address_details:"942/69 Charn Issara Tower 1, 2nd Floor, Rama 4 Road Suriyawong Sub-district, Bang Rak District, Bangkok 10500",
        main_company_website:"Main Company Website",
    },
  };
  