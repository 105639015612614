import httpClient from "@/services/httpClient";
import { server,feature } from "@/services/constants";
import * as productApis from "@/services/api_product";
import * as companyApis from "@/services/api_company";
import * as AppIdApis from "@/services/api_appid";
import * as ApiKeyApis from "@/services/api_apikey";
import * as AccountApis from "@/services/api_account";
import * as MeetApis from "@/services/api_meet";
import * as MeetRoomApis from "@/services/api_meetroom";
import * as MeetRoomJoinApis from "@/services/api_meetroom_join";
import * as DepartmentApis from "@/services/api_department";
import * as VoteApis from "@/services/api_vote";
import * as PDPAApis from "@/services/api_pdpa";
import * as PackageApis from "@/services/api_package";
import * as VerifyApis from "@/services/api_verify";
import * as LogHostApis from "@/services/api_log_host";
import * as LogParticipantApis from "@/services/api_log_participant";
import * as RecordLinkApis from "@/services/api_record_link";
import * as PollVoteApis from "@/services/api_poll_vote";

import router from "@/router";

const isLoggedIn = () => {
  let token = localStorage.getItem(server.TOKEN_KEY);
  return token != null;
};

const logoff = () => {
  localStorage.removeItem(server.TOKEN_KEY);
  localStorage.removeItem(server.USERNAME);
  localStorage.removeItem(server.FULLNAME);
  localStorage.removeItem(server.ROLE);
  localStorage.removeItem(server.COMPANYID);
  localStorage.clear();
  localStorage.setItem(feature.LANGUAGE,"gb");
  router.push("/main-page");
};

const login = async (values) => {
  try {
    // alert(server.LOGIN_URL);
    // alert(JSON.stringify(values));

    let result = await httpClient.post(server.LOGIN_URL, values);
    // alert(JSON.stringify(result.data));
    console.log(result.data);
    if (result.data.acc_fullname != null || result.data.acc_fullname != "") {
      localStorage.setItem(server.USERNAME, values.username);
      localStorage.setItem(server.USER_ID, result.data.user_id);
      localStorage.setItem(server.TOKEN_KEY, result.data.token);
      localStorage.setItem(server.FULLNAME, result.data.acc_fullname);
      localStorage.setItem(server.ROLE, result.data.role);
      localStorage.setItem(server.COMPANYID, result.data.company_id);

      localStorage.setItem(server.COMPANY_NAME, result.data.company_name);
      localStorage.setItem(server.DEPARTMENT_ID, result.data.department_id);
      localStorage.setItem(server.DEPARTMENT_NAME, result.data.department_name);
      localStorage.setItem(server.ACC_PLAN_STA, result.data.acc_plan);
      // alert("return true;");
      router.push("/create-meet-room");
      return true;
    } else {
      // alert("return false;");
      // alert(JSON.stringify(result));
      return false;
    }
  } catch (error) {
    // alert("return error;");
    // alert(JSON.stringify(error));
    return false;
  }
};
const forgot = async (values) => {
  let result = await httpClient.post(server.FORGOT_URL, values);
  return result;
};

const resetPassword = async (values) => {
  let result = await httpClient.post(server.RESET_URL, values);
  return result;
};

const register = async (values) => {
  let result = await httpClient.post(server.REGISTER_URL, values);
  return result;
};

export default {
  logoff,
  login,
  register,
  isLoggedIn,
  forgot,
  resetPassword,
  ...LogHostApis,
  ...LogParticipantApis,
  ...productApis,
  ...companyApis,
  ...AppIdApis,
  ...ApiKeyApis,
  ...AccountApis,
  ...MeetApis,
  ...MeetRoomApis,
  ...MeetRoomJoinApis,
  ...DepartmentApis,
  ...VoteApis,
  ...PDPAApis,
  ...PackageApis,
  ...VerifyApis,
  ...RecordLinkApis,
  ...PollVoteApis,
};
