<template>
  <v-container>
    <v-row class="justify-center pt-5">
      <v-col cols="12">
        <v-card class="mx-auto pa-5" outlined>
          <v-card-title>{{ lg_company_add.title }}</v-card-title>
          <v-divider></v-divider>
          <br />
          <v-form @submit.prevent="submit" ref="form">
            <v-text-field
            dense
              outlined
              :label="lg_company_add.company_name"
              v-model="company.company_name"
            ></v-text-field>

            <v-text-field
            dense
              outlined
              :label="lg_company_add.company_code"
              v-model="company.company_code"
              :rules="codeRules"
            ></v-text-field>

              <v-text-field
              dense
              outlined
              :label="lg_company_add.company_contact_email"
              v-model="company.company_contact_email"
            ></v-text-field>

             <v-text-field
             dense
              outlined
              :label="lg_company_add.company_contact_name"
              v-model="company.company_contact_name"
            ></v-text-field>


<v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <!-- v-model="dateFormatted" -->
                  <!-- v-model="account.acc_birth" -->
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      v-model="dateFormatted"
                      :label="lg_company_add.company_start_date"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      outlined
                      @blur="date = parseDate(dateFormatted)"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    no-title
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>

            <v-text-field
              dense
              outlined
              :label="lg_company_add.company_contact_address"
              v-model="company.company_contact_address"
            ></v-text-field>

              <v-text-field
              dense
              outlined
              type="number"
              :label="lg_company_add.company_phone"
              v-model="company.company_phone"
            ></v-text-field>

            
              <v-text-field
              dense
              outlined
              :label="lg_company_add.company_extension_number"
              v-model="company.company_extension_number"
            ></v-text-field>

                
            <v-row>
              <v-spacer></v-spacer>
              <v-btn class="mr-4" @click.prevent="cancel">
                {{ lg_company_add.btn_cancel }}
              </v-btn>

              <v-btn color="success" type="submit">
                {{ lg_company_add.btn_save }}
              </v-btn>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <LoadingDialog></LoadingDialog>
    <!-- Alert Dialog -->
    <v-dialog v-model="alertDlg" max-width="60%">
      <v-card>
        <v-card-title class="headline">{{
          this.titleMessageAlert
        }}</v-card-title>

        <v-card-text class="body">
          {{ this.messageAlert }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            text
            @click="alertDlg = false"
            col="12"
            lg="12"
            md="12"
            sm="12"
          >
            Close
          </v-btn>

          <!-- <v-btn outlined color="error" text @click="confirmDelete">
            Confirm
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Alert Dialog -->
  </v-container>
</template>

<script>
import api from "@/services/api";
import LoadingDialog from "../../components/cards/LoadingDialog.vue";

import { feature } from "../../services/constants";
import { lg_company_add } from "../language/lg_company";
import { lg_register } from "../language/lg_register";

export default {
  name: "company-create",
  beforeCreate() {
    localStorage.getItem(feature.LANGUAGE) === "th"
      ? (this.lg_company_add = lg_company_add.company_add_th)
      : (this.lg_company_add = lg_company_add.company_add_en);

      localStorage.getItem(feature.LANGUAGE) === "th"
      ? (this.lg_register = lg_register.register_th)
      : (this.lg_register = lg_register.register_en);

    // console.log("lg_company_add: " + JSON.stringify(this.lg_company_add));
  },
  async mounted() {
    this.codeRules = [
      value => {
        if (value?.length == 0 || (/^(?=.*[A-Za-z])[A-Za-z\d]{6,}$/.test(value) && value?.length == 6)) return true
          return this.lg_register.dialog_company_code
        },
      ];
  },
  data: (vm) => ({
     date: vm.formatDateshowselect(new Date().toISOString().substr(0, 10)),
      dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
       menu1: false,
    alertDlg: false,
    titleMessageAlert: "",
    messageAlert: "",
    company: {
      company_name: "",
      company_code: "",
      company_contact_email:"",
      company_contact_name:"",
      company_start_date:null,
      company_contact_address:"",
      company_phone:"",
      company_extension_number:"",
    },
    codeRules: [],
  }),
  components: {
    LoadingDialog,
  },
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  methods: {
    async submit() {
      // console.log(server.COMPANY_URL);
      // console.log(JSON.stringify(this.company));
      // alert(JSON.stringify(this.company));
      if (this.$refs.form.validate()) {
      this.$store.state.loadMenu = true;
      const result = await api.checkByNameCompany(this.company.company_name);
      if (result.data != null) {
        this.titleMessageAlert = this.lg_company_add.titleMessageAlert;
        this.messageAlert = this.lg_company_add.messageAlert;
        this.alertDlg = true;
        this.$store.state.loadMenu = false;
      } else {
        const result_code = await api.checkByCompanyCode(
          this.company.company_code
        );

        if (result_code.data != null) {
          this.titleMessageAlert = this.lg_company_add.titleMessageAlert;
          this.messageAlert = this.lg_company_add.messageAlertCodeDuplicate;
          this.alertDlg = true;
          this.$store.state.loadMenu = false;
        } else {
          this.company.company_start_date = this.date;
          console.log(this.company)
          await api.addCompany(this.company);
          // this.$store.state.loadMenu = false;
          this.$router.back();
        }
      }
    }
    },
    cancel() {
      this.$router.back();
    },
     formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${parseInt(year)+0}`;
    },
    formatDateshowselect(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${parseInt(year)+0}-${month}-${day}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}`;
    },
  },
};
</script>

<style></style>
