<template>
  <v-container>
   <v-row class="justify-center align-end pt-10 pb-10">
    <v-card class="mt-5 ml-5 mr-5" :style="widthcard">
      <v-card-title primary-title>
        {{ lg_account_reset_password.title }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form @submit.prevent="submit">
          <v-text-field
          dense
            :label="lg_account_reset_password.txt_email"
            v-model="this.account.username"
            outlined
            disabled
          />

          <v-text-field
          dense
            v-model="account.password"
            :label="lg_account_reset_password.txt_old_pass"
            :placeholder="lg_account_reset_password.txt_old_pass"
            outlined
            :append-icon="e1 ? 'visibility' : 'visibility_off'"
            @click:append="e1 = !e1"
            :type="e1 ? 'password' : 'text'"
          ></v-text-field>

          <v-text-field
          dense
            v-model="account.password_new"
            :label="lg_account_reset_password.txt_new_pass"
            :placeholder="lg_account_reset_password.txt_new_pass"
            outlined
            :append-icon="e2 ? 'visibility' : 'visibility_off'"
            @click:append="e2 = !e2"
            :type="e2 ? 'password' : 'text'"
          ></v-text-field>

          <v-text-field
          dense
            :label="lg_account_reset_password.txt_confirm_pass"
            :placeholder="lg_account_reset_password.txt_confirm_pass"
            :append-icon="e3 ? 'visibility' : 'visibility_off'"
            @click:append="e3 = !e3"
            :type="e3 ? 'password' : 'text'"
            outlined
            v-model="account.password_new_confirm"
          ></v-text-field>

          <v-divider></v-divider>
          <!-- <span>{{ JSON.stringify(this.account) }}</span> -->
          <v-row class="justify-center mb-0  mt-5 ">
             <!--  <v-btn outlined text @click.prevent="$router.push('/main-page')">{{
              lg_account_reset_password.btn_cancel
            }}</v-btn> -->
            <v-btn type="submit" color="primary">{{
              lg_account_reset_password.btn_save
            }}</v-btn>
            <!-- <v-btn @click.prevent="registerMember" color="success"
                >Register</v-btn
              > -->
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
   </v-row>
    <!-- Alert Dialog -->
    <v-dialog v-model="alertDlg" max-width="60%">
      <v-card>
        <v-card-title class="headline">{{
          this.titleMessageAlert
        }}</v-card-title>

        <v-card-text class="body">
          {{ this.messageAlert }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            text
            @click="alertDlg = false"
            col="12"
            lg="12"
            md="12"
            sm="12"
          >
            Close
          </v-btn>

          <!-- <v-btn outlined color="error" text @click="confirmDelete">
            Confirm
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Alert Dialog -->

    <!-- Complete Dialog -->
    <v-dialog v-model="completeDlg" max-width="60%">
      <v-card>
        <v-card-title class="headline">{{
          this.titleMessageAlert
        }}</v-card-title>

        <v-card-text class="body">
          {{ this.messageAlert }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            text
            @click="onclickcompleteDlg()"
            col="12"
            lg="12"
            md="12"
            sm="12"
          >
            Close
          </v-btn>

          <!-- <v-btn outlined color="error" text @click="confirmDelete">
                Confirm
              </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Complete Dialog -->

    <LoadingDialog></LoadingDialog>
  </v-container>
</template>

<script>
import api from "@/services/api";
import { server } from "@/services/constants";
import LoadingDialog from "@/components/cards/LoadingDialog.vue";

import { feature } from "../../services/constants";
import { lg_account_reset_password } from "../language/lg_account_reset_password";

export default {
  name: "account-resetpassword",
  beforeCreate() {
    localStorage.getItem(feature.LANGUAGE) === "th"
      ? (this.lg_account_reset_password =
          lg_account_reset_password.account_reset_password_th)
      : (this.lg_account_reset_password =
          lg_account_reset_password.account_reset_password_en);

    if (this.resultJson == null) {
      this.checkData = false;
    }
  },
  async mounted() {
    this.$store.state.loadMenu = true;
    this.loadAccountData();
  },
  data() {
    return {
      resultJson: null,
      resultJsonCompanyName: null,
      checkData: false,
      alertDlg: false,
      completeDlg: false,
      titleMessageAlert: "",
      messageAlert: "",
      account: {
        id: 0,
        username: "",
        password: "",
        password_new: "",
        password_new_confirm: "",
      },
      valid: false,
      e3: true,
      e1: true,
      e2: true,
      isShowPassword: false,
      usernameRules: [(v1) => !!v1 || "Username is required"],
      passwordRules: [
        (v1) => !!v1 || "Password is required",
        (v2) =>
          !!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(v2) ||
          "Minimum eight characters, at least one letter and one number",
      ],
    };
  },
  components: {
    LoadingDialog,
  },
   computed: {
    widthcard() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "width:80%";
        case "sm":
          return "width:70%";
        case "md":
          return "width:60%";
        case "lg":
          return "width:50%";
        case "xl":
          return "width:50%";
      }
    },
       },
  methods: {
    onclickcompleteDlg() {
      this.completeDlg = false;
      this.$router.push("/main-page");
    },
    async submit() {
      // this.$router.push(`/account-edit-member/${this.username}`);
      // alert(JSON.stringify(this.account));

      // this.titleMessageAlert = "กรุณาตรวจสอบข้อมูล";
      this.titleMessageAlert = this.lg_account_reset_password.dialog_check_data;
      // alert(JSON.stringify(this.account));
      if (
        this.account.password == "" ||
        this.account.password_new == "" ||
        this.account.password_new_confirm == ""
      ) {
        // this.$store.state.loadMenu = false;
        // this.messageAlert = "กรุณาตรวจสอบ กรอกข้อมูลให้ครบถ้วน";
        this.messageAlert = this.lg_account_reset_password.dialog_check_data;
        this.alertDlg = true;
        return;
      }

      if (this.account.password_new != this.account.password_new_confirm) {
        // this.$store.state.loadMenu = false;
        // this.messageAlert = "กรุณาตรวจสอบ Confirm Password";
        this.messageAlert = this.lg_account_reset_password.dialog_failed_confirm_password;
        this.alertDlg = true;
        return;
      }

      // alert("result_update_account");
      // alert(this.account);
      this.$store.state.loadMenu = true;
      try {
        const result_update_account = await api.resetPassword(
          this.account.id,
          this.account
        );
        // alert(JSON.stringify(result_update_account));
        // alert(JSON.stringify(result_update_account.data));
        if (result_update_account != null) {
          // alert("result_update_account.data != null");
          this.$store.state.loadMenu = false;

          // this.titleMessageAlert = "บันทึกข้อมูล";
          this.titleMessageAlert = this.lg_account_reset_password.dialog_check_save;
          // this.messageAlert =
          //   "บันทึกข้อมูลสำเร็จ หลังจาก Reset Password ท่านควรทำการ LogOut แล้ว LogIn ใหม่";
            this.messageAlert = this.lg_account_reset_password.messageAlert;
          this.completeDlg = true;
        } else {
          alert("result_update_account.data == null");
          this.$store.state.loadMenu = false;
        }
      } catch (error) {
        // alert("รหัสเดิมไม่ถูกต้อง กรุณากรอกใหม่อีกครั้ง");
        this.$store.state.loadMenu = false;
        this.account.password = "";
        // this.titleMessageAlert = "บันทึกข้อมูล";
        this.titleMessageAlert = this.lg_account_reset_password.dialog_check_save;
        // this.messageAlert = "รหัสเดิมไม่ถูกต้อง กรุณากรอกใหม่อีกครั้ง";
        this.messageAlert = this.lg_account_reset_password.dialog_failed_old_password;
        this.alertDlg = true;

        // this.$router.push("/account-resetpassword");
      }
    },
    async loadAccountData() {
      this.username = localStorage.getItem(server.USERNAME);
      // alert(JSON.stringify(username));
      this.resultJson = await api.checkRegister(this.username);
      // alert(JSON.stringify(this.resultJson.data.company_id));
      this.resultJsonCompanyName = await api.getCompanyByID(
        this.resultJson.data.company_id
      );
      // alert(JSON.stringify(this.resultJsonCompanyName.data));

      this.account.id = this.resultJson.data.id;
      this.account.username = this.resultJson.data.username;

      this.checkData = true;
      this.$store.state.loadMenu = false;
    },
  },
};
</script>

<style></style>
