<template>
  <div class="vote-container">
    <div
      class="vote-button noselect"
      @click="
        () => {
          createVoteVisible = true;
        }
      "
    >
      <h2>Vote</h2>
    </div>
    <vote-dialog
      :visible="createVoteVisible"
      :roomId="roomId"
      :room="room"
      :userId="userId"
      :user="user"
      @close="
        () => {
          createVoteVisible = false;
        }
      "
    />
  </div>
</template>

<script>
import VoteDialog from "./VoteDialog.vue";

export default {
  props: {
    roomId: {
      type: String,
    },
    room: {
      type: Object,
    },
    userId: {
      type: String,
    },
    user: {
      type: String,
    },
  },
  components: {
    VoteDialog,
  },
  data: () => ({
    createVoteVisible: false,
  }),
};
</script>

<style lang="scss" scoped>
$primary-color: rgb(7, 143, 195);

.vote-button {
  position: absolute;
  top: 0;
  left: 0;
  background: $primary-color;
  padding: 0.5rem;
  margin-top: 40vh;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  color: #fff;

  &:hover {
    cursor: pointer;
  }
}
</style>
