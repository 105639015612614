export const lg_department_list = {
  department_list_th: {
    title: "แผนก",
    department_search: "ค้นหาแผนก",
    department_add: "เพิ่มแผนก",
    dialog_title: "ยืนยันการลบ",
    dialog_delete:
      "คุณแน่ใจหรือว่าต้องการลบแผนกนี้ ? คุณไม่สามารถกู้คืนได้หลังจาก คลิกยืนยัน",
    dialog_confirm: "ยืนยัน",
    dialog_cancel: "ยกเลิก",
    headers: [
      { text: "รหัสแผนก", value: "id" },
      { text: "ชื่อแผนก", value: "department" },
      { text: "ชื่อบริษัท", value: "company_name" },
      { text: "Action", value: "action" },
    ],
  },
  department_list_en: {
    title: "Department",
    department_search: "Search Department",
    department_add: "Add Department",
    dialog_title: "Confirm Delete",
    dialog_delete:
      "Are you sure you want to delete this Department ? You can't restore it after clicking confirm",
    dialog_confirm: "Confirm",
    dialog_cancel: "Cancel",
    headers: [
      { text: "Department Id", value: "id" },
      { text: "Department Name", value: "department" },
      { text: "Company Name", value: "company_name" },
      { text: "Action", value: "action" },
    ],
  },
};

export const lg_department_add = {
  department_add_th: {
    title: "เพิ่มข้อมูล การตั้งค่า แผนก",
    department_name: "ชื่อแผนก",
    company_select: "เลือกบริษัท",
    btn_save: "บันทึก",
    btn_cancel: "ยกเลิก",
    titleMessageAlert: "กรุณาตรวจสอบข้อมูล",
    messageAlert: "ชื่อแผนกนี้ถูกใช้งานแล้ว กรุณาเปลี่ยนชื่อแผนกใหม่",
  },
  department_add_en: {
    title: "Add Department",
    department_name: "Department Name",
    company_select: "Select Company",
    btn_save: "Save",
    btn_cancel: "Cancel",
    titleMessageAlert: "Please check data",
    messageAlert:
      "This Department name is already used. Please change Department name",
  },
};

export const lg_department_edit = {
  department_edit_th: {
    title: "แก้ไขข้อมูล การตั้งค่า แผนก",
    company_select: "เลือกบริษัท",
    department_id: "รหัสแผนก",
    department_name: "ชื่อแผนก",
    btn_save: "บันทึก",
    btn_cancel: "ยกเลิก",
    titleMessageAlert: "กรุณาตรวจสอบข้อมูล",
    messageAlert: "ชื่อแผนกนี้ถูกใช้งานแล้ว กรุณาเปลี่ยนชื่อแผนกใหม่",
  },
  department_edit_en: {
    title: "Edit Department",
    company_select: "Select Company",
    department_id: "Department ID",
    department_name: "Department Name",
    btn_save: "Save",
    btn_cancel: "Cancel",
    titleMessageAlert: "Please check data",
    messageAlert:
      "This Department name is already used. Please change Department name",
  },
};
