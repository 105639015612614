export const lg_license_detail = {
  license_detail_th: {
    title1: "Free Feature",
    title2: "Licens Feature",
    btn_save: "บันทึก",
    dialog_popup_success: "สำเร็จ",
    dialog_popup_success_message: "บันทึกการตั้งค่าเรียบร้อย",
    dialog_popup_fail: "Error",
    dialog_popup_fail_message: "Error",
  },
  license_detail_en: {
    title1: "Free Feature",
    title2: "Licens Feature",
    btn_save: "Save",
    dialog_popup_success: "Success",
    dialog_popup_success_message: "Save Setting Success",
    dialog_popup_fail: "Error",
    dialog_popup_fail_message: "Error",
  },
};
