<template>
  <v-container style="padding-top: 20px">
    <div class="title-wrapper">
      <h3 class="page-title">{{ lg_vote_report.title }}</h3>
      <h4 class="page-sub-title">{{ lg_vote_report.search_conditions }}</h4>
    </div>
    <!-- vertical-top-row px-5   use for top position in table is class after elevation-1-->
    <v-data-table
      :headers="lg_vote_report.headers"
      :items="reports"
      class="elevation-1"
      :loading="loading"
      :items-per-page="itemsPerPage"
    >
      <template v-slot:top>
        <v-row>
          <v-menu
            v-model="dateMenuVisible"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
              class="mx-4"
                :value="renderDateSearch"
                clearable
                :label="lg_vote_report.search_date"
                readonly
                v-bind="attrs"
                v-on="on"
                @click:clear="dateSearch = null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateSearch"
              @change="dateMenuVisible = false"
            ></v-date-picker>
          </v-menu>

          <v-text-field
            v-model="topicSearch"
            class="mx-4"
            :label="lg_vote_report.search_topic"
            :clearable="true"
          ></v-text-field>

          <v-text-field
            v-model="createdBySearch"
            class="mx-4"
            :label="lg_vote_report.search_user"
            :clearable="true"
          ></v-text-field>
         
         
        </v-row>
      </template>

      <template v-slot:item.created_at="{ item }">
        {{ new Date(item.created_at).toLocaleDateString('en-GB',{
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    })}}
      </template>

      <template v-slot:item.options="{ item }">
        <p
          v-for="(o, i) in JSON.parse(item.options)"
          :key="`${o.id}-${i}`"
          style="margin: 0"
        >
          <span class="mr-2">{{ i + 1 }}.</span>
          <span class="mr-2">{{ o.text }}</span>
        </p>
      </template>

      <template v-slot:item.voted="{ item }">
        {{ JSON.parse(item.voted).length }}
      </template>

      <template v-slot:item.action="{ item }">
        <v-btn  color="#3E73BB" dark class="rounded-pill" @click="createPdf(item)">View</v-btn>
      </template>

    </v-data-table>
    <template>
      <VotePdfDialog
        v-if="setdatavote"
        :datavote="setdatavote"
        :visible="pdfDialog"
        :key="setdatavoteshow"
        @on-cancel="pdfDialog = false"
      />
    </template>
  </v-container>
</template>

<script>
import firebase from "firebase/app";
import api from "@/services/api";
import { format, parseISO } from "date-fns";
import VotePdfDialog from "@/components/core/VotePdfDialog";
import { server } from "../services/constants";

import { feature } from "../services/constants";
import { lg_vote_report } from "./language/lg_vote_report";

export default {
  data: () => ({
    itemsPerPage: 5,
    setdatavoteshow: 0,
    votes: [],
    setdatavote: "",
    createdBySearch: null,
    topicSearch: null,
    dateMenuVisible: false,
    dateSearch: null,
    loading: false,
    pdfDialog: false,
    pdfBytes: null,
    userRole: null,
    company_id: null,
  }),
  components: {
    VotePdfDialog,
  },
  computed: {
    renderDateSearch() {
      return this.dateSearch
        ? format(parseISO(this.dateSearch), "dd/MM/yyyy")
        : "";
    },
    reports() {
      const { votes, createdBySearch, topicSearch, dateSearch } = this;
      const reports = votes.filter((v) => {
        const {  meet_topic, created_at,question } = v;
        const validCreatedBy =
          createdBySearch?.length > 0
            ? question?.toLowerCase()?.includes(createdBySearch?.toLowerCase())
            : true;
        const validTopic =
          topicSearch?.length > 0
            ? meet_topic?.toLowerCase()?.includes(topicSearch?.toLowerCase())
            : true;

            const validDate = dateSearch
          ?  new Date(created_at).toLocaleDateString('en-CA') &&
          new Date(created_at).toLocaleDateString('en-CA') ===
          dateSearch
          : true;
        return validCreatedBy && validTopic && validDate;
      });

      return reports;
    },
  },
  methods: {
    async createPdf(item) {
      // console.log("createPdf:", JSON.stringify(item));
      // console.log(item);
      // this.$refs.listroom.loadListRoomMeetingData();
      this.setdatavoteshow++;
      // item.options = JSON.parse(item.options);
      this.setdatavote = item;
      this.pdfDialog = true;
      // try {
      //   const body = item;
      //   if (item.room && item.room.meet_topic) {
      //     body.roomName = item.room.meet_topic;
      //   }
      //   const response = await api.getVoteReport(body);
      //   console.log(response)
      //   if (response && response.data && response.data.pdfBytes) {
      //     this.pdfBytes = Uint8Array.from(
      //       Object.values(response.data.pdfBytes)
      //     );
      //     this.pdfDialog = true;
      //   }
      // } catch (e) {
      //   console.log(e.message);
      // }
    },
    async fetchVotes() {
      try {
        this.loading = true;
        let result;
        let setdata = [];
        let role = localStorage.getItem(server.ROLE);
        let userId = localStorage.getItem(server.USER_ID)?localStorage.getItem(server.USER_ID):0;
        if (role == "Admin") {
          result= await api.getAllPollVote();
          this.votes = result.data;
        }else{
          result= await api.getAllPollVoteByOwnerId(userId);
          if(result.data.length > 0){
            result.data.filter((x,i)=>{
              setdata.push(x.tbl_poll_vote);
              if(i == result.data.length-1){
                this.votes = setdata;
              }
            });
          }
         
        }
        // if(result.data){
        //   result.data.
        // }
       
        this.loading = false;
      } catch (error) {
        this.loading = false;

        this.$swal.fire("Error", error.message, "error");
      }
    },
  },
  beforeCreate() {
    localStorage.getItem(feature.LANGUAGE) === "th"
      ? (this.lg_vote_report = lg_vote_report.vote_report_th)
      : (this.lg_vote_report = lg_vote_report.vote_report_en);
  },
  async created() {
    try {
      const role = localStorage.getItem(server.ROLE);
      this.company_id = localStorage.getItem("company_id");
      this.userRole = role;
    } catch (e) {
      console.log(e.message);
    }
    if (api.isLoggedIn()) {
      this.fetchVotes();
    }
  },
};
</script>

<style>
.vertical-top-row > .v-data-table__wrapper > table > tbody > tr {
  vertical-align: text-top;
}

.title-wrapper {
  text-align: center;
  margin-bottom: 20px;
}

.page-title {
  font-size: 20px;
  margin-bottom: 10px;
}

.page-sub-title {
  font-weight: 400;
}
/*
.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center, .apexcharts-legend.apx-legend-position-top.apexcharts-align-center {
    display: block;
}
.apexcharts-xaxis-title{
  font-size:12px;
}
*/
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 85px;
}
</style>
