export const lg_policy = {
  policy_th: {
    privacy_consent: "การให้ความยินยอมเรื่องความเป็นส่วนตัว",
    text1:
      "DCM. จะปฏิบัติต่อข้อมูลส่วนบุคคลใด ๆ ที่ท่านได้ให้ไว้เป็นความลับและจะประมวลผลข้อมูลดังกล่าวตามเงื่อนไขและนโยบายความเป็นส่วนตัวของ DCM. เท่านั้น",
    text2: "ข้าพเจ้าได้อ่านและตกลงกับเงื่อนไขและนโยบายความเป็นส่วนตัวของ DCM.",
    accept: "ยอมรับ",
    notaccept: "ไม่ยอมรับ",
  },
  policy_en: {
    privacy_consent: "Privacy Consent",
    text1:
      "DCM will treat any personal information you provide confidential and will only process such information in accordance to DCM Terms and Privacy Policy.",
    text2: "I have read and agree to DCM Terms and Privacy Policy.",
    accept: "Accept",
    notaccept: "Deny",
  },
};
