<template>
  <v-card class="mx-auto mt-8 mb-8" max-width="600" outlined>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th>
              <h2 class="teal--text">
                {{ lg_license_detail.title1 }}
                <i
                  aria-hidden="true"
                  class="v-icon notranslate mdi mdi-account theme--light info--text"
                ></i>
              </h2>
            </th>
            <th>
              <h2 class="red--text">
                {{ lg_license_detail.title2 }}
                <i
                  aria-hidden="true"
                  class="v-icon notranslate mdi mdi-shield-account theme--light info--text"
                ></i>
              </h2>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div v-for="(f, i) in free" :key="`premium-${i}`">
                <v-text-field
                  :label="f.description"
                  v-model="f.valueInt"
                  v-if="f.valueType === 'INT'"
                  type="number"
                ></v-text-field>
                <v-checkbox
                  v-model="f.valueBool"
                  :label="f.description"
                  v-if="f.valueType === 'BOOL'"
                ></v-checkbox>
              </div>
            </td>
            <td>
              <div v-for="(f, i) in premium" :key="`premium-${i}`">
                <v-text-field
                  :label="f.description"
                  v-model="f.valueInt"
                  v-if="f.valueType === 'INT'"
                ></v-text-field>
                <v-checkbox
                  v-model="f.valueBool"
                  :label="f.description"
                  v-if="f.valueType === 'BOOL'"
                ></v-checkbox>
              </div>
            </td>
          </tr>
       
            <br />
            <!-- <v-btn class="mx-1" depressed dark color="indigo">
              <v-icon dark> mdi-plus </v-icon>เพิ่ม Feature
            </v-btn> -->
          
        </tbody>
      </template>
    </v-simple-table>

     <v-layout justify-center class="mb-4">
              <v-btn  depressed dark color="indigo" @click="onSave">
              <v-icon dark> mdi-content-save </v-icon>
              {{ lg_license_detail.btn_save }}
            </v-btn>
          
              </v-layout>

  </v-card>
</template>
<script>
import service from "../services/api_package";
import { feature } from "../services/constants";
import { lg_license_detail } from "./language/lg_license_detail";

export default {
  beforeCreate() {
    localStorage.getItem(feature.LANGUAGE) === "th"
      ? (this.lg_license_detail = lg_license_detail.license_detail_th)
      : (this.lg_license_detail = lg_license_detail.license_detail_en);
  },
  data: () => ({
    selected: ["WaitingRoom"],
    free: [],
    premium: [],
    originalFree: [],
    originalPremium: [],
  }),
  methods: {
    async fetchConfigs() {
      const res = await service.list();
      const all = res?.data ?? [];
      const free = all.filter((a) => a.group === "FREE");
      const premium = all.filter((a) => a.group === "PREMIUM");
      this.free = free;
      this.premium = premium;
      this.originalFree = JSON.stringify(free);
      this.originalPremium = JSON.stringify(premium);
    },
    async onSave() {
      this.$swal.showLoading();

      try {
        const { free, premium } = this;
        const originalFree = JSON.parse(this.originalFree);
        const originalPremium = JSON.parse(this.originalPremium);

        let updateList = [];

        for (const f1 of originalFree) {
          const f2 = free.find((f) => f.key === f1.key);
          if (f1.valueType === "BOOL") {
            if (f1.valueBool !== f2.valueBool) {
              updateList = [
                ...updateList,
                { id: f2.id, valueBool: f2.valueBool },
              ];
            }
          } else if (f1.valueType === "INT") {
            if (f1.valueInt !== f2.valueInt) {
              updateList = [
                ...updateList,
                { id: f2.id, valueInt: f2.valueInt },
              ];
            }
          }
        }

        for (const f1 of originalPremium) {
          const f2 = premium.find((f) => f.key === f1.key);
          if (f1.valueType === "BOOL") {
            if (f1.valueBool !== f2.valueBool) {
              updateList = [
                ...updateList,
                { id: f2.id, valueBool: f2.valueBool },
              ];
            }
          } else if (f1.valueType === "INT") {
            if (f1.valueInt !== f2.valueInt) {
              updateList = [
                ...updateList,
                { id: f2.id, valueInt: f2.valueInt },
              ];
            }
          }
        }

        await Promise.all(
          updateList.map(async (l) => {
            const id = l.id;
            delete l.id;
            await service.update(id, l);
          })
        );
        await this.fetchConfigs();
        this.$swal.close();
        setTimeout(() => {
          this.$swal.fire(
            this.lg_license_detail.dialog_popup_success,
            this.lg_license_detail.dialog_popup_success_message,
            this.lg_license_detail.dialog_popup_success
          );
        }, 500);
      } catch (error) {
        this.$swal.close();
        setTimeout(() => {
          this.$swal.fire(
            this.lg_license_detail.dialog_popup_fail,
            error.message,
            this.lg_license_detail.dialog_popup_fail_message
          );
        }, 500);
      }
    },
  },
  created() {
    this.fetchConfigs();
  },
};
</script>
