
export const lg_header_menu = {
    lg_header_menu_th: {
        home: "หน้าแรก",
        feature:"ฟีเจอร์",
        contact:"ติดต่อเรา",
        joinmeetbyid:"เข้าประชุม",
        signin:"เข้าสู่ระบบ",
        registerformeeting:"ลงทะเบียน",
    },
    lg_header_menu_en: {
      home: "Home",
      feature:"Features",
      contact:"Contact Us",
      joinmeetbyid:"Join Meeting",
      signin:"Login",
      registerformeeting:"Register",
    },
  };

// import {feature} from "@/services/constants";
//  let lg_header_menu = {
//     lg_header_menu_th: {
//         home: "หน้าแรก",
//         feature:"ฟีเจอร์",
//         contact:"ติดต่อเรา",
//         joinmeetbyid:"เข้าประขุมด้วย ID",
//     },
//     lg_header_menu_en: {
//       home: "Home",
//       feature:"Feature",
//       contact:"contact",
//       joinmeetbyid:"Joinmeet By ID",
//     },
//   };
//   console.log("ภาษา คือ" + localStorage.getItem(feature.LANGUAGE))
//   if(localStorage.getItem(feature.LANGUAGE) === 'th'){
//     lg_header_menu = lg_header_menu.lg_header_menu_th
//   }else{
//     lg_header_menu = lg_header_menu.lg_header_menu_en
//   }
//   console.log(lg_header_menu)

// export default lg_header_menu;