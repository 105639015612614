import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getAllPollVote = () => {
  return httpClient.get(server.POLL_VOTE);
};

export const getAllPollVoteByCompanyId = (id) => {
  return httpClient.get(server.POLL_VOTE + `/${id}`);
};

export const getAllPollVoteById = (id) => {
  return httpClient.get(server.POLL_VOTE + `/byid/${id}`);
};

export const getAllPollVoteByUserId = (id) => {
  return httpClient.get(server.POLL_VOTE + `/byuser_id/${id}`);
};

export const checkByNamePollVote= (department, id_company) => {
  return httpClient.get(
    server.POLL_VOTE + `/checkbyname/${department}/${id_company}`
  );
};


export const addPollVote = (data) => {
  return httpClient.post(server.POLL_VOTE, data);
};

export const deletePollVote = (id) => {
  return httpClient.delete(server.POLL_VOTE + `/${id}`);
};

export const updatePollVote = (id, data) => {
  return httpClient.put(server.POLL_VOTE + `/${id}`, data);
};

export const getAllPollVoteByOwnerId = (id) => {
  return httpClient.get(server.POLL_VOTE + `/byowner_room/${id}`);
};