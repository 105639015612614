import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const addVerify = (data) => {
  return httpClient.post(server.VERIFY, data);
};

export const deleteVerify = (meet_id, ver_email) => {
  return httpClient.delete(
    server.VERIFY + `/deleteverify/${meet_id}/${ver_email}`
  );
};

export const checkOTP = (meet_id, ver_email, ver_expire) => {
  return httpClient.get(
    server.VERIFY +
      `/checkotp?ver_email=${ver_email}&meet_id=${meet_id}&ver_otp=${ver_expire}`
  );
};
