import axios from "axios";
import {
  server,
  apiUrl,
  apiMeetUrl,
  NOT_CONNECT_NETWORK,
  NETWORK_CONNECTION_MESSAGE,
} from "@/services/constants";
import router from "@/router";

const isAbsoluteURLRegex = /^(?:\w+:)\/\//;

axios.interceptors.request.use(async (config) => {
  if (!isAbsoluteURLRegex.test(config.url)) {
    const userToken = localStorage.getItem(server.TOKEN_KEY);
    const meetSta = localStorage.getItem(server.MEET_STA);
    if (userToken) {
      config.headers = { "x-access-token": userToken };
    }
    // console.log("meetSta : " + meetSta);
    // config.url = apiUrl + "/" + config.url;
    if (meetSta == "true") {
      config.url = apiMeetUrl + "/" + config.url;
    } else {
      config.url = apiUrl + "/" + config.url;
    }
    // console.log("httpClient Url: " + config.url);
  }
  config.timeout = 10000; // 10 Second
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // console.log(JSON.stringify(error, undefined, 2));
    // router.push("/");
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    } else if (!error.response) {
      return Promise.reject({
        code: NOT_CONNECT_NETWORK,
        message: NETWORK_CONNECTION_MESSAGE,
      });
    }
    return Promise.reject(error);
  }
);

export default axios;
