<template>
  <v-main>
  <v-container class="joinmeeting-container">
    <v-row class="ma-1" justify="center" >
      <v-col align="center" justify="center"  cols="12" xl="5" lg="5" md="6" sm="12" xs="12">
        <div>
          <h1 class="display-6 fw-bold lh-1 mb-3" v-bind:style="{ fontSize:'38px' }">
            {{this.lg_joinmeetbtid.title}}
          </h1>

          <h2 align="left" v-bind:style="{ color: '#000000' }" class="font-weight-regular">
           {{this.lg_joinmeetbtid.subtitle1}}
          </h2>
          <br/>
          <h2 align="left" v-bind:style="{ color: '#000000' }" class="font-weight-regular">
              {{this.lg_joinmeetbtid.subtitle2}}
          </h2>
          <br/>
          <h2 align="left" v-bind:style="{ color: '#000000' }" class="font-weight-regular">
              {{this.lg_joinmeetbtid.subtitle3}}
          </h2>
          <br />

          <v-form @submit.prevent="onSubmit">
            <v-container align="center" justify="center">
              <v-text-field
              v-bind:style="{ width: '80%' }"
              class="textinform"
              solo
              dense
                :label="this.lg_joinmeetbtid.formlabel"
                :placeholder="this.lg_joinmeetbtid.formplaceholder"
                outlined
                v-model="form.meetId"
                @keypress="isNumber($event)"
              ></v-text-field>
 <v-btn :small="buttonsmall"  type="submit"  v-bind:style="{ width: '150px', height: '36px', fontSize: '14px', backgroundColor: '#205295', color: '#ffffff' }">
 {{this.lg_joinmeetbtid.buttonjoin}}
              </v-btn>
            </v-container>
          </v-form>
        </div>
      </v-col>
      <v-col align="center" justify="center" cols="12" xl="5" lg="5" md="6" sm="12" xs="12">
       <v-row align="center" justify="center">
            <v-img 
            :max-height="widthheightimage"
          :max-width="widthheightimage"
            align-center 
            justify-center
             src="../assets/join_meeting.jpg" contain />
        
     </v-row>
      </v-col>
    </v-row>
  </v-container>
</v-main>
</template>

<script>
import { getByMeetNameOnly } from "@/services/api_meetroom";
import api from "@/services/api";
import {lg_joinmeetbtid} from './language/lg_header_joinmeetbyid.js'
import { feature } from "../services/constants.js";

export default {
  data: () => ({
    form: {
      meetId: undefined,
    },
  }),
  methods: {
    isNumber(evt) {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async onSubmit() {
      try {
        const { meetId } = this.form;
        const res = await api.getByMeetNameOnlydyte(meetId.trim());
        const {
          data: { meet_appid, meet_sta },
        } = res;
        if (meet_sta !== "Y") {
          throw new Error("The meeting was finished");
        }
        const url = `/meet/dmeets/${meetId.trim()}/meeting`;

        // this.resultJson = await api.getAllAppId();
        // this.appId = this.resultJson.data[0].app_id_name;

        this.$router.push(url);
      } catch (error) {
        if (error.response?.status === 404) {
          this.$swal.fire("ผิดพลาด", "Meeting ID ไม่ถูกต้อง", "error");
        } else {
          this.$swal.fire("ผิดพลาด", error?.message, "error");
        }
      }
    },
    
  },
   computed: {
  buttonsmall() {
     if(this.$vuetify.breakpoint.name === "xs"){
       return true;
     }
      },
      widthheightimage(){
 switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "350";
        case "sm":
          return "450";
        case "md":
          return "450";
        case "lg":
          return "500";
        case "xl":
          return "500";
      }
      },
    widthbutton() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "40%";
        case "sm":
          return "25%";
        case "md":
          return "25%";
        case "lg":
          return "25%";
        case "xl":
          return "20%";
      }
    },
    },
   async beforeCreate() {
      this.$store.state.page_reload = false;
      this.$store.state.meetSta = false;

      // alert(localStorage.getItem("language"))
      // console.log("language login : " + localStorage.getItem(feature.LANGUAGE));

      localStorage.getItem(feature.LANGUAGE) === "th"
        ? this.lg_joinmeetbtid = lg_joinmeetbtid.lg_joinmeetbtid_th
        : this.lg_joinmeetbtid = lg_joinmeetbtid.lg_joinmeetbtid_en;

        // console.log("lg_login: " + JSON.stringify(this.lg_login));
        
    },
};
</script>

<style scoped>
.textinform{
  width:75%;
}
@media (max-width: 599px) {
  .joinmeeting-container {
    margin-top:-150px;
  }
  .v-main {
    margin-top:-50px;
    height: 110% !important;
  }
}
@media (max-width: 959px) {
  .joinmeeting-container {
    margin-top:-180px !important;
  }
}

@media (min-width: 1260px) {
  .joinmeeting-container {
    margin-top: 5rem;
  }
  
}

.v-main {
    max-width: 100%;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to right top, #cbe9f8, #dcedfc, #ebf2fd, #f7f8fe, #ffffff) !important;
}
.joinmeeting-container {
    margin-top: -10px;
  }
</style>
