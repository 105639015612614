export const lg_list_account = {
  list_account_th: {
    title: "บัญชีผู้ใช้งาน",
    search: "ค้นหา",
    headers: [
      { text: "รหัส", value: "id" },
      { text: "อีเมล", value: "username" },
      { text: "ชื่อเต็ม", value: "acc_fullname" },
      { text: "วันเกิด", value: "acc_birth" },
      { text: "เลขบัตรประชาชน", value: "acc_id_card" },
      { text: "บริษัท", value: "company_name" },
      { text: "สิทธิ์", value: "role" },
      { text: "Plan", value: "acc_plan" },
      { text: "Action", value: "action" },
    ],
  },
  list_account_en: {
    title: "Accounts",
    search: "Search",
    headers: [
      { text: "Id", value: "id" },
      { text: "Email", value: "username" },
      { text: "Full Name", value: "acc_fullname" },
      { text: "Birthday", value: "acc_birth" },
      { text: "Identification Number", value: "acc_id_card" },
      { text: "Company", value: "company_name" },
      { text: "Role", value: "role" },
      { text: "Plan", value: "acc_plan" },
      { text: "Action", value: "action" },
    ],
  },
};
