export const lg_meet_page = {
  meet_page_th: {
    input_password: "ระบุ รหัสผ่าน",
    label_password: "รหัสผ่าน",
    btn_save: "บันทึก",
    invalid_url: "URL ไม่ถูกต้อง",
    invalid_password: "รหัสผ่าน ไม่ถูกต้อง",
    dialog_check_data: "กรุณาตรวจสอบข้อมูล",
    input_name: "ระบุชื่อ",
    join_meeting: "เข้าร่วมประชุม",
    btn_enter: "ตกลง",
    title_error: "ผิดพลาด",
  },
  meet_page_en: {
    input_password: "Enter password",
    label_password: "Password",
    btn_save: "Save",
    invalid_url: "Invalid URL",
    invalid_password: "Invalid password",
    dialog_check_data: "Please check your information",
    input_name: "Enter Name",
    join_meeting: "Join Meeting",
    btn_enter: "Enter",
    title_error: "Error",
  },
};
