export const lg_footer_userrequest = {
  lg_footer_userrequest_th: {
    title: "กรุณากรอกข้อมูล",
    name: "ชื่อ-นามสกุล",
    placeholdername: "ชื่อ-นามสกุล",
    email: "อีเมล์",
    placeholderemail: "อีเมล์",
    phonenumber: "เบอร์โทรศัพท์",
    placeholderphonenumber: "เบอร์โทรศัพท์",
    subject: "หัวข้อ",
    placeholderphonesubject: "หัวข้อ",
    message: "ข้อความ",
    placeholdermessage: "กรุณาเขียนรายระเอียดการขอใช้สิทธิ",
    buttonsend: "ส่ง",
  },
  lg_footer_userrequest_en: {
    title: "Please fill out",
    name: "Firstname-Lastname",
    placeholdername: "Firstname-Lastname",
    email: "Email",
    placeholderemail: "Email",
    phonenumber: "Phone Number",
    placeholderphonenumber: "Phone Number",
    subject: "Subject",
    placeholdersubject: "Subject",
    message: "Message",
    placeholdermessage: "Please explain the reason(s) for permission request.",
    buttonsend: "Send",
  },
};
