export const lg_meeting_recording = {
  meeting_recording_th: {
    title: "การบันทึก",
    search_conditions: "เงื่อนไขในการค้นหา",
    search_user: "ผู้สร้าง",
    search_topic: "ชื่อการประชุม",
    search_date: "วันที่",
    headers: [
      { text: "ลำดับ", value: "index" },
      { text: "เวลาเริ่มการบันทึก", value: "started_time" },
      { text: "เวลาหยุดการบันทึก", value: "stopped_time", align: "center" },
      { text: "ชื่อการประชุม", value: "meeting.title" },
      { text: "ไอดีการบันทึก", value: "id", sortable: false },
      { text: "", value: "action" },
    ],
  },
  meeting_recording_en: {
    title: "Recording",
    search_conditions: "Search Conditions",
    search_user: "User",
    search_topic: "Meeting Name",
    search_date: "Date",
    headers: [
      { text: "No.", value: "index" },
      { text: "Rec. Start Time", value: "started_time" },
      { text: "Rec. Stop Time", value: "stopped_time", align: "center" },
      { text: "Meeting Name", value: "meeting.title" },
      { text: "Recording ID", value: "id", sortable: false },
      { text: "", value: "action" },
    ],
  },
};
