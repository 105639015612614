<template>
  <v-container class="grey lighten-5">
    <v-row no-gutters>
      <v-col cols="12" md="5">
        <v-card outlined tile v-bind:style="{ padding: '0.5rem 2rem 2rem' }">
          <!-- formCreateMeetroom -->
          <br />
          <h3 class="text-center">
            <v-icon color="info">mdi-shield-account</v-icon>
            {{ this.lg_create_meetroom.title }}
          </h3>

          <br />

          <form @submit.prevent="onSubmit">
            <div class="home-page">
              <!-- <img src="/logo.png" alt="" class="app-logo" />
      <br /> -->

              <div class="buttons mb-5">
                <v-tabs fixed-tabs background-color="#205295" dark>
                  <v-tabs-slider color="#FFE9B1"></v-tabs-slider>
                  <v-tab
                    :color="createMode === 'now' ? 'primary' : 'default'"
                    @click="createMode = 'now'"
                  >
                    {{ this.lg_create_meetroom.create_room_now }}
                  </v-tab>
                  <v-tab
                    :color="createMode === 'schedule' ? 'primary' : 'default'"
                    @click="createMode = 'schedule'"
                  >
                    {{ this.lg_create_meetroom.create_room_schedule }}
                  </v-tab>
                </v-tabs>
              </div>

              <div class="create-room-form">
                <v-text-field
                  v-bind:style="{ width: '100%' }"
                  :placeholder="lg_create_meetroom.meet_topic"
                  outlined
                  v-model="topic"
                  autocomplete="off"
                ></v-text-field>
                <div style="width: 100%" v-if="createMode === 'schedule'">
                  <v-menu
                    ref="dateMenu"
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="formatDate"
                        clearable
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        :placeholder="lg_create_meetroom.meet_date_start"
                        style="width: 100%"
                        class="white-input"
                        required
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      no-title
                      scrollable
                      locale="th-TH"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="dateMenu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dateMenu.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                  <v-menu
                    ref="timeMenu"
                    v-model="timeMenu"
                    :close-on-content-click="false"
                    :return-value.sync="time"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    required
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="time"
                        clearable
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        :placeholder="lg_create_meetroom.meet_time_start"
                        style="width: 100%"
                        class="white-input"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="time"
                      scrollable
                      locale="th-TH"
                      format="24hr"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="dateMenu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.timeMenu.save(time)"
                      >
                        OK
                      </v-btn>
                    </v-time-picker>
                  </v-menu>
                </div>
                <v-text-field
                  style="width: 100%"
                  :placeholder="lg_create_meetroom.meet_password"
                  outlined
                  v-model="password"
                  class="white-input"
                  autocomplete="new-password"
                  type="password"
                  :loading="loading"
                ></v-text-field>
             <v-card class="bordercardoption" >
              <h2 class="pt-2" v-bind:style="{ margin: '1rem 2rem 0' }">
                {{ this.lg_create_meetroom.meet_option }}
              </h2>
           <v-card-text v-bind:style="{ margin: '0 1rem' }">
                <v-checkbox class="checkbox-options"
                  v-model="cbLobby"
                  :label="lg_create_meetroom.meet_option_lobby"
                ></v-checkbox>
                <v-checkbox class="checkbox-options"
                  v-model="cbVerify"
                  :label="lg_create_meetroom.meet_option_verify_identify"
                ></v-checkbox>
                <v-checkbox class="checkbox-options"
                  v-model="selected"
                  :label="lg_create_meetroom.meet_option_share_screen"
                  value=""
                ></v-checkbox>
                 </v-card-text>
                       </v-card>

          <v-row class="justify-center mb-5  mt-5 ">
            <v-btn type="submit" v-bind:style="{ backgroundColor: '#205295', color: '#ffffff' }">
              {{
                  this.lg_create_meetroom.meet_create
                }}
            </v-btn>
          </v-row>
              </div>
            </div>
            <LoadingDialog></LoadingDialog>
            <CreateRoomSuccessDialog
              :visible="resultVisible"
              :result="result"
              @close="
                () => {
                  resultVisible = false;
                  $refs.listroom.loadListRoomMeetingData();
                }
              "
            />
       
          </form>
        </v-card>
      </v-col>
      <v-col cols="12" md="7">
        <v-card class="ma-1 pa-2" outlined tile>
          <!-- Import ListRoom2 -->
          <ListRoom ref="listroom" />
        </v-card>
      </v-col>
    </v-row>

    <!-- Privacy Dialog -->
    <v-dialog v-model="privacyDlg" :max-width="maxwidthdialog">
      <v-card v-bind:style="{ padding: '1rem' }">
        <v-img height="200" contain src="../assets/privacypolicy4.png"></v-img>

        <v-card-title primary-title class="justify-center">
          {{ this.lg_policy.privacy_consent }}</v-card-title
        >

        <v-divider class="mx-4"></v-divider>

        <v-card-text>
          <div class="mt-3">
            {{ this.lg_policy.text1 }}
          </div>
          <div class="mt-3">
            {{ this.lg_policy.text2 }}
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
           :small="buttonsmall"
            outlined
            color="success"
            text
            @click="onclickPrivacyDlg()"
            col="12"
            lg="12"
            md="12"
            sm="12"
          >
            {{ this.lg_policy.accept }}
          </v-btn>

          <v-btn
           :small="buttonsmall"
            outlined
            color="error"
            text
            @click="onclickNonPrivacyDlg()"
            col="12"
            lg="12"
            md="12"
            sm="12"
          >
            {{ this.lg_policy.notaccept }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Privacy Dialog -->
  </v-container>
</template>

<script>
// import axios from "axios";
import { format, parseISO, parse } from "date-fns";
import { th } from "date-fns/locale";
import ListRoom from "../views/ListRoom2.vue";

import api from "@/services/api";
import { server } from "../services/constants";
import { feature } from "../services/constants";
import LoadingDialog from "@/components/cards/LoadingDialog.vue";
import CreateRoomSuccessDialog from "@/components/cards/CreateRoomResultDialog.vue";

import { lg_create_meetroom } from "./language/lg_create_meet_room";
import { lg_policy } from "./language/lg_policy";

export default {
  components: {
    CreateRoomSuccessDialog,
    LoadingDialog,
    ListRoom,
  },
  async beforeCreate() {
    localStorage.getItem(feature.LANGUAGE) === "th"
      ? (this.lg_create_meetroom = lg_create_meetroom.create_meetroom_th)
      : (this.lg_create_meetroom = lg_create_meetroom.create_meetroom_en);

    localStorage.getItem(feature.LANGUAGE) === "th"
      ? (this.lg_policy = lg_policy.policy_th)
      : (this.lg_policy = lg_policy.policy_en);

    // console.log(
    //   "lg_create_meetroom: " + JSON.stringify(this.lg_create_meetroom)
    // );

    // console.log("lg_policy: " + JSON.stringify(this.lg_policy));

    // this.$forceUpdate();
    if (!this.$store.state.page_reload) {
      this.$store.state.page_reload = true;
      // this.$router.go(); // Refreshes page
    }

    this.acc_plan = localStorage.getItem(server.ACC_PLAN_STA);
    const response_package = await api.getPackageConfig(this.acc_plan);

    // console.log(`package config : ${JSON.stringify(response_package.data)}`);

    this.mDataPackageArray = response_package.data;
    let count = this.mDataPackageArray.length;

    // console.log(`package config : ${count}`);
    // console.log(
    //   `package config : ${JSON.stringify(this.mDataPackageArray[3].valueBool)}`
    // );

    for (let i = 0; i < count; i++) {
      let key = this.mDataPackageArray[i].key;

      if (key == "WAITING_ROOM") {
        localStorage.setItem(
          feature.FEATURE_LOBBY,
          this.mDataPackageArray[i].valueBool
        );
      } else if (key == "RECORDING") {
        localStorage.setItem(
          feature.FEATURE_RECORD,
          this.mDataPackageArray[i].valueBool
        );
      } else if (key == "SUBTITLE") {
        localStorage.setItem(
          feature.FEATURE_SUBTITLE,
          this.mDataPackageArray[i].valueBool
        );
      } else if (key == "VOTE") {
        localStorage.setItem(
          feature.FEATURE_VOTE,
          this.mDataPackageArray[i].valueBool
        );
      } else if (key == "VOTE_REPORT") {
        localStorage.setItem(
          feature.FEATURE_VOTE_REPORT,
          this.mDataPackageArray[i].valueBool
        );
      } else if (key == "CONTROL_MIC_CAMERA") {
        localStorage.setItem(
          feature.FEATURE_MUTE_EVERYONE,
          this.mDataPackageArray[i].valueBool
        );
      }
    }

    // console.log(
    //   `load package config : ${localStorage.getItem(
    //     feature.FEATURE_VOTE_REPORT
    //   )}`
    // );
    // for (let i=0;i<count;i++){
    //     this.mDataArray.push(result.data.rows.[i]);
    // }
    try{
     if(this.$store.state.checklinkmeeting === true){
throw new Error(this.lg_create_meetroom.contentmeeterror);
     }
     
     if(this.$store.state.checkidmeeting === true){
          this.$swal.fire(this.lg_create_meetroom.titlemeeterror, this.lg_create_meetroom.contentmeetiderror, "error");
      
     }
   
}catch (error) {
   if (error.response?.status === 404) {
          this.$swal.fire(this.lg_create_meetroom.titlemeeterror, this.lg_create_meetroom.contentmeetiderror, "error");
        } else {
          this.$swal.fire(this.lg_create_meetroom.titlemeeterror, error?.message, "error");
        }
      }
  },
  async mounted() {
    localStorage.getItem(feature.LANGUAGE) === "th"
      ? (this.lg_create_meetroom = lg_create_meetroom.create_meetroom_th)
      : (this.lg_create_meetroom = lg_create_meetroom.create_meetroom_en);

    localStorage.getItem(feature.LANGUAGE) === "th"
      ? (this.lg_policy = lg_policy.policy_th)
      : (this.lg_policy = lg_policy.policy_en);

    // console.log(
    //   "lg_create_meetroom: " + JSON.stringify(this.lg_create_meetroom)
    // );

    // console.log("lg_policy: " + JSON.stringify(this.lg_policy));

    const userId = localStorage.getItem(server.USER_ID);
    if (userId && api.isLoggedIn()) {
      const response = await api.checkIfUserAgreed(userId);
      if (!response || !response.data || !response.data.id) {
        this.privacyDlg = true;
      }
      this.onloadAppIdAndApiKey();
    } else {
      // this.privacyDlg = true;
      // this.$router.push("/");
    }
  },
  data: () => ({
    reload_page: false,
    mDataArray: [],
    mDataPackageArray: [],
    privacy_sta: "false",
    privacyDlg: false,
    loading: false,
    resultVisible: false,
    createMode: "now",
    acc_plan: "",
    appId: "",
    apiKey: "",
    password: null,
    dateMenu: false,
    timeMenu: false,
    date: null,
    time: null,
    result: null,
    topic: null,
    resultJson: "",
    resultJsonCompanyName: null,
    username: "",
    cbLobby: false,
    cbVerify: false,
    meet: {
      meet_id_show: "",
      meet_name: "",
      meet_appid: "vpaas-magic-cookie-e205800afeca4002aad705d1994953cb",
      meet_apikey: "vpaas-magic-cookie-e205800afeca4002aad705d1994953cb/560000",
      meet_token: "",
      meet_sta: "Y",
      account_id: 0,
      company_id: 0,
      company_name: "",
      department_id: 0,
      department_name: "",
      meet_create: null,
      meet_end: null,
      meet_admin_link: "",
      meet_user_link: "",
      meet_pass: "",
      meet_topic: "",
      meet_start: null,
      meet_lobby_sta: "N",
      meet_verify_sta: "N",
      meet_room_license: "FREE",
    },
    meetjoin: {
      meet_id: 0,
      meet_join_name: "",
      account_id: 0,
    },
    selected: null,
  }),
  computed: {
    formatDate() {
      const { date } = this;
      if (!date) return "";
      return format(parseISO(date), "yyyy-MM-dd", { locale: th });
    },
     maxwidthdialog() {
      switch (this.$vuetify.breakpoint.name) {
         case "xs":
          return "100%";
        case "sm":
            return "70%";
        case "md":
           return "50%";
        case "lg":
           return "40%";
        case "xl":
            return "40%";
      }
    },
    buttonsmall() {
     if(this.$vuetify.breakpoint.name === "xs"){
       return true;
     }
      },
  },
  methods: {
    async onclickPrivacyDlg() {
      if (api.isLoggedIn()) {
        const userId = localStorage.getItem(server.USER_ID);
        const username = localStorage.getItem(server.FULLNAME);
        if (userId) {
          const body = {
            account_id: userId,
            account_name: username,
            device_platform: "web",
          };
          try {
            const response = await api.createPDPA(body);
            if (response && response.data && response.data.id) {
              this.privacyDlg = false;
            }
          } catch (e) {
            console.log(e.message);
          }
        }
      } else {
        this.privacyDlg = false;
      }
    },
    onclickNonPrivacyDlg() {
      this.$store.dispatch("doLogout");
      this.privacyDlg = false;
    },
    async onloadAppIdAndApiKey() {
      this.$store.state.loadMenu = true;
      this.mDataArray = await api.getAllAppId();
      // console.log(JSON.stringify(this.mDataArray.data[0].app_id_name));
      this.appId = this.mDataArray.data[0].app_id_name;
      // console.log(this.appId);
      this.mDataArray = await api.getAllApiKey();
      // console.log(JSON.stringify(this.mDataArray.data));
      this.apiKey = this.mDataArray.data[0].api_key_name;
      // console.log(this.apiKey);
      this.$store.state.loadMenu = false;
    },
    currentDateTime() {
      const current = new Date();
      const date =
        current.getFullYear() +
        "-" +
        (current.getMonth() + 1) +
        "-" +
        current.getDate();
      const time =
        current.getHours() +
        ":" +
        current.getMinutes() +
        ":" +
        current.getSeconds();
      const dateTime = date + " " + time;

      return dateTime;
    },
    async onSubmit2() {
      alert(localStorage.getItem(server.ACC_PLAN_STA));
    },
    async onSubmit() {
      const { createMode, password, date, time, topic } = this;
      const userId = localStorage.getItem(server.USER_ID);
      try {
        let body = { password, host: window.location.origin, topic, userId };
        if (createMode === "schedule") {
          if (!date || !time) {
            throw new Error("กรุณากรอกวันและเวลาเริ่ม");
          }
          const dateOnly = format(parseISO(date), "yyyy-MM-dd");
          const startDate = parse(
            `${dateOnly} ${time}`,
            "yyyy-MM-dd HH:mm",
            new Date()
          );
          body = {
            ...body,
            startDate,
          };
        }

        this.$swal.showLoading();

        // const res = await axios.post("/rooms", body);

        localStorage.setItem(server.MEET_STA, "true");
        const res = await api.meetRoom(body);
        if (res && res.data && res.data.status === "error") {
          this.$swal.fire(
            res.data.title || "Error",
            res.data.message || "Try again",
            "error"
          );
          return;
        }
        localStorage.setItem(server.MEET_STA, "false");

        // {"id":"2221240092272505",
        // "roomName":"2221 2400 9227 2505",
        // "password":null,
        // "startDate":null,
        // "topic":"44444",
        // "updatedAt":"2021-07-15T09:29:49.931Z",
        // "createdAt":"2021-07-15T09:29:49.931Z",
        // "modLink":"http://localhost:8080/meet/vpaas-magic-cookie-0d676e1889ea4299a153f25b1d1ee9f4/2221240092272505?moderator=true",
        // "userLink":"http://localhost:8080/meet/vpaas-magic-cookie-0d676e1889ea4299a153f25b1d1ee9f4/2221240092272505"}

        // console.log(JSON.stringify(res.data));
        // if (this.createMode == "schedule") {
        //   alert("schedule");
        // alert(JSON.stringify(res.data));
        this.$store.state.loadMenu = true;
        this.username = localStorage.getItem(server.USERNAME);
        this.resultJson = await api.checkRegister(this.username);

        // this.resultJsonCompanyName = await api.getCompanyByID(
        //   this.resultJson.data.company_id
        // );
        // this.meet.meet_token = token;
        this.meet.meet_id_show = res.data.roomName;
        this.meet.meet_name = res.data.id;
        this.meet.meet_appid = this.appId;
        this.meet.meet_apikey = this.apiKey;
        this.meet.meet_admin_link = res.data.modLink;
        this.meet.meet_user_link = res.data.userLink;
        this.meet.meet_pass = this.password;
        this.meet.meet_topic = res.data.topic;
        if (this.createMode == "schedule") {
          // alert("schedule");
          this.meet.meet_start = res.data.startDate;
        } else {
          this.meet.meet_start = this.currentDateTime();
        }

        if (this.cbLobby == true) {
          // alert("schedule");
          this.meet.meet_lobby_sta = "Y";
        } else {
          this.meet.meet_lobby_sta = "N";
        }

        if (this.cbVerify == true) {
          // alert("schedule");
          this.meet.meet_verify_sta = "Y";
        } else {
          this.meet.meet_verify_sta = "N";
        }

        // alert(this.meet.meet_start);

        //   this.meet.meet_appid = this.appId;
        //   this.meet.meet_apikey = this.appId;
        this.meet.account_id = this.resultJson.data.id;
        this.meet.company_id = this.resultJson.data.company_id;
        // this.meet.company_name = this.resultJsonCompanyName.data.company_name;
        this.meet.company_name = localStorage.getItem(server.COMPANY_NAME);
        this.meet.department_id = localStorage.getItem(server.DEPARTMENT_ID);
        this.meet.department_name = localStorage.getItem(
          server.DEPARTMENT_NAME
        );

        // alert(this.meet.department_id);
        // alert(this.meet.department_name);
        this.meet.meet_create = this.currentDateTime();
        this.meet.meet_end = this.currentDateTime();

        if (localStorage.getItem(server.ROLE) == "Admin") {
          this.meet.meet_room_license = "PREMIUM";
        } else {
          this.meet.meet_room_license = localStorage.getItem(
            server.ACC_PLAN_STA
          );
        }

        // alert(this.meet.meet_room_license);

        await api.addMeet(this.meet);

        this.meetjoin.meet_join_name = this.resultJson.data.acc_fullname;
        this.meetjoin.account_id = this.resultJson.data.id;

        this.resultJson = await api.checkByNameMeet(
          this.meet.meet_name,
          this.meet.account_id
        );
        this.meetjoin.meet_id = this.resultJson.data.id;

        await api.addMeetJoin(this.meetjoin);
        this.$store.state.navMenu = false;
        this.$store.state.loadMenu = false;
        // }

        this.result = {
          ...res.data,
          password,
        };
        this.resultVisible = true;
        this.loading = true;
        this.date = null;
        this.time = null;
        this.topic = null;
        this.password = null;
        this.$swal.close();

        this.loading = false;
      } catch (error) {
        this.$swal.close();
        this.loading = false;
        this.$swal.fire("Error", error.message, "error");
        console.log(error);
      }
    },
  },
};
</script>
<style scoped>
.v-input--selection-controls {
     margin-top: 0px; 
}
.v-tabs--fixed-tabs > .v-tabs-bar .v-tab {
      width: min-content;
}
.bordercardoption{
  border: 1px solid gray;
  box-shadow: none !important;
}

.checkbox-options {
  height: 35px;
}
</style>
