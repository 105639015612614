import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const meetGenerateJWTToken = (data) => {
  return httpClient.post(server.MEET_GET_JWT, data);
};

export const meetRoom = (data) => {
  return httpClient.post(server.ROOM, data);
};

export const getMeetRoom = (roomId) => {
  return httpClient.get(server.ROOM + `/${roomId}`);
};
