<template lang="html">
  <nav>
  <!-- absolute after permanent menu not fixed-->
    <v-navigation-drawer app permanent  dark v-model="drawer">
      <!-- <router-link to="/" exact>
        <img src="@/assets/logo.png" alt="" width="100%" class="pa-5" />
      </router-link> -->

      <v-list shaped>
        <v-subheader>MENUS</v-subheader>
        <v-list-item-group v-model="selectedMenu" mandatory color="primary">
          <v-list-item
            class="tile"
            v-for="([icon, text, route], i) in menus"
            :key="i"
            link
            @click="onClickMenu(route)"
          >
            <v-list-item-icon>
              <v-icon>{{ icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import { feature } from "../../services/constants";
import { lg_menu } from "../../views/language/lg_menu";

export default {
  name: "Menu",
  props: [],
  

  mounted() {
    let role = localStorage.getItem("role");

    // console.log("language menu mounted : " + localStorage.getItem(feature.LANGUAGE));
    localStorage.getItem(feature.LANGUAGE) === "th"
      ? (this.lg_menu = lg_menu.menu_th)
      : (this.lg_menu = lg_menu.menu_en);

    // console.log(
    //   "language menu mounted : " + JSON.stringify(this.lg_menu.menus_admin)
    // );

    if (role == "Admin") {
      this.menus = this.lg_menu.menus_admin;
    } else if (role == "Company Admin") {
      this.menus = this.lg_menu.menus_company_admin;
    } else {
      this.menus = this.lg_menu.menus_member;
    }
   
if(this.selectedMenu != -1){
  
    this.selectedMenu = this.menus.findIndex(
      (menu) => menu[2] == this.$route.path
    );
  }
 
  },
  data() {
    return {
      menus: null,
      selectedMenu: 0,
      oldselectedMenu:0,
      drawer: true,
    };
  },
  methods: {
    onClickMenu(link) {
      console.log(link)
      this.$router.push(link).catch((err) => {});
    },
  },
  watch: {
    $route(to, from) {
      // ชั้นของ Array || menu[2]
      this.oldselectedMenu = this.selectedMenu;
      this.selectedMenu = this.menus.findIndex((menu) => menu[2] == to.path);
      if(this.selectedMenu === -1){
      this.selectedMenu = this.oldselectedMenu;
      }
    },
  },
  computed: {},
};
</script>

<style>
.v-list-item-group .v-list-item--active {
  color: #fff !important;
  font-weight: bold;
}

.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  opacity: 0.7;
}

.tile {
  color: #fff;
}
.tile:hover {
  background: #078fc3;
}
.tile:active {
  background: #05ab71;
}
</style>
