export const lg_account_reset_password = {
  account_reset_password_th: {
    title: "แก้ไขข้อมูลส่วนตัว",
    txt_email: "อีเมล",
    txt_old_pass: "รหัสผ่านเดิม",
    txt_new_pass: "รหัสผ่านใหม่",
    txt_confirm_pass: "ยืนยันรหัสผ่านใหม่",
    btn_cancel: "ยกเลิก",
    btn_save: "บันทึก",
    dialog_check_data: "กรุณาตรวจสอบข้อมูล",
    dialog_check_save: "บันทึกข้อมูล",
    dialog_failed_old_password: "รหัสเดิมไม่ถูกต้อง กรุณากรอกใหม่อีกครั้ง",
    dialog_failed_confirm_password: "กรุณาตรวจสอบ Confirm Password",
    messageAlert: "บันทึกข้อมูลสำเร็จ หลังจาก Reset Password ท่านควรทำการ LogOut แล้ว LogIn ใหม่",
  },
  account_reset_password_en: {
    title: "Edit Profile",
    txt_email: "Email",
    txt_old_pass: "Old Password",
    txt_new_pass: "New Password",
    txt_confirm_pass: "Confirm New Password",
    btn_cancel: "Cancel",
    btn_save: "Save",
    dialog_check_data: "Please check your information",
    dialog_check_save: "Save",
    dialog_failed_old_password: "Old Password is incorrect",
    dialog_failed_confirm_password: "Please recheck the Confirm Password",
    messageAlert: "After saving the account information, you must logout and login again",
  },
};
