export const lg_menu = {
  menu_th: {
    menus_admin: [
      ["mdi-database-cog", "ตั้งค่าข้อมูล บริษัท", "/company-config"],
      ["mdi-database-cog", "ตั้งค่าข้อมูล แผนก", "/department-config"],
      ["mdi-database-cog", "ตั้งค่าข้อมูล App ID", "/appid-config"],
      ["mdi-database-cog", "ตั้งค่าข้อมูล API KEY", "/apikey-config"],
      ["mdi-poll", "Vote", "/vote-reports"],
      ["mdi-account", "ข้อมูลส่วนตัว", "/account-detail"],
      ["mdi-account", "กำหนด License", "/licens-detail"],
      ["mdi-account-lock", "เปลี่ยนรหัสผ่านใหม่", "/account-resetpassword"],
      ["mdi-video", "สร้างการประชุม", "/create-meet-room"],
      ["mdi-file-video", "ห้องประชุม", "/listroom"],
      ["mdi-clipboard-list", "ผู้ใช้", "/list-account"],
      ["mdi-record-rec", "การบันทึก", "/meeting-recording"],
    ],
    menus_company_admin: [
      ["mdi-poll", "Vote", "/vote-reports"],
      ["mdi-account", "ข้อมูลส่วนตัว", "/account-detail"],
      ["mdi-account-lock", "เปลี่ยนรหัสผ่านใหม่", "/account-resetpassword"],
      ["mdi-video", "สร้างการประชุม", "/create-meet-room"],
      ["mdi-file-video", "ห้องประชุม", "/listroom"],
      ["mdi-clipboard-list", "ผู้ใช้", "/list-account"],
      ["mdi-clipboard-list", "รายงานการสร้างห้องประชุม", "/listmeeting"],
      ["mdi-record-rec", "การบันทึก", "/meeting-recording"],
    ],
    menus_member: [
      ["mdi-poll", "Vote", "/vote-reports"],
      ["mdi-account", "ข้อมูลส่วนตัว", "/account-detail"],
      ["mdi-account-lock", "เปลี่ยนรหัสผ่านใหม่", "/account-resetpassword"],
      ["mdi-video", "สร้างการประชุม", "/create-meet-room"],
      ["mdi-file-video", "ห้องประชุม", "/listroom"],
      ["mdi-record-rec", "การบันทึก", "/meeting-recording"],
    ],
  },
  menu_en: {
    menus_admin: [
      ["mdi-database-cog", "Company Configurations", "/company-config"],
      ["mdi-database-cog", "Department Configurations", "/department-config"],
      ["mdi-database-cog", "App ID Configurations", "/appid-config"],
      ["mdi-database-cog", "API key Configurations", "/apikey-config"],
      ["mdi-poll", "Vote", "/vote-reports"],
      ["mdi-account", "Personal Information", "/account-detail"],
      ["mdi-account", "License Details", "/licens-detail"],
      ["mdi-account-lock", "Change Password", "/account-resetpassword"],
      ["mdi-video", "Create new meeting room", "/create-meet-room"],
      ["mdi-file-video", "Meeting Rooms", "/listroom"],
      ["mdi-clipboard-list", "Users", "/list-account"],
      ["mdi-record-rec", "Recording", "/meeting-recording"],
    ],
    menus_company_admin: [
      ["mdi-poll", "Vote", "/vote-reports"],
      ["mdi-account", "Personal Information", "/account-detail"],
      ["mdi-account-lock", "Change Password", "/account-resetpassword"],
      ["mdi-video", "Create new meeting room", "/create-meet-room"],
      ["mdi-file-video", "Meeting Rooms", "/listroom"],
      ["mdi-clipboard-list", "Users", "/list-account"],
      ["mdi-clipboard-list", "Meeting Room Creation Report", "/listmeeting"],
      ["mdi-record-rec", "Recording", "/meeting-recording"],
    ],
    menus_member: [
      ["mdi-poll", "Vote", "/vote-reports"],
      ["mdi-account", "Personal Information", "/account-detail"],
      ["mdi-account-lock", "Change Password", "/account-resetpassword"],
      ["mdi-video", "Create new meeting room", "/create-meet-room"],
      ["mdi-file-video", "Meeting Rooms", "/listroom"],
      ["mdi-record-rec", "Recording", "/meeting-recording"],
    ],
  },
};
