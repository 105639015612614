export const lg_appid_list = {
  appid_list_th: {
    title: "Appid",
    appid_search: "ค้นหา Appid",
    appid_add: "เพิ่ม Appid",
    dialog_title: "ยืนยันการลบ",
    dialog_delete:
      "คุณแน่ใจหรือว่าต้องการลบ Appid นี้ ? คุณไม่สามารถกู้คืนได้หลังจาก คลิกยืนยัน",
    dialog_confirm: "ยืนยัน",
    dialog_cancel: "ยกเลิก",
    headers: [
      { text: "รหัส Appid", value: "id" },
      { text: "ชื่อ Appid", value: "app_id_name" },
      { text: "Action", value: "action" },
    ],
  },
  appid_list_en: {
    title: "Appid",
    appid_search: "Search Appid",
    appid_add: "Add Appid",
    dialog_title: "Confirm Delete",
    dialog_delete:
      "Are you sure you want to delete this Appid ? You can't restore it after clicking confirm",
    dialog_confirm: "Confirm",
    dialog_cancel: "Cancel",
    headers: [
      { text: "Appid Id", value: "id" },
      { text: "Appid Name", value: "app_id_name" },
      { text: "Action", value: "action" },
    ],
  },
};

export const lg_appid_add = {
  appid_add_th: {
    title: "เพิ่มข้อมูล การตั้งค่า  App ID",
    appid_name: "ชื่อ App ID",
    btn_save: "บันทึก",
    btn_cancel: "ยกเลิก",
    titleMessageAlert: "กรุณาตรวจสอบข้อมูล",
    messageAlert: "ชื่อ Appid นี้ถูกใช้งานแล้ว กรุณาเปลี่ยนชื่อ Appid ใหม่",
  },
  appid_add_en: {
    title: "Add App ID",
    appid_name: "App ID Name",
    btn_save: "Save",
    btn_cancel: "Cancel",
    titleMessageAlert: "Please check your information",
    messageAlert: "TThis App ID Name is already in used. Please change the App ID Name.",
  },
};

export const lg_appid_edit = {
  appid_edit_th: {
    title: "แก้ไขข้อมูล การตั้งค่า  App ID",
    appid_id: "รหัส App ID",
    appid_name: "ชื่อ App ID",
    btn_save: "บันทึก",
    btn_cancel: "ยกเลิก",
    titleMessageAlert: "กรุณาตรวจสอบข้อมูล",
    messageAlert: "ชื่อ Appid นี้ถูกใช้งานแล้ว กรุณาเปลี่ยนชื่อ App ID ใหม่",
  },
  appid_edit_en: {
    title: "Edit App ID",
    appid_id: "App ID Number",
    appid_name: "App ID Name",
    btn_save: "Save",
    btn_cancel: "Cancel",
    titleMessageAlert: "Please check your information",
    messageAlert: "This Appid name is already used. Please change App ID name",
  },
};
