<template>
      <div class="wrapper">
        <pdf
			v-for="i in 5"
			:key="i"
			src="https://www.dcm.co.th/wp-content/uploads/2021/12/InformationSecurityPolicy.pdf"
			:page="i"
			style="height: 100%"
		></pdf>
    </div>
</template>

<script>
import pdf from 'vue-pdf'

export default {
  components: {
    pdf
  },
  methods: {
        onError(e) {
            console.log(e)
        },
    },
}
</script>

<style scoped>
  .wrapper{
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}
</style>