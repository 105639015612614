<template>
  <v-dialog
    v-model="visible"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card dark>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Vote</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <CreateVoteDialog :roomId="roomId" :userId="userId" :user="user" />

          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-main>
        <v-container>
          <v-simple-table fixed-header>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Question</th>
                  <th class="text-left">Status</th>
                  <th class="text-left">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(vote, index) in votes" :key="`vote-${index}`">
                  <td width="300">{{ vote.question }}</td>
                  <td>{{ vote.status }}</td>

                  <td width="300">
                    <v-btn
                      v-if="!vote.status || vote.status === 'CREATED'"
                      color="primary"
                      small
                      @click="() => send(vote)"
                      >Send</v-btn
                    >
                    <v-btn
                      v-if="vote.status === 'SENT'"
                      color="warning"
                      small
                      @click="() => resend(vote)"
                      class="ml-2"
                      >Resend</v-btn
                    >
                    <v-btn
                      v-if="vote.status === 'SENT'"
                      color="green"
                      small
                      class="ml-2"
                      @click="() => showStatus(vote)"
                      >Status</v-btn
                    >
                    <v-btn
                      v-if="vote.status === 'END'"
                      color="green"
                      small
                      class="ml-2"
                      @click="() => showReportDialog(vote)"
                      >Report</v-btn
                    >
                    <v-btn
                      v-if="
                        vote.status &&
                        vote.status !== 'CREATED' &&
                        vote.status !== 'END'
                      "
                      color="red"
                      small
                      outlined
                      @click="() => end(vote)"
                      class="ml-2"
                      >End</v-btn
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-container>
      </v-main>
    </v-card>

    <vote-status-dialog
      :visible="isStatusDialogVisible"
      :vote="statusDialogData"
      :roomId="roomId"
      :userId="userId"
      @close="closeStatusDialog"
    />
    <vote-report-dialog
      :visible="isReportDialogVisible"
      :vote="reportDialogData"
      :roomId="roomId"
      :userId="userId"
      @close="closeReportDialog"
    />
  </v-dialog>
</template>

<script>
import firebase from "firebase/app";

import CreateVoteDialog from "./CreateVoteDialog.vue";
import VoteStatusDialog from "./VoteStatusDialog.vue";
import VoteReportDialog from "./VoteReportDialog.vue";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    roomId: {
      type: String,
    },
    room: {
      type: Object,
    },
    userId: {
      type: String,
    },
    user: {
      type: String,
    },
  },
  components: {
    CreateVoteDialog,
    VoteStatusDialog,
    VoteReportDialog,
  },
  data: () => ({
    votes: [],
    watchVotes: null,
    isStatusDialogVisible: false,
    statusDialogData: null,
    isReportDialogVisible: false,
    reportDialogData: null,
  }),
  watch: {
    visible(visible) {
      if (visible) {
        this.watchVotes = firebase
          .firestore()
          .collection("votes")
          .where("roomId", "==", this.roomId)
          .onSnapshot(async (snapshots) => {
            const { docs } = snapshots;
            const votes = await Promise.all(
              docs.map(async (doc) => {
                const data = await doc.data();
                return { ...data, id: doc.id };
              })
            );

            this.votes = votes;
          });
      } else {
        if (this.watchVotes) {
          this.watchVotes();
        }
      }
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async resend(vote) {
      const { userId, room } = this;

      await firebase.firestore().collection(`events`).doc(this.roomId).set({
        roomId: this.roomId,
        room,
        type: "SEND_VOTE",
        createdDate: firebase.firestore.FieldValue.serverTimestamp(),
        vote,
        userId,
      });
    },
    async send(vote) {
      const { userId, room } = this;
      await firebase.firestore().collection(`events`).doc(this.roomId).set({
        roomId: this.roomId,
        type: "SEND_VOTE",
        createdDate: firebase.firestore.FieldValue.serverTimestamp(),
        vote,
        userId,
        room,
      });
      firebase.firestore().collection("votes").doc(vote.id).update({
        status: "SENT",
        room,
      });
    },
    showStatus(vote) {
      this.isStatusDialogVisible = true;
      this.statusDialogData = vote;
    },
    closeStatusDialog() {
      this.isStatusDialogVisible = false;
      this.statusDialogData = null;
    },
    showReportDialog(vote) {
      this.isReportDialogVisible = true;
      this.reportDialogData = vote;
    },
    closeReportDialog() {
      this.isReportDialogVisible = false;
      this.reportDialogData = null;
    },
    async getMembers() {
      const snapshot = await firebase.firestore().collection("members").get();
      const { docs } = snapshot;
      const members = await Promise.all(
        docs.map(async (doc) => {
          return await doc.data();
        })
      );
      return members;
    },
    async getReports(vote) {
      const snapshot = await firebase
        .firestore()
        .collection("vote-report")
        .where("questionId", "==", vote.id)
        .get();
      const { docs } = snapshot;
      const members = await Promise.all(
        docs.map(async (doc) => {
          const data = await doc.data();
          return {
            ...data,
            id: doc.id,
            room: this.room,
          };
        })
      );
      return members;
    },
    async end(vote) {
      let members = await this.getMembers();
      members = members.filter((m) => m.userId !== this.userId);
      const reports = await this.getReports(vote);

      // bind answer to members
      const newReports = reports.map((report) => {
        const member = members.find((m) => m.userId === report.userId);
        if (member) {
          return {
            userId: member.userId,
            displayname: member.displayname ?? null,
            answer: report ? report.answer : null,
            answers: report ? report.answers : [],
          };
        }
        return null;
      });

      // remove answer collection
      reports.forEach((report) => {
        firebase.firestore().collection("vote-report").doc(report.id).delete();
      });

      console.log(newReports, "<--- newReports");

      // update status
      firebase.firestore().collection("votes").doc(vote.id).update({
        status: "END",
        reports: newReports,
      });
    },
  },
  beforeDestroy() {
    if (this.watchVotes) {
      this.watchVotes();
    }
  },
};
</script>

<style lang="scss">
.vote-item {
  border: 1px solid red;
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
}
</style>
