<template>
  <v-app>
    <Header v-if="!$store.state.meetSta" :key="$store.state.headerfullname"/>
    <!-- <Header v-if="$store.getters['isLogin']" /> -->
 
     <MenuMainpage v-if="$store.state.navMenuMainpage" />
    <Menu v-if="$store.state.navMenu" />
    
    <Content />
    <Fotter  v-if="$store.state.loadFooter" />
  </v-app>
</template>

<script>
import HeaderResponsive from "@/components/core/HeaderResponsive.vue";
import HeaderisLogin from "@/components/core/HeaderisLogin.vue";
import Header from "@/components/core/Header.vue";
import Menu from "./components/core/Menu.vue";
import MenuMainpage from "./components/core/MenuMainpage.vue";
import Content from "./components/core/Content.vue";
import Fotter from "./components/core/Fotter.vue";
import { feature } from "@/services/constants";

export default {
  name: "app",
  components: {
    HeaderResponsive,
    HeaderisLogin,
    Header,
    MenuMainpage,
    Menu,
    Content,
    Fotter,
  },
  beforeCreate() {
    // localStorage.setItem(feature.LANGUAGE, "en");
     if (localStorage.getItem(feature.LANGUAGE) === "en") {
      localStorage.setItem(feature.LANGUAGE, "gb");
    }
    if (!localStorage.getItem(feature.LANGUAGE)) {
      localStorage.setItem(feature.LANGUAGE, "gb");
    }


  },
  mounted() {
    this.$store.dispatch({ type: "restoreLogin" });
  },
  data() {
    return {};
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@200;400&display=swap");


.v-application {
    font-family: "Kanit", serif;
}
#app a {
  font-weight: bold;
  color: #078fc3;
}
/* #zmmtg-root {
  display: none;
  position: none;
} */
input[type=text]:focus {
    outline: none !important;
    outline-offset: none !important;
}

/* body{
  overflow: auto;
} */
/* html, body {
  overflow: auto !important;
} */
input[type=text]:focus {
    outline: 0px solid rgba(0,0,0,0) !important;
    outline-offset: 0px !important;
}
/* .v-main {
   background-color:lightblue;
} */
/* .theme--light.v-application {
  background-image: linear-gradient(to right top, #cbe9f8, #dcedfc, #ebf2fd, #f7f8fe, #ffffff) !important;
} */
</style>
