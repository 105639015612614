var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"grey lighten-5"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-card',{style:({ padding: '0.5rem 2rem 2rem' }),attrs:{"outlined":"","tile":""}},[_c('br'),_c('h3',{staticClass:"text-center"},[_c('v-icon',{attrs:{"color":"info"}},[_vm._v("mdi-shield-account")]),_vm._v(" "+_vm._s(this.lg_create_meetroom.title)+" ")],1),_c('br'),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"home-page"},[_c('div',{staticClass:"buttons mb-5"},[_c('v-tabs',{attrs:{"fixed-tabs":"","background-color":"#205295","dark":""}},[_c('v-tabs-slider',{attrs:{"color":"#FFE9B1"}}),_c('v-tab',{attrs:{"color":_vm.createMode === 'now' ? 'primary' : 'default'},on:{"click":function($event){_vm.createMode = 'now'}}},[_vm._v(" "+_vm._s(this.lg_create_meetroom.create_room_now)+" ")])],1)],1),_c('div',{staticClass:"create-room-form"},[_c('v-text-field',{style:({ width: '100%' }),attrs:{"placeholder":_vm.lg_create_meetroom.meet_topic,"outlined":"","autocomplete":"off"},model:{value:(_vm.topic),callback:function ($$v) {_vm.topic=$$v},expression:"topic"}}),(_vm.createMode === 'schedule')?_c('div',{staticStyle:{"width":"100%"}},[_c('v-menu',{ref:"dateMenu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"white-input",staticStyle:{"width":"100%"},attrs:{"value":_vm.formatDate,"clearable":"","readonly":"","outlined":"","placeholder":_vm.lg_create_meetroom.meet_date_start,"required":""}},'v-text-field',attrs,false),on))]}}],null,false,592604139),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","locale":"th-TH"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dateMenu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dateMenu.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1),_c('v-menu',{ref:"timeMenu",attrs:{"close-on-content-click":false,"return-value":_vm.time,"transition":"scale-transition","offset-y":"","min-width":"auto","required":""},on:{"update:returnValue":function($event){_vm.time=$event},"update:return-value":function($event){_vm.time=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"white-input",staticStyle:{"width":"100%"},attrs:{"clearable":"","readonly":"","outlined":"","placeholder":_vm.lg_create_meetroom.meet_time_start},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}},'v-text-field',attrs,false),on))]}}],null,false,3483244742),model:{value:(_vm.timeMenu),callback:function ($$v) {_vm.timeMenu=$$v},expression:"timeMenu"}},[_c('v-time-picker',{attrs:{"scrollable":"","locale":"th-TH","format":"24hr"},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dateMenu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.timeMenu.save(_vm.time)}}},[_vm._v(" OK ")])],1)],1)],1):_vm._e(),_c('v-text-field',{staticClass:"white-input",staticStyle:{"width":"100%"},attrs:{"placeholder":_vm.lg_create_meetroom.meet_password,"outlined":"","autocomplete":"new-password","type":"password","loading":_vm.loading},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-row',{staticClass:"justify-center mb-5  mt-5 "},[_c('v-btn',{style:({ backgroundColor: '#205295', color: '#ffffff' }),attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(this.lg_create_meetroom.meet_create)+" ")])],1)],1)]),(_vm.resultVisible)?_c('LoadingDialog'):_vm._e(),_c('ZoomCreateRoomSuccessDialog',{attrs:{"visible":_vm.resultVisible,"result":_vm.result},on:{"close":function () {
                _vm.resultVisible = false;
                _vm.$refs.listroom.loadListRoomMeetingData();
              }}})],1)])],1),_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('v-card',{staticClass:"ma-1 pa-2",attrs:{"outlined":"","tile":""}},[_c('ListRoomZoom',{ref:"listroom"})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":_vm.maxwidthdialog},model:{value:(_vm.privacyDlg),callback:function ($$v) {_vm.privacyDlg=$$v},expression:"privacyDlg"}},[_c('v-card',{style:({ padding: '1rem' })},[_c('v-img',{attrs:{"height":"200","contain":"","src":require("../assets/privacypolicy4.png")}}),_c('v-card-title',{staticClass:"justify-center",attrs:{"primary-title":""}},[_vm._v(" "+_vm._s(this.lg_policy.privacy_consent))]),_c('v-divider',{staticClass:"mx-4"}),_c('v-card-text',[_c('div',{staticClass:"mt-3"},[_vm._v(" "+_vm._s(this.lg_policy.text1)+" ")]),_c('div',{staticClass:"mt-3"},[_vm._v(" "+_vm._s(this.lg_policy.text2)+" ")])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"small":_vm.buttonsmall,"outlined":"","color":"success","text":"","col":"12","lg":"12","md":"12","sm":"12"},on:{"click":function($event){return _vm.onclickPrivacyDlg()}}},[_vm._v(" "+_vm._s(this.lg_policy.accept)+" ")]),_c('v-btn',{attrs:{"small":_vm.buttonsmall,"outlined":"","color":"error","text":"","col":"12","lg":"12","md":"12","sm":"12"},on:{"click":function($event){return _vm.onclickNonPrivacyDlg()}}},[_vm._v(" "+_vm._s(this.lg_policy.notaccept)+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }