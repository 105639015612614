export const lg_company_list = {
  company_list_th: {
    title: "บริษัท",
    company_search: "ค้นหาบริษัท",
    company_add: "เพิ่มบริษัท",
    dialog_title: "ยืนยันการลบ",
    dialog_delete:
      "คุณแน่ใจหรือว่าต้องการลบบริษัทนี้ ? คุณไม่สามารถกู้คืนได้หลังจาก คลิกยืนยัน",
    dialog_confirm: "ยืนยัน",
    dialog_cancel: "ยกเลิก",
    headers: [
      { text: "รหัสบริษัท", value: "id" },
      { text: "ชื่อบริษัท", value: "company_name" },
      { text: "หมายเลขสมาชิก", value: "company_code" },
      { text: "Action", value: "action" },
    ],
  },
  company_list_en: {
    title: "Company",
    company_search: "Search Company",
    company_add: "Add Company",
    dialog_title: "Confirm Delete",
    dialog_delete:
      "Are you sure you want to delete this company ? You can't restore it after clicking confirm",
    dialog_confirm: "Confirm",
    dialog_cancel: "Cancel",
    headers: [
      { text: "Company Id", value: "id" },
      { text: "Company Name", value: "company_name" },
      { text: "Membership Number", value: "company_code" },
      { text: "Action", value: "action" },
    ],
  },
};

export const lg_company_add = {
  company_add_th: {
    title: "เพิ่มข้อมูล การตั้งค่า บริษัท",
    company_name: "ชื่อบริษัท",
    company_code: "หมายเลขสมาชิก",
    company_contact_email:"อีเมลติดต่อ",
    company_contact_name:"ชื่อผู้ติดต่อ",
    company_start_date:"วันที่เรื่มใช้งาน (วัน-เดือน-ปี (ค.ศ.))",
    company_contact_address:"ที่อยู่ติดต่อ",
    company_phone:"เบอร์โทรศัพท์",
    company_extension_number:"เบอร์ต่อ",
    btn_save: "บันทึก",
    btn_cancel: "ยกเลิก",
    titleMessageAlert: "กรุณาตรวจสอบข้อมูล",
    messageAlert: "ชื่อบริษัทนี้ถูกใช้งานแล้ว กรุณาเปลี่ยนชื่อบริษัทใหม่",
    messageAlertCodeDuplicate:
      "หมายเลขสมาชิกนี้ถูกใช้งานแล้ว กรุณาเปลี่ยนหมายเลขสมาชิกใหม่",
  },
  company_add_en: {
    title: "Add Company",
    company_name: "Company Name",
    company_code: "Membership Number",
    company_contact_email:"Company Email contact",
    company_contact_name:"Company Contact Name",
    company_start_date:"Company Start Date (DD-MM-YYYY)",
    company_contact_address:"Company Contact Address",
    company_phone:"Company Phone",
    company_extension_number:"Company Extension Number",
    btn_save: "Save",
    btn_cancel: "Cancel",
    titleMessageAlert: "Please check data",
    messageAlert:
      "This company name is already used. Please change company name",
    messageAlertCodeDuplicate:
      "This Membership Number is already used. Please change Membership Number",
  },
};

export const lg_company_edit = {
  company_edit_th: {
    title: "แก้ไขข้อมูล การตั้งค่า บริษัท",
    company_id: "รหัสบริษัท",
    company_name: "ชื่อบริษัท",
    company_code: "หมายเลขสมาชิก",
    company_contact_email:"อีเมลติดต่อ",
    company_contact_name:"ชื่อผู้ติดต่อ",
    company_start_date:"วันที่เรื่มใช้งาน (วัน-เดือน-ปี (ค.ศ.))",
    company_contact_address:"ที่อยู่ติดต่อ",
    company_phone:"เบอร์โทรศัพท์",
    company_extension_number:"เบอร์ต่อ",
    btn_save: "บันทึก",
    btn_cancel: "ยกเลิก",
    titleMessageAlert: "กรุณาตรวจสอบข้อมูล",
    messageAlert: "ชื่อบริษัทนี้ถูกใช้งานแล้ว กรุณาเปลี่ยนชื่อบริษัทใหม่",
    messageAlertCodeDuplicate:
      "หมายเลขสมาชิกนี้ถูกใช้งานแล้ว กรุณาเปลี่ยนหมายเลขสมาชิกใหม่",
  },
  company_edit_en: {
    title: "Edit Company",
    company_id: "Company ID",
    company_name: "Company Name",
    company_code: "Membership Number",
    company_contact_email:"Company Email contact",
    company_contact_name:"Company Contact Name",
    company_start_date:"Company Start Date (DD-MM-YYYY)",
    company_contact_address:"Company Contact Address",
    company_phone:"Company Phone",
    company_extension_number:"Company Extension Number",
    btn_save: "Save",
    btn_cancel: "Cancel",
    titleMessageAlert: "Please check data",
    messageAlert:
      "This company name is already used. Please change company name",
    messageAlertCodeDuplicate:
      "This Membership Number is already used. Please change Membership Number",
  },
};
