export const lg_account_edit_admin = {
  account_edit_admin_th: {
    title: "แก้ไขข้อมูลส่วนตัว",
    license_type: "ประเภท License",
    cb_free: "ฟรี",
    cb_premium: "พรีเมี่ยม",
    sl_company: "เลือกบริษัท",
    sl_department: "เลือกแผนก",
    txt_name: "ชื่อ-นามสกุล",
    email: "อีเมล",
    dtp_birthday: "วัน-เดือน-ปี (ค.ศ.) เกิด",
    txt_phone: "หมายเลขโทรศัพท์มือถือ",
    txt_permission: "การอนุญาต",
    btn_cancel: "ยกเลิก",
    btn_save: "บันทึก",
    dialog_select_company: "กรุณาเลือกบริษัท",
    dialog_check_data: "กรุณาตรวจสอบข้อมูล",
    dialog_check_save: "บันทึกข้อมูล",
    dialog_check_save_success: "บันทึกข้อมูลสำเร็จ",
  },
  account_edit_admin_en: {
    title: "Edit Personal Information",
    license_type: "License type",
    cb_free: "Free",
    cb_premium: "Premium",
    sl_company: "Select company",
    sl_department: "Select department",
    txt_name: "Name",
    email: "Email",
    dtp_birthday: "Date (YYYY-MM-DD)",
    txt_phone: "Phone number",
    txt_permission: "Permission",
    btn_cancel: "Cancel",
    btn_save: "Save",
    dialog_select_company: "Please select company",
    dialog_check_data: "Please check your information",
    dialog_check_save: "Save",
    dialog_check_save_success: "Save successful",
  },
};
