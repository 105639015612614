<template>
  <v-dialog v-model="visible" width="500" persistent>
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        {{ lg_crrd.title }}
      </v-card-title>

      <v-card-text v-if="result" class="py-5">
        <v-text-field
          :value="result.roomName"
          readonly
          :label="lg_crrd.room_name"
        />
        <!-- <v-text-field
          :value="result.modLink"
          readonly
          label="Link สำหรับ Moderator"
          append-icon="mdi-content-copy"
          @click:append="copyModLink"
        /> -->
        <v-text-field
          :value="result.userLink"
          readonly
          :label="lg_crrd.room_link"
          append-icon="mdi-content-copy"
          @click:append="copyLink"
        />
        <!-- <v-text-field
          :value="result.iosLink"
          readonly
          label="Link IOS"
          append-icon="mdi-content-copy"
          @click:append="copyIOSLink"
        /> -->
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <!-- :href="result.modLink" -->
        <v-btn color="primary" @click="onClickJoinMeetion()" outlined text>{{
          lg_crrd.btn_join
        }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" outlined text @click="onClose">{{
          lg_crrd.btn_close
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { format } from "date-fns";
import api from "../../services/api";
import { server } from "../../services/constants";

import { feature } from "../../services/constants";
import { lg_crrd } from "../../views/language/lg_create_room_result_dialog";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    result: {
      type: Object,
    },
  },
  beforeCreate() {
    localStorage.getItem(feature.LANGUAGE) === "th"
      ? (this.lg_crrd = lg_crrd.crrd_th)
      : (this.lg_crrd = lg_crrd.crrd_en);
  },
  data() {
    return {
      resultJson: "",
    };
  },
  computed: {
    modLink() {
      // const { result } = this;
      // if (!result) return "";
      return "";
    },
  },
  methods: {
    async onClickJoinMeetion() {
      // console.log("token111 : ", this.result);
      const { id } = this.result;
      // console.log("token111 : ", id);

      this.resultJson = await api.getByMeetNameOnly(id);
      // console.log("token111 : ", this.resultJson);

      if (
        localStorage.getItem(server.USER_ID) ==
          this.resultJson.data.account_id ||
        localStorage.getItem(server.ROLE) == "Admin" ||
        localStorage.getItem(server.ROLE) == "Company Admin"
      ) {
        localStorage.setItem(server.MODERATOR_STA, "true");
      } else {
        localStorage.setItem(server.MODERATOR_STA, "false");
      }
      this.$router.push(
        `/meet/${this.resultJson.data.meet_appid}/${this.resultJson.data.meet_name}`
      );
    },
    onClose() {
      this.$emit("close");
    },
    copy(text) {
      navigator.clipboard.writeText(text);
    },

    copyModLink() {
      const { startDate, topic, modLink, password } = this.result;
      var shareText =
        "Dmeets is inviting you to a scheduled Dmeets meeting.\n" +
        `Topic: ${topic ?? "-"}\n` +
        `${
          startDate
            ? `Time: ${
                format(new Date(startDate), "MMM dd, yyyy HH:mm") + " Bangkok"
              }\n`
            : ""
        }` +
        `${password ? `Password: ${password}\n` : ""}` +
        "Join Dmeets Meeting\n" +
        modLink;

      this.copy(shareText);
    },
    copyLink() {
      const { id, startDate, topic, userLink, password } = this.result;
      var shareText =
        "Dmeets is inviting you to a scheduled Dmeets meeting.\n" +
        `Topic: ${topic ?? "-"}\n` +
        `${
          startDate
            ? `Time: ${
                format(new Date(startDate), "MMM dd, yyyy HH:mm") + " Bangkok"
              }\n`
            : ""
        }` +
        "Join Dmeets Meeting\n" +
        userLink +
        "\n" +
        "Meeting Number : \n" +
        id +
        "\n" +
        `${password ? `Password:\n ${password}\n` : ""}`;

      this.copy(shareText);
    },

    copyIOSLink() {
      const { startDate, topic, iosLink, password } = this.result;

      var shareText =
        "Dmeets is inviting you to a scheduled Dmeets meeting.\n" +
        `Topic: ${topic ?? "-"}\n` +
        `${
          startDate
            ? `Time: ${
                format(new Date(startDate), "MMM dd, yyyy HH:mm") + " Bangkok"
              }\n`
            : ""
        }` +
        `${password ? `Password: ${password}\n` : ""}` +
        "Join Dmeets Meeting\n" +
        iosLink;

      this.copy(shareText);
    },
  },
};
</script>
