export const lg_forgotpassword = {
    lg_forgotpassword_th: {
        title: "รีเซ็ตรหัสผ่านของคุณ",
        label:"อีเมล",
        placeholder:"exam@email.com",
        backbutton:"กลับ",
        send:"รีเซ็ตรหัสผ่าน",
    },
    lg_forgotpassword_en: {
        title: "Reset Your Password",
        label:"Email",
        placeholder:"exam@email.com",
        backbutton:"Back",
        send:"Reset Password",
    },
  };
