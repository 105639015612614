<template>
  
  <v-app  >
  <!-- <v-container v-resize="onResize" class="mainpage-container"> -->
    <div class="homemain">
      <v-row class="mt-16 mb-16 ml-4 mr-4" justify="center" v-bind:style="{ align:'center'}">
      <v-col v-bind:style="{ align:'center'}" justify="center" cols="12" xl="6" lg="6" md="6" sm="8" xs="12">
        <div align-center justify-center>
          <h1 class="display-6 fw-bold lh-1 mb-3" v-bind:style="{ color: '#ffffff',fontSize:'38px'}">
            {{ this.lg_mainpage.title }}
          </h1>

          <h2  v-bind:style="{ color: '#ffffff',fontWeight:'300 !important',align:'left' }">
            {{ this.lg_mainpage.subtitle1 }}
          </h2>
<br/>
    <v-row v-bind:style="{ alignContent:'center',align:'left' }" class="ma-0 d-flex justify-center align-center justify-md-start align-md-start"  >
          <v-btn
                class="mb-1 mt-2 white--text"
                height="45"
                @click="onClickMenu('/register-account')"
                v-bind:style="{ minWidth: 'auto', backgroundColor: '#2C74B3',marginRight: '10px' }"
              >
                <h2>{{ this.lg_header_feature.get_start }}</h2>
              </v-btn>

              <v-btn
                class="mt-2"
                height="45"
                @click="onClickMenu('/feature')"
                v-bind:style="{ minWidth: 'auto', backgroundColor: '#000000',color:'#2C74B3',borderStyle:'solid',borderColor:'#2C74B3' }"
              >
                <h2>{{ this.lg_header_feature.explore_features }}</h2>
              </v-btn>
            </v-row>
        </div>
      </v-col>
    </v-row>
    </div>

    <div class="homefeature">
      <v-row class="mt-8 mb-8 ml-4 mr-4 flex-wrap-reverse" justify="center" >
      <v-col align="center" justify="center"  cols="12" xl="4" lg="4" md="5" sm="5" xs="12" v-bind:style="{alignContent: 'center',align:'center' }">
          <v-img 
            :max-height="widthheightimage"
          :max-width="widthheightimage"
            align-center 
            justify-center
             src="../assets/home_feature.jpg" 
             contain
             v-bind:style="{backgroundPosition: 'center',backgroundRepeat: 'no-repeat',backgroundSize: 'cover' }" />
      </v-col>
      <v-col justify="center" cols="12" xl="4" lg="4" md="7" sm="7" xs="12" v-bind:style="{align:'center',alignContent: 'center' }">
       <v-row justify="center" v-bind:style="{align:'center' }">
        <h1 class="display-6 fw-bold lh-1" v-bind:style="{ color: '#000000',fontSize:'30px'}">
            {{ this.lg_mainpage.titlefeature }}
          </h1>

          <v-timeline 
          dense
          clipped>
    <v-timeline-item
    fill-dot
      color="cyan"
      small
    >
        <h1 v-bind:style="{align:'left' }">
          {{ this.lg_mainpage.high_quality_video }}
        </h1>
        <h2 v-bind:style="{fontWeight:'400 !important',align:'left' }">
          {{ this.lg_mainpage.high_quality_video_details }}
        </h2>
    </v-timeline-item>

    <v-timeline-item
    fill-dot
      color="cyan"
      small
    >
        <h1 v-bind:style="{align:'left' }">
          {{ this.lg_mainpage.intuitive_interface }}
        </h1>
          <h2 v-bind:style="{fontWeight:'400 !important',align:'left' }">
          {{ this.lg_mainpage.intuitive_interface_details }}
        </h2>
    </v-timeline-item>

    <v-timeline-item
    fill-dot
      color="cyan"
      small
    >
        <h1 v-bind:style="{align:'left' }">
          {{ this.lg_mainpage.seamless_collaboration }}
        </h1>
          <h2 v-bind:style="{fontWeight:'400 !important',align:'left' }">
          {{ this.lg_mainpage.seamless_collaboration_details }}
        </h2>
    </v-timeline-item>
  </v-timeline>
     </v-row>
      </v-col>
    </v-row>
    </div>
    <div class="homepricing">
      <v-row justify="center" class="mt-8 mb-8 ml-4 mr-4 flex-wrap-reverse homepricing_paragraph">
      <v-col align="center" justify="center"  cols="12" xl="4" lg="4" md="5" sm="5" xs="12" v-bind:style="{alignContent: 'center',align:'center' }">
          <v-img 
            :max-height="widthheightimage"
          :max-width="widthheightimage"
            align-center 
            justify-center
             src="../assets/home_pricing.jpg" 
             contain
             class="pricingimg"
             v-bind:style="{backgroundPosition: 'center',backgroundRepeat: 'no-repeat',backgroundSize: 'cover' }" />
      </v-col>
      <v-col justify="center" cols="12" xl="4" lg="4" md="7" sm="7" xs="12" v-bind:style="{align:'center',alignContent: 'center' }">
       <v-row justify="center" v-bind:style="{align:'center' }">
        <h1 class="display-6 fw-bold lh-1" v-bind:style="{ color: '#000000',fontSize:'38px'}">
            {{ this.lg_mainpage.pricing }}
          </h1>

          <h2 v-bind:style="{ color: '#000000',fontWeight:'300 !important',align:'left' }">
            {{ this.lg_mainpage.pricing_details }}
          </h2>
    <v-row v-bind:style="{ alignContent:'center'}" class="ma-0 d-flex justify-center align-center justify-md-start align-md-start" >
          <!-- <v-btn
                class="mb-1 white--text"
                height="45"
                @click="onClickMenu('/register-account')"
                v-bind:style="{ minWidth: 'auto', backgroundColor: '#2C74B3',marginRight: '10px' }"
              >
                <h2>{{ this.lg_header_feature.get_start }}</h2>
              </v-btn> -->

              <v-btn
                class="mb-3"
                height="45"
                @click="onClickMenu('/feature')"
                v-bind:style="{ minWidth: 'auto', backgroundColor: '#000000',color:'#2C74B3',borderStyle:'solid',borderColor:'#2C74B3' }"
              >
                <h2>{{ this.lg_header_feature.get_start }}</h2>
              </v-btn>
            </v-row>

     </v-row>
      </v-col>
    </v-row>
    </div>
    <div class="homegettotouch">
      <v-row justify="center" class="mt-8 mb-8 ml-4 mr-4 homepricing_paragraph">
      <v-col justify="center"  cols="12" xl="4" lg="4" md="7" sm="7" xs="12" v-bind:style="{alignContent: 'center',align:'center' }">
        <v-row justify="center" v-bind:style="{align:'center' }">
        <h1 class="display-6 fw-bold lh-1" v-bind:style="{ color: '#ffffff',fontSize:'38px'}">
            {{ this.lg_mainpage.get_in_touch }}
          </h1>

          <h2 v-bind:style="{ color: '#ffffff',fontWeight:'300 !important',align:'left' }">
            {{ this.lg_mainpage.get_in_touch_details1 }}
            <span v-bind:style="{ color: '#2C74B3',fontWeight:'400 !important',align:'left',textDecoration: 'underline' }">admin@dcm.co.th</span>
            <span> {{ this.lg_mainpage.get_in_touch_details2}} </span>
            <span v-bind:style="{ color: '#ffffff',fontWeight:'400 !important',align:'left',textDecoration: 'underline',cursor: 'pointer' }" @click="onClickMenu('/contact')"> {{ this.lg_mainpage.contact_us}}</span>
            <span> {{ this.lg_mainpage.get_in_touch_details3}} </span>
          </h2>

     </v-row>
      </v-col>
      <v-col align="center" justify="center" cols="12" xl="4" lg="4" md="5" sm="5" xs="12" v-bind:style="{align:'center' }">
        <v-img 
            :max-height="widthheightimage"
          :max-width="widthheightimage"
            align-center 
            justify-center
             src="../assets/home_gettotouch.jpg" 
             contain
             class="pricingimg"
             v-bind:style="{backgroundPosition: 'center',backgroundRepeat: 'no-repeat',backgroundSize: 'cover'}" />
     
      </v-col>
    </v-row>
    </div>
    
    <!-- Privacy Dialog -->
    <v-dialog v-if="showpop" v-model="privacyDlg" :max-width="maxwidthdialog">
      <v-card v-bind:style="{ padding: '1rem' }">
        <v-img height="200" contain src="../assets/privacypolicy4.png"></v-img>

        <v-card-title primary-title class="justify-center">
          {{ this.lg_policy.privacy_consent }}</v-card-title
        >

        <v-divider class="mx-4"></v-divider>

        <v-card-text>
          <div class="mt-3">
            {{ this.lg_policy.text1 }}
          </div>
          <div class="mt-3">
            {{ this.lg_policy.text2 }}
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :small="buttonsmall"
            outlined
            color="success"
            text
            @click="onclickPrivacyDlg()"
            col="12"
            lg="12"
            md="12"
            sm="12"
          >
            {{ this.lg_policy.accept }}
          </v-btn>

          <v-btn
            :small="buttonsmall"
            outlined
            color="error"
            text
            @click="onclickNonPrivacyDlg()"
            col="12"
            lg="12"
            md="12"
            sm="12"
          >
            {{ this.lg_policy.notaccept }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Privacy Dialog -->
  <!-- </v-container> -->
</v-app>
</template>

<script>
import api from "@/services/api";
import { server } from "../services/constants";
import { lg_mainpage } from "./language/lg_header_mainpage.js";
import { lg_policy } from "./language/lg_policy";
import { feature } from "../services/constants.js";
import { lg_header_feature } from "./language/lg_header_feature.js";




export default {
  name: "main-page",
  data: () => ({
    showpop: false,
    dateNow: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .replace("T", " ")
      .substr(0, 16),
    margincardpriceplans: "mx-auto ml-0 mt-0 mr-0 mb-0",
    width: 420,
    width_emp: 600,
    text_fontsize: 30,
    privacy_sta: "false",
    privacyDlg: false
  }),
  async mounted() {
    this.onResize();
    this.$forceUpdate();
    // this.$router.go(); // Refreshes page
    const userId = localStorage.getItem(server.USER_ID);
    if (userId && api.isLoggedIn()) {
      const response = await api.checkIfUserAgreed(userId);
      if (!response || !response.data || !response.data.id) {
        this.privacyDlg = true;
      } else {
        // this.$router.push("/create-meet-room");
      }
    } else {
      try {
        if (this.$store.state.checklinkmeeting === true) {
          throw new Error(this.lg_mainpage.contentmeeterror);
        }

        if (this.$store.state.checkidmeeting === true) {
          this.$swal.fire(
            this.lg_mainpage.titlemeeterror,
            this.lg_mainpage.contentmeetiderror,
            "error"
          );
        }
      } catch (error) {
        if (error.response?.status === 404) {
          this.$swal.fire(
            this.lg_mainpage.titlemeeterror,
            this.lg_mainpage.contentmeetiderror,
            "error"
          );
        } else {
          this.$swal.fire(
            this.lg_mainpage.titlemeeterror,
            error?.message,
            "error"
          );
        }
      }
      this.privacyDlg = true;
    }
    const paragraphs = document.querySelectorAll(".homepricing_paragraph");
document.addEventListener("scroll", function(){
  paragraphs.forEach((paragraph)=>{
    if(isInView(paragraph)){
      paragraph.classList.add("homepricing_paragraph--visible")
    }
  })
})

function isInView(element){
const rect = element.getBoundingClientRect();
return(
  rect.bottom > 0 &&
  rect.top < (window.innerHeight -200  || document.documentElement.clientHeight - 200 )
)
}
  },
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  async beforeCreate() {
    this.$store.state.page_reload = false;
    // alert(localStorage.getItem("language"))
    // console.log("language login : " + localStorage.getItem(feature.LANGUAGE));

    localStorage.getItem(feature.LANGUAGE) === "th"
      ? (this.lg_mainpage = lg_mainpage.lg_mainpage_th)
      : (this.lg_mainpage = lg_mainpage.lg_mainpage_en);

    localStorage.getItem(feature.LANGUAGE) === "th"
      ? (this.lg_policy = lg_policy.policy_th)
      : (this.lg_policy = lg_policy.policy_en);

    localStorage.getItem(feature.LANGUAGE) === "th"
      ? (this.lg_header_feature = lg_header_feature.lg_header_feature_th)
      : (this.lg_header_feature = lg_header_feature.lg_header_feature_en);

    // console.log("lg_login: " + JSON.stringify(this.lg_login));
  },
  created() {
    this.setpopupdialog("");
    this.onResize();
    this.$store.state.meetSta = false;
  },

  async beforeUpdate() {
    this.onResize();
  },

  computed: {
    widthheightimage(){
 switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "300";
        case "sm":
          return "400";
        case "md":
          return "400";
        case "lg":
          return "400";
        case "xl":
          return "400";
      }
      },
    maxwidthdialog() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100%";
        case "sm":
          return "70%";
        case "md":
          return "50%";
        case "lg":
          return "40%";
        case "xl":
          return "30%";
      }
    },
    buttonsmall() {
      if (this.$vuetify.breakpoint.name === "xs") {
        return true;
      }
    },
  },
  methods: {
    async onclickPrivacyDlg() {
      if (api.isLoggedIn()) {
        const userId = localStorage.getItem(server.USER_ID);
        const username = localStorage.getItem(server.FULLNAME);
        if (userId) {
          const body = {
            account_id: userId,
            account_name: username,
            device_platform: "web",
          };
          try {
            const response = await api.createPDPA(body);
            if (response && response.data && response.data.id) {
              this.privacyDlg = false;
            }
          } catch (e) {
            console.log(e.message);
          }
        }
      } else {
        this.privacyDlg = false;
      }
      this.setpopupdialog("clicked");
    },
    onclickNonPrivacyDlg() {
      // this.$store.dispatch("doLogout");
      this.privacyDlg = false;
      this.setpopupdialog("clicked");
    },
    async onResize() {
      if (this.$vuetify.breakpoint.xsOnly) {
        this.margincardpriceplans = "mx-auto ml-5 mt-5 mr-5 mb-5";
      } else if (this.$vuetify.breakpoint.smOnly) {
        this.margincardpriceplans = "mx-auto ml-5 mt-5 mr-5 mb-5";
      } else {
        this.margincardpriceplans = "mx-auto ml-0 mt-0 mr-0 mb-0";
      }
    },
    async setpopupdialog(data) {
      // 1 = 1day  / +7 is +time zone
      const day = 1 * 24;
      const setdateexpired = new Date(
        new Date().setHours(new Date().getHours() + (day + 7))
      )
        .toISOString()
        .replace("T", " ")
        .substr(0, 16);
      // const setdateexpired = "2022-04-01 14:51";

      if (data === "clicked") {
        this.showpop = false;
        this.$cookies.set(
          "sessionpopup",
          { name: "checkclickpopup", expired: setdateexpired },
          setdateexpired
        );
      } else {
        if (
          this.$cookies.get("sessionpopup") &&
          new Date(this.$cookies.get("sessionpopup").expired) <=
            new Date(this.dateNow)
        ) {
          this.showpop = true;
          this.$cookies.remove("sessionpopup");
        } else if (
          this.$cookies.get("sessionpopup") &&
          new Date(this.$cookies.get("sessionpopup").expired) >=
            new Date(this.dateNow)
        ) {
          this.showpop = false;
        } else {
          this.showpop = true;
        }
      }
    },
    onClickMenu(link) {
      // console.log(link);
      this.$router.push(link).catch((err) => {});
    },
  },
};
</script>

<style scoped>
/*
@media only screen and (max-width: 600px) {
  .v-card{
    margin-top:40px;
     margin-bottom:40px;
      margin-right:40px;
       margin-left:40px;
}
}
*/
@media only screen and (max-width: 375px)  {
  .homemain{
    margin-top: -50px;
    /* height:100vh !important; */
  }
  /* .homefeature{
    height:150vh !important;
  } */
  /* .homepricing{
    height:80vh !important;
  } */
  /* .homegettotouch{
    height:70vh !important;
  } */
}
  @media only screen and (min-width: 375px) and (max-width: 599px)  {
  /* .mainpage-container {
    margin-top:-150px;
  } */
  .v-application {
    margin-top:-50px;
    /* height: 110% !important; */
  }
  /* .homemain{
    height:90vh !important;
  } */
  /* .homefeature{
    height:130vh !important;
  } */
  /* .homepricing{
    height:80vh !important;
  } */
  /* .homegettotouch{
    height:70vh !important;
  } */
}

/* @media (max-width: 959px) {
  .homefeature{
    height: 40% !important;
  }
} */

@media only screen and (min-width: 600px) and (max-width: 960px)  {
  /* .homefeature{
    height:80vh !important;
  } */
}

@media (min-width: 960px) {
  .mainpage-container {
    margin-top: 3rem
  }
}

.v-main {
    overflow:auto;
    /* max-width: 100%;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to right top, #cbe9f8, #dcedfc, #ebf2fd, #f7f8fe, #ffffff) !important; */
}
/* .mainpage-container {
    margin-top: -20px;
  } */

.homemain{
    background: rgba(0,0,0,0.8) url("../assets/home.jpg");
    /* height: 50vh; */
    /* min-height:50vh; */
    width: 100%;
    background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: darken;
  align-content: center;
  /* filter: brightness(0.3); */
}
.homefeature{
  background-image: linear-gradient(to right top, #cbe9f8, #dcedfc, #ebf2fd, #f7f8fe, #ffffff) !important;
    /* height: 60vh; */
    width: 100%;
    align-content: center;
}
.homepricing{
  background-color: #afcbf8 !important;
    /* height: 50vh; */
    width: 100%;
    align-content: center;
}
.homegettotouch{
  background-color: #102c39 !important;
    /* height: 50vh; */
    width: 100%;
    align-content: center;
}

.homepricing_paragraph{
  transform: translateY(20px);
  opacity: 0;
  transition: 2s ease;
}
.homepricing_paragraph--visible{
  transform: translateY(0);
  opacity: 1;
}
/* .pricingimg{
  background-position: 'center';
  background-repeat: 'no-repeat';
  background-size: 'cover';
}
.fadein1s{
  animation: '1s fadeIn' 
} */
</style>
