export const lg_crrd = {
  crrd_th: {
    title: "สร้างห้องสำเร็จ",
    room_id: "รหัสห้อง",
    room_name: "ชื่อห้อง",
    room_link: "Link สำหรับผู้ร่วมประชุม",
    btn_join: "เข้าร่วมการประชุม",
    btn_close: "ปิด",
    room_password: "รหัสผ่าน",
  },
  crrd_en: {
    title: "Room creation success",
    room_id: "Room ID",
    room_name: "Room Name",
    room_link: "Meeting room link",
    btn_join: "Join",
    btn_close: "Close",
    room_password: "Password",
  },
};
