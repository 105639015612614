import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getAllDepartment = () => {
  return httpClient.get(server.DEPARTMENT);
};

export const getAllDepartmentByCompanyId = (id) => {
  return httpClient.get(server.DEPARTMENT + `/${id}`);
};

export const getAllDepartmentById = (id) => {
  return httpClient.get(server.DEPARTMENT + `/byid/${id}`);
};

export const checkByNameDepartment = (department, id_company) => {
  return httpClient.get(
    server.DEPARTMENT + `/checkbyname/${department}/${id_company}`
  );
};


export const addDepartment = (data) => {
  return httpClient.post(server.DEPARTMENT, data);
};

export const deleteDepartment = (id) => {
  return httpClient.delete(server.DEPARTMENT + `/${id}`);
};

export const updateDepartment = (id, data) => {
  return httpClient.put(server.DEPARTMENT + `/${id}`, data);
};
