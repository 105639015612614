<template>
  <v-container>
    <v-card class="mt-8">
      <v-data-table
        :search="search"
        :headers="lg_company_list.headers"
        :items="mDataArray"
        :items-per-page="7"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>{{ lg_company_list.title }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field
              v-model="search"
              append-icon="search"
              :label="lg_company_list.company_search"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn
              @click="$router.push('/company-create')"
              color="primary"
              dark
              class="mb-2"
            >
              <v-icon left>add</v-icon>
              <span>{{ lg_company_list.company_add }}</span>
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:item="{ item }">
          <tr :class="{ primary: item.id === selectedCompanyId }">
            <td @click="editItem(item)">{{ item.id }}</td>

            <td @click="editItem(item)">{{ item.company_name }}</td>
            <td @click="editItem(item)">{{ item.company_code }}</td>
            <td>
              <v-icon class="mr-2" @click="editItem(item)" color="green">
                edit
              </v-icon>
              <span class="ma-1"></span>
              <v-icon @click="deleteItem(item)" color="red"> delete </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>

      <v-dialog v-model="confirmDeleteDlg" width="unset">
        <v-card>
          <v-card-title class="headline">{{
            lg_company_list.dialog_title
          }}</v-card-title>

          <v-card-text class="body">
            {{ lg_company_list.dialog_delete }}
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="confirmDeleteDlg = false">
              {{ lg_company_list.dialog_cancel }}
            </v-btn>

            <v-btn color="error" text @click="confirmDelete">
              {{ lg_company_list.dialog_confirm }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- <LoadingDialog></LoadingDialog> -->
    </v-card>
  </v-container>
</template>

<script>
import api from "@/services/api";
import LoadingDialog from "../../components/cards/LoadingDialog.vue";

import { feature } from "../../services/constants";
import { lg_company_list } from "../language/lg_company";

export default {
  name: "company-config",
  beforeCreate() {
    localStorage.getItem(feature.LANGUAGE) === "th"
      ? (this.lg_company_list = lg_company_list.company_list_th)
      : (this.lg_company_list = lg_company_list.company_list_en);

    // console.log("lg_company_list: " + JSON.stringify(this.lg_company_list));
  },
  async mounted() {
    this.$store.state.loadMenu = true;
    this.loadCompany();
  },
  components: {
    LoadingDialog,
  },
  data() {
    return {
      search: "",
      selectedCompanyId: "",
      confirmDeleteDlg: false,
      mDataArray: [],
    };
  },
  methods: {
    async loadCompany() {
      let result = await api.getAllCompany();
      this.mDataArray = result.data;
      this.$store.state.loadMenu = false;
    },
    editItem(item) {
      this.$router.push(`/company-edit/${item.id}`);
      // alert(`/stock-edit/${item.id}`);
    },
    deleteItem(item) {
      this.selectedCompanyId = item.id;
      this.confirmDeleteDlg = true;
      // alert(`/company-delete/${this.selectedCompanyId}`);
    },
    async confirmDelete() {
      this.confirmDeleteDlg = false;
      // this.$store.state.loadMenu = true;
      const resdel =  await api.deleteCompany(this.selectedCompanyId);
      if(resdel.status == 200 || resdel.status == 201){
       await this.loadCompany();
      }
    },
  },
};
</script>

<style></style>
