import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getAllMeet = () => {
  return httpClient.get(server.MEETROOM_URL);
};

export const getMeetByID = (id) => {
  return httpClient.get(server.MEETROOM_URL + `/id/${id}`);
};

export const getByMeetNameOnly = (id) => {
  return httpClient.get(server.MEETROOM_URL + `/getbymeetnameonly/${id}`);
};

export const listRoomMeeting = () => {
  return httpClient.get(server.MEETROOM_URL + `/listroommeeting`);
};

export const listRoomMeetingByCompany = (company_id) => {
  return httpClient.get(
    server.MEETROOM_URL + `/listroommeetingbycompany/${company_id}`
  );
};

export const listRoomMeetingByDepartment = (department_id) => {
  return httpClient.get(
    server.MEETROOM_URL + `/listroommeetingbydepartment/${department_id}`
  );
};

export const listRoomMeetingAllStatus = () => {
  return httpClient.get(server.MEETROOM_URL + `/listroommeetingallstatus`);
};

export const listRoomMeetingAllStatusByCompany = (company_id) => {
  return httpClient.get(
    server.MEETROOM_URL + `/listroommeetingallstatusbycompany/${company_id}`
  );
};

export const checkByNameMeet = (meet_name, account_id) => {
  return httpClient.get(
    server.MEETROOM_URL +
    `/checkbyname?meet_name=${meet_name}&account_id=${account_id}`
  );
};

export const checkNameForUpdateMeet = (id, meet_name) => {
  return httpClient.get(
    server.MEETROOM_URL + `/checknameforupdate?id=${id}&meet_name=${meet_name}`
  );
};

export const addMeet = (data) => {
  return httpClient.post(server.MEETROOM_URL, data);
};

export const deleteMeet = (id) => {
  return httpClient.delete(server.MEETROOM_URL + `/${id}`);
};

export const updateMeet = (id, data) => {
  return httpClient.put(server.MEETROOM_URL + `/${id}`, data);
};

export const inviteMembers = (data) => {
  return httpClient.post(server.MEETROOM_URL + `/invite-members`, data)
}

export const generateSignatureMeet = (data) => {
  return httpClient.post(server.MEETROOM_URL + `/generateSignature`, data)
}

export const createMeetingdyte = (data) => {
  return httpClient.post(server.MEETROOM_URL + `/dyte/createMeeting`, data)
}

export const listRoomMeetingdyte = () => {
  return httpClient.get(server.MEETROOM_URL + `/listroommeetingdyte`);
};

export const listRoomMeetingByCompanydyte = (company_id) => {
  return httpClient.get(
    server.MEETROOM_URL + `/listroommeetingbycompanydyte/${company_id}`
  );
};

export const listRoomMeetingByDepartmentdyte = (department_id) => {
  return httpClient.get(
    server.MEETROOM_URL + `/listroommeetingbydepartmentdyte/${department_id}`
  );
};

export const updateMeetdyte = (id, data) => {
  return httpClient.put(server.MEETROOM_URL + `/dyte/${id}`, data);
};

export const deleteMeetdyte = (id) => {
  return httpClient.delete(server.MEETROOM_URL + `/dyte/${id}`);
};

export const addMeetdyte = (data) => {
  return httpClient.post(server.MEETROOM_URL+ `/dyte`, data);
};

export const checkByNameMeetdyte = (meet_name, account_id) => {
  return httpClient.get(
    server.MEETROOM_URL +
    `/checkbynamedyte?meet_name=${meet_name}&account_id=${account_id}`
  );
}


export const getByMeetNameOnlydyte = (id) => {
  return httpClient.get(server.MEETROOM_URL + `/getbymeetnameonlydyte/${id}`);
};

export const listRoomMeetingByAccountCreatedyte = (id) => {
  return httpClient.get(
    server.MEETROOM_URL + `/listRoomMeetingByAccountCreatetdyte/${id}`
  );
};

export const getMeetingRecordall = () => {
  return httpClient.get(server.MEETROOM_URL + '/dyte/recordall');
};

export const getMeetingRecordbyid = (id) => {
  return httpClient.get(server.MEETROOM_URL + `/dyte/recordbyid/${id}`);
};

