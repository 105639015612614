<template lang="html">
  <nav>
 

 
    <v-navigation-drawer  
    v-model="drawer"
    width="100%"
    absolute
      bottom
      temporary  permanent  color="white" >
    
      <p class="text-right">
      <v-btn  icon  @click="closemenumainpage">
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
       </p>
       <p class="text-center">
       <router-link to="/" exact>
        <img  src="@/assets/logo.png" alt="" width="30%"  />
      </router-link> 
       </p>
      <v-list shaped>
      
     
       <!-- <v-subheader>MENUS  </v-subheader> -->
       
        <v-list-item-group v-model="selectedMenu"  active-class="deep-purple--text text--accent-4" mandatory color="primary">
          <v-list-item
            class="tile"
            v-for="([icon, text, route], i) in menus"
            :key="i"
            link
            @click="onClickMenu(route)"
          >
            <v-list-item-icon>
              <v-icon>{{ icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import { feature } from "../../services/constants";
import { lg_menu_mainpage } from "../../views/language/lg_menu_mainpage";

export default {
  name: "MenuMainpage",
  props: [],
  menus: null,
 data() {
    return {
      selectedMenu: 0,
      drawer: true,
    };
  },
  async beforeCreate() {
     window.scrollTo(0,0);
    localStorage.getItem(feature.LANGUAGE) === "th"
      ? (this.lg_menu_mainpage = lg_menu_mainpage.lg_menu_mainpage_th)
      : (this.lg_menu_mainpage = lg_menu_mainpage.lg_menu_mainpage_en);
  },
  mounted() {
    let role = localStorage.getItem("role");

    // console.log(
    //   "language menu mounted : " + JSON.stringify(this.lg_menu.menus_admin)
    // );


      this.menus = this.lg_menu_mainpage;


    this.selectedMenu = this.menus.findIndex(
      (menu) => menu[2] == this.$route.path
    );
  },
 
  methods: {
    onClickMenu(link) {
      this.$router.push(link).catch((err) => {});
     this.$store.state.navMenuMainpage = !this.$store.state.navMenuMainpage;
    },
 closemenumainpage(){
this.$store.state.navMenuMainpage = false;
  },
  },
 
  watch: {
    $route(to, from) {
      // ชั้นของ Array || menu[2]
      this.selectedMenu = this.menus.findIndex((menu) => menu[2] == to.path);
    },
  },
  computed: {},
};
</script>

<style>

</style>
