<template>
  <v-container>
    <h3 class="text-center">
      <v-icon>mdi-text-box-multiple-outline</v-icon>
      {{ lg_listroom2.title }}
    </h3>
    <br />
    <v-data-iterator
      :items="mDataArray"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      :search="search"
      :sort-by="sortBy.toLowerCase()"
      :sort-desc="sortDesc"
      hide-default-footer
    >
      <template v-slot:header>
        <!-- <v-toolbar class="mb-1 mt-5"> -->
        <v-text-field
          v-model="search"
          clearable
          flat
          solo-inverted
          hide-details
          prepend-inner-icon="mdi-magnify"
          :label="lg_listroom2.room_search"
        ></v-text-field>
        <!-- </v-toolbar> -->
      </template>

      <template v-slot:default="props">
        <v-row>
          <v-col
            v-for="item in props.items"
            :key="item.meet_name"
            cols="12"
            sm="12"
            md="12"
            lg="6"
          >
            <v-card height="100%" class="mt-5">
              <v-card-title
                class="blue--text"
                style="color: #0d47a1 !important"
              >
                <div>
                  <v-icon style="color: #0d47a1">mdi-video</v-icon>
                  <span style="font-size: 16px"> {{ item.meet_topic }} </span>
                </div>
              </v-card-title>

              <!-- <v-btn color="blue darken-4" text>
                <v-icon>mdi-video</v-icon>
                <h4>{{ item.meet_topic }}</h4>
              </v-btn> -->

              <v-divider></v-divider>

              <v-list dense>
                <v-list-item>
                  <v-list-item-content style="max-width: 100px">{{
                    lg_listroom2.room_id
                  }}</v-list-item-content>
                  <v-list-item-content class="align-end">
                    <!-- {{ item.meet_topic }} -->
                    {{ item.meet_name }}
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content style="max-width: 100px">{{
                    lg_listroom2.room_user
                  }}</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ item.tbl_account.acc_fullname }}
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content style="max-width: 100px">{{
                    lg_listroom2.room_company
                  }}</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ item.company_name }}
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content style="max-width: 100px">{{
                    lg_listroom2.room_department
                  }}</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ item.department_name }}
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content style="max-width: 100px">{{
                    lg_listroom2.room_date
                  }}</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ formatDate(item.meet_create) }}
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content style="max-width: 100px">{{
                    lg_listroom2.room_schedule
                  }}</v-list-item-content>
                  <v-list-item-content class="align-end green--text">
                    {{ formatDate(item.meet_start) }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  :style="{
                    visibility:
                      item.meet_pass === null || item.meet_pass === ''
                        ? 'hidden'
                        : 'visible',
                  }"
                >
                  <v-list-item-content style="max-width: 100px">{{
                    lg_listroom2.room_remark
                  }}</v-list-item-content>
                  <v-list-item-content
                    :style="{
                      visibility:
                        item.meet_pass === null || item.meet_pass === ''
                          ? 'hidden'
                          : 'visible',
                    }"
                  >
                    <v-list-item-content class="align-end red--text">{{
                      lg_listroom2.room_message_pass
                    }}</v-list-item-content>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  :style="{
                    visibility:
                      item.meet_pass === null || item.meet_pass === ''
                        ? 'hidden'
                        : 'visible',
                  }"
                >
                  <v-list-item-content style="max-width: 100px">{{
                    lg_listroom2.password
                  }}</v-list-item-content>
                  <v-list-item-content
                    :style="{
                      visibility:
                        item.meet_pass === null || item.meet_pass === ''
                          ? 'hidden'
                          : 'visible',
                    }"
                  >
                    <v-list-item-content class="align-end red--text">
                      <!-- {{
                      item.meet_pass
                    }} -->
                      <v-text-field
                        class="align-end red--text"
                        single-line
                        readonly
                        flat
                        hide-details
                        v-model="item.meet_pass"
                        :append-icon="
                          item.showpass ? 'visibility' : 'visibility_off'
                        "
                        @click:append="item.showpass = !item.showpass"
                        :type="item.showpass ? 'password' : 'text'"
                        required
                        variant
                        solo
                        dense
                      />
                    </v-list-item-content>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  :style="{
                    visibility:
                      item.meet_lobby_sta === 'N' ? 'hidden' : 'visible',
                  }"
                >
                  <v-list-item-content style="max-width: 100px">{{
                    lg_listroom2.room_lobby
                  }}</v-list-item-content>
                  <v-list-item-content
                    :style="{
                      visibility:
                        item.meet_lobby_sta === 'N' ? 'hidden' : 'visible',
                    }"
                  >
                    <v-list-item-content class="align-end red--text">{{
                      lg_listroom2.room_message_lobby
                    }}</v-list-item-content>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <div class="d-flex flex-column">
                <v-btn
                  class="mb-5 ml-5 mr-5 blue darken-3 white--text"
                  v-bind="attrs"
                  v-on="on"
                  @click="openInviteModal(item)"
                >
                  {{ lg_listroom2.btn_invite }}
                </v-btn>
              </div>
              <!--end-->

              <div class="text-center"></div>

              <!-- @click="onClickJoinMeetion(item)" -->
              <!-- :href="item.meet_admin_link" -->
              <div class="d-flex flex-column" v-if="checkRoleAdmin">
                <v-btn
                  class="mb-5 ml-5 mr-5 green darken white--text text-tranform-none"
                  @click="onClickJoinMeetion(item)"
                >
                  {{ lg_listroom2.btn_join }}
                </v-btn>
              </div>
              <div class="d-flex flex-column" v-if="!checkRoleAdmin">
                <v-btn
                  class="mb-5 ml-5 mr-5 green darken white--text text-tranform-none"
                  @click="onClickJoinMeetion(item)"
                >
                  {{ lg_listroom2.btn_join }}
                </v-btn>
              </div>
              <div class="d-flex flex-column" v-if="acc_plan === 'PREMIUM'">
                <v-btn
                  class="mb-5 ml-5 mr-5 orange darken white--text"
                  @click="onClickJoinLivestream(item)"
                >
                  {{ lg_listroom2.btn_join_livestream }}
                </v-btn>
              </div>
              <div class="d-flex flex-column">
                <v-btn
                  class="mb-5 ml-5 mr-5 grey darken-4 white--text"
                  @click="dialogCloseMeetion(item)"
                >
                  {{ lg_listroom2.btn_close }}
                </v-btn>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </template>

      <template v-slot:footer>
        <v-row
          class="justify-space-between px-3 pt-5 pb-5"
          align="center"
          justify="center"
        >
          <v-spacer></v-spacer>
        </v-row>

        <v-layout class="justify-space-between mb-0 mt-5">
          <div>
            <span class="grey--text">Items per page</span>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  text
                  color="primary"
                  class="ml-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ itemsPerPage }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(number, index) in itemsPerPageArray"
                  :key="index"
                  @click="updateItemsPerPage(number)"
                >
                  <v-list-item-title>{{ number }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <div>
            <span class="mr-4 grey--text">
              Page {{ page }} of {{ numberOfPages }}
            </span>
            <v-btn
              outlined
              plain
              small
              color="blue darken-3"
              class="mr-1"
              @click="formerPage"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn
              outlined
              plain
              small
              color="blue darken-3"
              class="ml-1"
              @click="nextPage"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </v-layout>
      </template>
    </v-data-iterator>

    <LoadingDialog></LoadingDialog>

    <!-- Alert Dialog -->
    <v-dialog v-model="alertDlg" max-width="60%">
      <v-card>
        <v-card-title class="headline">{{
          this.titleMessageAlert
        }}</v-card-title>

        <v-card-text class="body">
          {{ this.messageAlert }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            text
            @click="alertDlg = false"
            col="12"
            lg="12"
            md="12"
            sm="12"
          >
            Close
          </v-btn>

          <!-- <v-btn outlined color="error" text @click="confirmDelete">
            Confirm
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Alert Dialog -->
    <v-dialog v-model="dialog" scrollable width="unset">
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false" height="20">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-4" align="center">
          <h3
            v-bind:style="{
              color: '#000000',
            }"
          >
            {{ this.lg_listroom2.dialog_invite_title }}
          </h3>

          <v-combobox
           class="mt-5"
            v-model="emails"
            outlined
            clearable
            :label="lg_listroom2.dialog_invite_send_to"
            multiple
            prepend-icon="mdi-email"
          >
            <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip
                v-bind="attrs"
                :input-value="selected"
                close
                @click:close="removeEmail(item)"
              >
                <strong>{{ item }}</strong
                >&nbsp;
              </v-chip>
            </template>
          </v-combobox>

          <v-btn
            align="center"
            min-width="120"
            color="#3E73BB"
            class="rounded-pill"
            dark
            @click="inviteMembers(item)"
          >
            {{ this.lg_listroom2.btn_send }}
          </v-btn>

          <h3
            class="mt-5"
            v-clipboard:copy="message"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            align="center"
            v-bind:style="{
              color: '#000000',
              align: 'left',
              textDecoration: 'underline',
              cursor: 'pointer',
            }"
            @click="onClickMenu('/contact')"
          >
            {{ this.lg_listroom2.dialog_invite_copy_link }}
          </h3>

          <h3
            class="mt-5 mb-2"
            v-clipboard:copy="messagelivestream"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            align="center"
            v-bind:style="{
              color: '#000000',
              align: 'left',
              textDecoration: 'underline',
              cursor: 'pointer',
            }"
            @click="onClickMenu('/contact')"
          >
            {{ this.lg_listroom2.dialog_invite_copy_link_livestream }}
          </h3>
        </v-card-text>
        <!-- <v-divider></v-divider> -->
        <!-- <v-card-actions>
          <v-btn
            class="mx-1"
            text
            color="blue darken-1"
            v-clipboard:copy="message"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            <v-icon dark right> mdi-content-copy </v-icon>
            {{ this.lg_listroom2.dialog_invite_copy_link }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="inviteMembers">
            <v-icon dark right> mdi-send </v-icon>
            {{ this.lg_listroom2.dialog_invite_send_link }}
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogclosemeeting" width="unset">
      <v-card>
        <v-card-title class="text-h5">
          {{ this.lg_listroom2.dialog_delete }}</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="canceldialogCloseMeetion"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="confirmdialogCloseMeetion"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import api from "@/services/api";
import LoadingDialog from "@/components/cards/LoadingDialog.vue";
import { server } from "../services/constants";
import { format } from "date-fns";
import { feature } from "../services/constants";
import { lg_listroom2 } from "./language/lg_listroom_2";
export default {
  name: "listroom",
  beforeCreate() {
    localStorage.getItem(feature.LANGUAGE) === "th"
      ? (this.lg_listroom2 = lg_listroom2.listroom2_th)
      : (this.lg_listroom2 = lg_listroom2.listroom2_en);
  },
  async mounted() {
    this.acc_plan = localStorage.getItem("acc_plan");
    localStorage.setItem(server.MEET_STA, "false");
    if (localStorage.getItem(server.ROLE) == "Admin") {
      this.checkRoleAdmin = true;
    }
    this.$store.state.loadMenu = true;
    this.$store.state.meetSta = false;
    this.$store.state.loadFooter = true;
    this.loadListRoomMeetingData();
  },
  components: {
    LoadingDialog,
  },
  data() {
    return {
      acc_plan: "",
      e1: true,
      dataclosemeeting: null,
      dialogclosemeeting: false,
      showsig1: false,
      message: null,
      messagelivestream: null,
      checkRoleAdmin: false,
      resultJson: "",
      itemsPerPageArray: [4, 8],
      search: "",
      alertDlg: false,
      titleMessageAlert: this.lg_listroom2.titleMessageAlert,
      messageAlert: this.lg_listroom2.messageAlert,
      role: "",
      filter: {},
      sortDesc: true,
      page: 1,
      itemsPerPage: 4,
      mDataArray: [],
      sortBy: "id",
      keys: ["meet_name"],
      meetjoin: {
        meet_id: 0,
        meet_join_name: "",
        account_id: 0,
      },
      meet: { meet_sta: "N", user_update: "", lastuser_update: "" },
      dialogm1: "",
      dialog: false,
      emails: [],
      selectedRoom: null,
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.mDataArray.length / this.itemsPerPage);
    },
    filteredKeys() {
      return this.keys.filter((key) => key !== "meet_name");
    },
  },
  methods: {
    doCopy: function (role, item) {
      this.$copyText(this.message).then(
        function (e) {
          // alert("Copied");
          // console.log(e);
        },
        function (e) {
          // alert("Can not copy");
          console.log(e);
        }
      );
      if (this.message != "" || this.message != null) {
        this.$swal.fire("Success", "Copied", "success");
      } else {
        this.$swal.fire("Error", "Can Not Copy", "error");
      }
    },
    onCopy() {
      this.dialog = false;
      this.$swal.fire("Success", "Copied", "success");
    },
    onError() {
      this.$swal.fire("Error", "Can Not Copy", "error");
    },
    openInviteModal(item) {
      this.selectedRoom = item;
      this.assignMessage(item);
      this.dialog = true;
    },
    assignMessage(item) {
      // old format date format(new Date(item.meet_start), "MMM dd, yyyy HH:mm")
      this.message =
        `${item.tbl_account.acc_fullname} is inviting you to a scheduled Dmeets meeting.\n\n` +
        `Topic: ${item.meet_topic ?? "-"}\n` +
        `${
          item.meet_start
            ? `Time: ${
                new Date(item.meet_start).toLocaleString("en-US", {
                  timeZone: "Asia/Bangkok",
                  dateStyle: "medium",
                  timeStyle: "short",
                }) + " Bangkok Time (UTC/GMT+7)"
              }\n\n`
            : ""
        }` +
        "Join Dmeets Meeting\n" +
        item.meet_user_link +
        "/meeting" +
        "\n\n" +
        "Meeting Number : \n" +
        item.meet_name +
        "\n" +
        `${item.meet_pass ? `Password:\n${item.meet_pass}\n` : ""}`;

      this.messagelivestream =
        `${item.tbl_account.acc_fullname} is inviting you to a scheduled Dmeets meeting.\n\n` +
        `Topic: ${item.meet_topic ?? "-"}\n` +
        `${
          item.meet_start
            ? `Time: ${
                new Date(item.meet_start).toLocaleString("en-US", {
                  timeZone: "Asia/Bangkok",
                  dateStyle: "medium",
                  timeStyle: "short",
                }) + " Bangkok Time (UTC/GMT+7)"
              }\n\n`
            : ""
        }` +
        "Join Dmeets Livestream\n" +
        item.meet_user_link +
        "/livestream" +
        "\n\n" +
        "Meeting Number : \n" +
        item.meet_name +
        "\n" +
        `${item.meet_pass ? `Password:\n${item.meet_pass}\n` : ""}`;
    },
    removeEmail(item) {
      this.emails.splice(this.emails.indexOf(item), 1);
      this.emails = [...this.emails];
    },
    async inviteMembers() {
      this.assignMessage(this.selectedRoom);
      try {
        this.$swal.showLoading();
        const payload = {
          members: this.emails,
          link: this.message,
        };
        const res = await api.inviteMembers(payload);
        if (res && res.data && res.data.status === "success") {
          this.dialog = false;
          this.$swal.fire("Success", res.data.message, "success");
        } else {
          this.$swal.fire("Error", res.data.message, "error");
        }
      } catch (e) {
        console.log(e);
        this.$swal.fire("Error", e.message, "error");
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      const [hh, mm] = day.split("T")[1].split(":");
      const hour = parseInt(hh);
      return `${year}-${month}-${day.split("T")[0]} ${hour + 7}:${mm}`;
    },
    async loadListRoomMeetingData() {
      this.mDataArray = [];
      // let result = await api.listRoomMeeting();
      //   console.log(result);
      // let count = result.data.count;
      // for (let i=0;i<count;i++){
      //     this.mDataArray.push(result.data.rows.[i]);
      // }
      let role = localStorage.getItem(server.ROLE);
      let company_id = localStorage.getItem(server.COMPANYID);
      let department_id = localStorage.getItem(server.DEPARTMENT_ID);
      let userId = localStorage.getItem(server.USER_ID);
      let result = null;
      if (role == "Admin") {
        result = await api.listRoomMeetingdyte();
      } else if (role == "Company Admin") {
        result = await api.listRoomMeetingByCompanydyte(company_id);
      } else {
        // result = await api.listRoomMeetingByDepartmentdyte(department_id);
        result = await api.listRoomMeetingByAccountCreatedyte(userId);
      }
      this.mDataArray = result.data.rows;
      // console.log(this.mDataArray)
      this.$store.commit("SET_MEETING_ROOMS", this.mDataArray);
      // console.log(JSON.stringify(this.mDataArray));
      this.$store.state.loadMenu = false;
    },
    async dialogCloseMeetion(item) {
      this.dataclosemeeting = item;
      this.dialogclosemeeting = true;
    },
    async canceldialogCloseMeetion() {
      this.dataclosemeeting = null;
      this.dialogclosemeeting = false;
    },
    async confirmdialogCloseMeetion(item) {
      this.dialogclosemeeting = false;
      await this.onClickCloseMeetion(this.dataclosemeeting);
    },
    async onClickCloseMeetion(item) {
      this.username = localStorage.getItem(server.USERNAME);
      this.resultJson = await api.checkRegister(this.username);
      const userId = localStorage.getItem(server.USER_ID);
      this.meet.user_update = userId;
      this.meet.lastuser_update = new Date();
      this.role = localStorage.getItem(server.ROLE);
      if (this.role == "Admin") {
        // console.log("Admin");
        // alert("Admin Close Room");
        this.$store.state.loadMenu = true;
        this.meet.meet_sta = "N";
        await api.deleteMeetdyte(item.meet_id_show);
        this.loadListRoomMeetingData();
      } else {
        if (item.account_id == this.resultJson.data.id) {
          // alert("Account Close Room");
          this.$store.state.loadMenu = true;
          this.meet.meet_sta = "N";
          await api.deleteMeetdyte(item.meet_id_show);
          this.loadListRoomMeetingData();
        } else {
          this.titleMessageAlert = this.lg_listroom2.titleMessageAlert;
          this.messageAlert = this.lg_listroom2.messageAlert;
          this.alertDlg = true;
          return;
        }
      }
    },
    async onClickJoinMeetion(item) {
      this.$store.state.loadMenu = true;
      localStorage.setItem("jwt", item.meet_token);
      // console.log("token : " + item.meet_token);
      this.username = localStorage.getItem(server.USERNAME);
      this.resultJson = await api.checkRegister(this.username);
      this.meetjoin.meet_join_name = this.resultJson.data.acc_fullname;
      this.meetjoin.account_id = this.resultJson.data.id;
      this.meetjoin.meet_id = item.id;
      if (
        localStorage.getItem(server.USER_ID) == item.account_id ||
        localStorage.getItem(server.ROLE) == "Admin" ||
        localStorage.getItem(server.ROLE) == "Company Admin"
      ) {
        localStorage.setItem(server.MODERATOR_STA, "true");
      } else {
        localStorage.setItem(server.MODERATOR_STA, "false");
      }
      // console.log("token111 : ", localStorage.getItem(server.MODERATOR_STA));
      // console.log("token111 : ", localStorage.getItem(server.ROLE));
      // console.log("token111 : ", localStorage.getItem(server.USER_ID));
      await api.addMeetJoin(this.meetjoin);
      // this.$store.state.meetSta = !this.$store.state.meetSta;
      this.$store.state.navMenu = false;
      this.$store.state.loadMenu = false;
      // alert(item.id);
      // this.$router.push(`/meet/${item.meet_appid}/${item.meet_name}`);
      this.$router.push(`/meet/dmeets/${item.meet_name}/meeting`);
    },
    async onClickJoinLivestream(item) {
      this.$store.state.loadMenu = true;
      localStorage.setItem("jwt", item.meet_token);
      // console.log("token : " + item.meet_token);
      this.username = localStorage.getItem(server.USERNAME);
      this.resultJson = await api.checkRegister(this.username);
      this.meetjoin.meet_join_name = this.resultJson.data.acc_fullname;
      this.meetjoin.account_id = this.resultJson.data.id;
      this.meetjoin.meet_id = item.id;
      if (
        localStorage.getItem(server.USER_ID) == item.account_id ||
        localStorage.getItem(server.ROLE) == "Admin" ||
        localStorage.getItem(server.ROLE) == "Company Admin"
      ) {
        localStorage.setItem(server.MODERATOR_STA, "true");
      } else {
        localStorage.setItem(server.MODERATOR_STA, "false");
      }
      // console.log("token111 : ", localStorage.getItem(server.MODERATOR_STA));
      // console.log("token111 : ", localStorage.getItem(server.ROLE));
      // console.log("token111 : ", localStorage.getItem(server.USER_ID));
      await api.addMeetJoin(this.meetjoin);
      // this.$store.state.meetSta = !this.$store.state.meetSta;
      this.$store.state.navMenu = false;
      this.$store.state.loadMenu = false;
      // alert(item.id);
      // this.$router.push(`/meet/${item.meet_appid}/${item.meet_name}`);
      this.$router.push(`/meet/dmeets/${item.meet_name}/livestream`);
    },

    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
  },
};
</script>
<style scoped>
.v-btn {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  flex: 0 0 auto;
  font-weight: 500;
  letter-spacing: 0.0892857143em;
  justify-content: center;
  outline: 0;
  position: relative;
  text-decoration: none;
  text-indent: 0.0892857143em;
  text-transform: capitalize;
  transition-duration: 0.28s;
  transition-property: box-shadow, transform, opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}
.v-application.v-application--is-ltr.theme--light {
  font-family: "Kanit", serif;
}
.v-application {
  font-size: 13px;
}
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: none !important;
  font-size: 13px;
}
.text-tranform-none {
  text-transform: none;
}
</style>
