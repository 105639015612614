<template>
  <v-container>
    <v-card class="mt-5 ml-5 mr-5">
      <v-card-title primary-title>
        {{ lg_account_edit_admin.title }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form @submit.prevent="submit">
          <div>
            <strong class="success--primary">{{
              lg_account_edit_admin.license_type
            }}</strong>
          </div>
          <v-radio-group row v-model="account.acc_plan">
            <v-radio value="FREE">
              <template v-slot:label>
                <div>
                  {{ lg_account_edit_admin.cb_free }} (<strong
                    class="info--text"
                    >{{ daysLeft }}</strong
                  >)
                </div>
              </template>
            </v-radio>
            <v-radio
              :label="lg_account_edit_admin.cb_premium"
              value="PREMIUM"
            ></v-radio>
          </v-radio-group>
          <v-select
            v-validate="'required'"
            :items="mDataArray"
            :label="lg_account_edit_admin.sl_company"
            item-text="company_name"
            item-value="id"
            v-model="account.company_id"
            outlined
            @change="changeCompany"
          ></v-select>

          <v-select
            v-validate="'required'"
            :items="mDataArrayDepartment"
            :label="lg_account_edit_admin.sl_department"
            item-text="department"
            item-value="id"
            v-model="account.department_id"
            outlined
            @change="changeDepartment"
          ></v-select>

          <v-text-field
            :label="lg_account_edit_admin.txt_name"
            v-model="account.acc_fullname"
            outlined
          />
          <v-text-field
            label="เลขบัตรประจำตัวประชาชน / Passport"
            v-model="account.acc_id_card"
            outlined
            v-if="false"
          />
          <v-text-field
            :label="lg_account_edit_admin.email"
            v-model="account.username"
            disabled
            outlined
          />
          <!-- <v-text-field
            label="วัน-เดือน-ปี"
            v-model="account.acc_birth"
            disabled
          /> -->

          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <!-- v-model="dateFormatted" -->
            <!-- v-model="account.acc_birth" -->
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateFormatted"
                :label="lg_account_edit_admin.dtp_birthday"
                persistent-hint
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                outlined
                @blur="date = parseDate(dateFormatted)"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              no-title
              @input="menu1 = false"
            ></v-date-picker>
          </v-menu>

          <v-text-field
            :label="lg_account_edit_admin.txt_phone"
            v-model="account.acc_tel"
            outlined
          />

          <!-- <v-select
            v-validate="'required'"
            :items="
              role == 'Admin' ? role_items_admin : role_items_company_admin
            "
            label="Department"
            item-text="role"
            item-value="role"
            v-model="account.role"
            outlined
            @change="onDepartmentChange"
          ></v-select> -->

          <v-select
            v-validate="'required'"
            :items="
              role == 'Admin' ? role_items_admin : role_items_company_admin
            "
            :label="lg_account_edit_admin.txt_permission"
            item-text="role"
            item-value="role"
            v-model="account.role"
            outlined
            @change="onPermissionChange"
          ></v-select>

          <v-divider></v-divider>
          <!-- <span>{{ JSON.stringify(this.account) }}</span> -->

          <!-- <span>{{ account }}</span> -->
          <v-row class="justify-space-between px-3 pt-5 pb-5">
            <v-btn
              outlined
              text
              @click.prevent="$router.push('/list-account')"
              >{{ lg_account_edit_admin.btn_cancel }}</v-btn
            >
            <v-btn type="submit" color="primary">{{
              lg_account_edit_admin.btn_save
            }}</v-btn>
            <!-- <v-btn @click.prevent="registerMember" color="success"
                >Register</v-btn
              > -->
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <!-- Alert Dialog -->
    <v-dialog v-model="alertDlg" max-width="60%">
      <v-card>
        <v-card-title class="headline">{{
          this.titleMessageAlert
        }}</v-card-title>

        <v-card-text class="body">
          {{ this.messageAlert }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            text
            @click="alertDlg = false"
            col="12"
            lg="12"
            md="12"
            sm="12"
          >
            Close
          </v-btn>

          <!-- <v-btn outlined color="error" text @click="confirmDelete">
            Confirm
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Alert Dialog -->

    <!-- Complete Dialog -->
    <v-dialog v-model="completeDlg" max-width="60%">
      <v-card>
        <v-card-title class="headline">{{
          this.titleMessageAlert
        }}</v-card-title>

        <v-card-text class="body">
          {{ this.messageAlert }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            text
            @click="onclickcompleteDlg()"
            col="12"
            lg="12"
            md="12"
            sm="12"
          >
            Close
          </v-btn>

          <!-- <v-btn outlined color="error" text @click="confirmDelete">
                Confirm
              </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Complete Dialog -->

    <LoadingDialog></LoadingDialog>
  </v-container>
</template>

<script>
import api from "@/services/api";
import LoadingDialog from "../../components/cards/LoadingDialog.vue";

import { feature } from "../../services/constants";
import { lg_account_edit_admin } from "../language/lg_account_edit_admin";

export default {
  name: "account-edit-admin",
  beforeCreate() {
    localStorage.getItem(feature.LANGUAGE) === "th"
      ? (this.lg_account_edit_admin =
          lg_account_edit_admin.account_edit_admin_th)
      : (this.lg_account_edit_admin =
          lg_account_edit_admin.account_edit_admin_en);

    if (this.resultJson == null) {
      this.checkData = false;
    }
  },
  async mounted() {
    this.role = localStorage.getItem("role");
    this.account.id = this.$route.params.id;
    this.$store.state.loadMenu = true;
    this.loadGuest();
    this.loadAccountData();
    this.loadCompany();
  },
  data(vm) {
    return {
      role_items_admin: [
        { role: "Admin" },
        { role: "Company Admin" },
        { role: "Member" },
        { role: "Guest" },
      ],
      role_items_company_admin: [
        { role: "Company Admin" },
        { role: "Member" },
        { role: "Guest" },
      ],
      role: "",
      resultJson: null,
      geust_id: "",
      geust_name: "",
      geust_ckeck: false,
      resultJsonCompanyName: null,
      checkData: false,
      alertDlg: false,
      completeDlg: false,
      titleMessageAlert: "",
      messageAlert: "",
      account: {
        id: 0,
        username: "",
        company_id: 0,
        department_id: 0,
        acc_fullname: "",
        acc_id_card: "",
        acc_tel: "",
        acc_birth: null,
        role: "",
        department: "",
        acc_plan: "",
        created_at: "",
      },
      date: new Date().toISOString().substr(0, 10),
      dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
      menu1: false,
      mDataArray: [],
      mDataArrayGuest: [],
      mDataArrayDepartment: [],
      daysLeft: undefined,
    };
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  components: {
    LoadingDialog,
  },
  methods: {
    changeDepartment(selectObj) {
      console.log(selectObj);
    },
    async changeCompany(selectObj) {
      if (this.account.company_id == this.geust_id) {
        this.account.role = this.geust_name;
      } else {
        this.account.role = "Member";
      }
      console.log(selectObj);
      this.loadDepartment(selectObj);
    },
    async loadDepartment(id) {
      // console.log("loadDepartment")
      let result = await api.getAllDepartmentByCompanyId(id);
      // console.log(result)
      this.mDataArrayDepartment = result.data;
      // console.log(JSON.stringify(result.data));
    },
    async submit() {
      this.$store.state.loadMenu = true;
      // this.titleMessageAlert = "กรุณาตรวจสอบข้อมูล";
      this.titleMessageAlert = this.lg_account_edit_admin.dialog_check_data;
      this.account.acc_birth = this.date;
      // alert(JSON.stringify(this.account));

      if (
        this.account.role != this.geust_name &&
        this.account.company_id == this.geust_id
      ) {
        // this.messageAlert = "กรุณาเลือกบริษัท";
        this.messageAlert = this.lg_account_edit_admin.dialog_select_company;
        this.alertDlg = true;
        this.$store.state.loadMenu = false;
        return;
      }

      if (this.account.company_id == 0 && this.account.role == "Member") {
        // this.messageAlert = "กรุณาเลือกบริษัท";
        this.messageAlert = this.lg_account_edit_admin.dialog_select_company;
        this.alertDlg = true;
        this.$store.state.loadMenu = false;
        return;
      }

      // this.titleMessageAlert = "บันทึกข้อมูล";
      this.titleMessageAlert = this.lg_account_edit_admin.dialog_check_save;
      // this.messageAlert = "บันทึกข้อมูลสำเร็จ";
      this.messageAlert = this.lg_account_edit_admin.dialog_check_save_success;
      this.completeDlg = true;
      // this.$router.push("/main-page");
      this.$store.state.loadMenu = false;

      const result_update_account = await api.updateAccount(
        this.account.id,
        this.account
      );

      // alert(JSON.stringify(result_update_account));
      if (result_update_account != null) {
        // alert("result_update_account.data != null");
        this.isRegister = true;
        // this.titleMessageAlert = "บันทึกข้อมูล";
        this.titleMessageAlert = this.lg_account_edit_admin.dialog_check_save;
        // this.messageAlert = "บันทึกข้อมูลสำเร็จ";
        this.messageAlert =
          this.lg_account_edit_admin.dialog_check_save_success;
        this.completeDlg = true;
        // this.$router.push("/main-page");
        this.$store.state.loadMenu = false;
      } else {
        // alert("result_update_account.data == null");
        this.$store.state.loadMenu = false;
      }
    },
    onclickcompleteDlg() {
      this.completeDlg = false;
      this.$router.push("/list-account");
    },
    onPermissionChange() {
      if (this.account.role == this.geust_name) {
        this.account.company_id = this.geust_id;
      } else {
        if (this.account.company_id == this.geust_id) {
          // this.titleMessageAlert = "กรุณาตรวจสอบข้อมูล";
          this.titleMessageAlert = this.lg_account_edit_admin.dialog_check_data;
          // this.messageAlert = "กรุณาเลือกบริษัท";
          this.messageAlert = this.lg_account_edit_admin.dialog_select_company;
          this.alertDlg = true;
        }
      }
    },
    onDepartmentChange() {},
    onCompanyChange() {
      if (this.account.company_id == this.geust_id) {
        this.account.role = this.geust_name;
      } else {
        this.account.role = "Member";
      }
    },
    async loadGuest() {
      this.mDataArrayGuest = [];

      let result = await api.getCompanyGuestId();
      // console.log("loadGuest : " + JSON.stringify(result.data));
      this.mDataArrayGuest = result.data.rows;

      // console.log("loadGuest : " + this.mDataArrayGuest[0].id);
      // console.log("loadGuest : " + this.mDataArrayGuest[0].company_name);
      // console.log("loadGuest2 : " + JSON.stringify(result.data.id));
      this.geust_id = this.mDataArrayGuest[0].id;
      this.geust_name = this.mDataArrayGuest[0].company_name;
    },
    async loadCompany() {
      let result = await api.getAllCompany();
      this.mDataArray = result.data;
      // console.log(JSON.stringify(result.data));
    },
    async loadAccountData() {
      // alert(JSON.stringify(username));
      this.resultJson = await api.getAccountById(this.account.id);
      // alert(JSON.stringify(this.resultJson.data.company_id));
      console.log("loadAccountData :" + JSON.stringify(this.resultJson.data));
      this.resultJsonCompanyName = await api.getCompanyByID(
        this.resultJson.data.company_id
      );

      this.loadDepartment(this.resultJson.data.company_id);

      // this.account.id = this.resultJson.data.id
      this.account.company_id = this.resultJson.data.company_id;
      this.account.department_id = this.resultJson.data.department_id;
      this.account.acc_fullname = this.resultJson.data.acc_fullname;
      this.account.acc_id_card = this.resultJson.data.acc_id_card;
      this.account.username = this.resultJson.data.username;
      this.account.acc_birth = this.resultJson.data.acc_birth;
      this.dateFormatted = this.resultJson.data.acc_birth;
      this.account.acc_tel = this.resultJson.data.acc_tel;
      this.account.role = this.resultJson.data.role;
      this.account.acc_plan = this.resultJson.data.acc_plan;
      this.account.created_at = this.resultJson.data.created_at;
      this.calculateDaysLeft(this.account.created_at);
      // alert(JSON.stringify(this.resultJsonCompanyName.data));
      this.checkData = true;
      this.$store.state.loadMenu = false;
    },
    calculateDaysLeft(created_at) {
      const created = new Date(created_at);
      const today = new Date();
      const difference_in_time = today.getTime() - created.getTime();
      const difference_in_days = difference_in_time / (1000 * 3600 * 24);
      const daysInt = Math.ceil(difference_in_days);
      this.daysLeft = daysInt >= 90 ? "Expired" : `${90 - daysInt} days left`;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${year}-${month}-${day}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>

<style></style>
