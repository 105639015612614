<template lang="html">
  <v-container>
    <v-row align="center" justify="center" class="pt-10">
      <v-card style="width:95%;">
        <v-card-title>เข้าสู่ระบบ Dmeets</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form @submit.prevent="submit" ref="form">
            <!-- Username field -->
            <v-text-field
              label="New Password"
              placeholder="New Password"
              v-model="account.password"
              :append-icon="e1 ? 'visibility' : 'visibility_off'"
              @click:append="e1 = !e1"
              :type="e1 ? 'password' : 'text'"
              required
              outlined
              :rules="passwordRules"
            />

            <v-text-field
              label="Confirm Password"
              placeholder="Confirm Password"
              v-model="account.confirm_password"
              :append-icon="e1 ? 'visibility' : 'visibility_off'"
              @click:append="e1 = !e1"
              :type="e1 ? 'password' : 'text'"
              required
              outlined
              :rules="passwordConfirmRules"
            />

            <v-alert
              v-model="$store.getters['altlogin']"
              dense
              outlined
              type="error"
            >
              {{ messageAlert }}
            </v-alert>

            <v-divider></v-divider>
            <!-- Buttons  -->
            <v-layout justify-space-between class="mt-5">
              <v-btn
                outlined
                text
                @click.prevent="$router.push('/login-account').catch(()=>{});"
                >Back</v-btn
              >
              <v-btn
                style="marginLeft: 20px"
                type="submit"
                :class="{
                  'primary white--text': valid,
                  disabled: !valid,
                }"
                >Reset</v-btn
              >
            </v-layout>
          </v-form>
        </v-card-text>
      </v-card>
    </v-row>

    <LoadingDialog></LoadingDialog>
  </v-container>
</template>

<script lang="js">

import api from "@/services/api";
import LoadingDialog from "@/components/cards/LoadingDialog";
import { server } from "../services/constants";

  export default  {
    name: 'reset',
    props: [],
    created() {
    },
    components: {
      LoadingDialog,
    },
    mounted () {
      localStorage.setItem(server.MEET_STA, "false");
      const token = this.$route.query.token
      const id = this.$route.query.id
      if (api.isLoggedIn() || !token || !id){
        this.$router.push("/main-page")
      }
    },
    data () {
          return {
            messageAlert: "Email หรือ Password ไม่ถูกต้อง กรุณาตรวจสอบข้อมูล",
            account:{
              password:"",
              confirm_password: ""
            },
            valid: false,
            e1: true,
            passwordRules: [
              (v1) => !!v1 || "Password is required",
              (v2) =>
                !!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(v2) ||
                "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character",
            ],
            passwordConfirmRules: [
              (v1) => this.account.password == this.account.confirm_password || "Password not match"
            ],
            email: '',
            emailRules: [
              (v) => !!v || 'E-mail is required!!!',
            ],
          }
        },
        methods: {
          async submit() {
             if (this.$refs.form.validate()) {
            this.$store.state.loadMenu=true;
            // if (this.$refs.form.validate()) {
              try {
                const token = this.$route.query.token
                const id = this.$route.query.id
                const password = this.account.password
                const body = {token, id, password}
                const res = await api.resetPasswordByUsername(body)
                this.$store.state.loadMenu=false;
                if(res.data && res.data.reset) {
                    alert('Password Reset Successfully')
                    this.$router.push("/login-account")
                } else {
                    alert('Something went wrong')
                }
              }
              catch (e) {
                alert(e.message)
                 this.$store.state.loadMenu=false;
              }
            // }
            console.log("Click Submin not if");

            this.$store.state.loadMenu = false;
             }
          },
          clear () {
            this.$refs.form.reset()
          }

        },
    computed: {

    }
}
</script>

<style scoped lang="scss">
.buttons {
  display: flex;
  justify-content: flex-end;
}
.v-progress-circular {
  margin: 1rem;
}
</style>
