import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getAllCompany = () => {
  return httpClient.get(server.COMPANY_URL);
};

export const getCompanyByID = (id) => {
  return httpClient.get(server.COMPANY_URL + `/id/${id}`);
};

export const getCompanyNotGuest = () => {
  return httpClient.get(server.COMPANY_URL + `/allnotguest`);
};

export const getCompanyGuestId = () => {
  return httpClient.get(server.COMPANY_URL + `/guestid`);
};

export const checkByNameCompany = (company_name) => {
  return httpClient.get(
    server.COMPANY_URL + `/checkbyname?company_name=${company_name}`
  );
};

export const checkByCompanyCode = (company_code) => {
  return httpClient.get(
    server.COMPANY_URL + `/checkbycompanycode?company_code=${company_code}`
  );
};

export const checkNameForUpdateCompany = (id, company_name) => {
  return httpClient.get(
    server.COMPANY_URL +
      `/checknameforupdate?id=${id}&company_name=${company_name}`
  );
};

export const addCompany = (data) => {
  return httpClient.post(server.COMPANY_URL, data);
};

export const deleteCompany = (id) => {
  return httpClient.delete(server.COMPANY_URL + `/${id}`);
};

export const updateCompany = (id, data) => {
  return httpClient.put(server.COMPANY_URL + `/${id}`, data);
};
