<template>
  <v-container>
    <v-row class="justify-center pt-5">
      <v-col cols="12">
        <v-card class="mx-auto pa-5" outlined>
          <v-card-title>{{ lg_department_edit.title }}</v-card-title>
          <v-divider></v-divider>
          <br />
          <v-form @submit.prevent="submit">
            <v-select
              v-validate="'required'"
              :items="mDataArray"
              :label="lg_department_edit.company_select"
              item-text="company_name"
              item-value="id"
              v-model="department.id_company"
              outlined
              @change="changeCompany"
            ></v-select>

            <v-text-field
              :label="lg_department_edit.department_id"
              v-model="department.id"
              disabled
            />

            <v-text-field
              outlined
              :label="lg_department_edit.department_name"
              v-model="department.department"
            ></v-text-field>
            <v-row>
              <v-spacer></v-spacer>
              <v-btn class="mr-4" @click.prevent="cancel">
                {{ lg_department_edit.btn_cancel }}
              </v-btn>

              <v-btn color="success" type="submit">
                {{ lg_department_edit.btn_save }}
              </v-btn>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <LoadingDialog></LoadingDialog>
    <!-- Alert Dialog -->
    <v-dialog v-model="alertDlg" max-width="60%">
      <v-card>
        <v-card-title class="headline">{{
          this.titleMessageAlert
        }}</v-card-title>

        <v-card-text class="body">
          {{ this.messageAlert }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            text
            @click="alertDlg = false"
            col="12"
            lg="12"
            md="12"
            sm="12"
          >
            Close
          </v-btn>

          <!-- <v-btn outlined color="error" text @click="confirmDelete">
            Confirm
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Alert Dialog -->
  </v-container>
</template>

<script>
import api from "@/services/api";
import LoadingDialog from "../../components/cards/LoadingDialog.vue";
import { server } from "../../services/constants";

import { feature } from "../../services/constants";
import { lg_department_edit } from "../language/lg_department";

export default {
  name: "department-edit",
  beforeCreate() {
    localStorage.getItem(feature.LANGUAGE) === "th"
      ? (this.lg_department_edit = lg_department_edit.department_edit_th)
      : (this.lg_department_edit = lg_department_edit.department_edit_en);

    // console.log("lg_department_edit: " + JSON.stringify(this.lg_department_edit));
  },
  data: () => ({
    alertDlg: false,
    titleMessageAlert: "",
    messageAlert: "",
    department: {
      id: 0,
      department: "",
      id_company: 0,
    },
    mDataArray: [],
  }),
  components: {
    LoadingDialog,
  },
  async mounted() {
    // get params id to show
    this.$store.state.loadMenu = true;
    this.department.id = this.$route.params.id;
    let result = await api.getAllDepartmentById(this.$route.params.id);
    this.department = result.data;
    this.department.id_company = parseInt(result.data.id_company);
    this.$store.state.loadMenu = false;
    this.loadCompany();
  },
  methods: {
    async changeCompany(selectObj) {
      // console.log(selectObj);
    },
    async loadCompany() {
      let result = null;
      // this.department.id_company = parseInt(
      //   localStorage.getItem(server.COMPANYID)
      // );
      // console.log("loadCompany : " + this.department.id_company);

      if (localStorage.getItem(server.ROLE) == "Admin") {
        result = await api.getCompanyNotGuest();
      } else {
        result = await api.getCompanyByID(this.department.id_company);
      }

      this.mDataArray = result.data;
      // console.log(JSON.stringify(result.data));
      // this.department.id_company = result.data.id;
      // console.log(this.department.id_company);
    },
    async submit() {
      // console.log(server.apikey_URL);
      // console.log(JSON.stringify(this.apikey));
      // alert(JSON.stringify(this.apikey));
      this.$store.state.loadMenu = true;

      this.$store.state.loadMenu = true;
      const result = await api.checkByNameDepartment(
        this.department.department,
        this.department.id_company
      );

      if (result.data != null) {
        // this.titleMessageAlert = "กรุณาตรวจสอบข้อมูล";
        // this.messageAlert =
        //   "Department นี้ถูกใช้งานแล้ว กรุณาเปลี่ยน Department ใหม่";
        this.titleMessageAlert = this.lg_department_edit.titleMessageAlert;
        this.messageAlert = this.lg_department_edit.messageAlert;
        this.alertDlg = true;
        this.$store.state.loadMenu = false;
      } else {
        await api.updateDepartment(this.department.id, this.department);
        this.$router.back();
      }
    },
    cancel() {
      this.$router.back();
    },
  },
};
</script>

<style></style>
