<template>
  <v-container>
    <v-row class="justify-center pt-5">
      <v-col cols="12">
        <v-card class="mx-auto pa-5" outlined>
          <v-card-title>{{ lg_apikey_edit.title }}</v-card-title>
          <v-divider></v-divider>
          <br />
          <v-form @submit.prevent="submit">
            <v-text-field
              :label="lg_apikey_edit.apikey_id"
              v-model="apikey.id"
              disabled
            />

            <v-text-field
              outlined
              :label="lg_apikey_edit.apikey_name"
              v-model="apikey.api_key_name"
            ></v-text-field>
            <v-row>
              <v-spacer></v-spacer>
              <v-btn class="mr-4" @click.prevent="cancel">
                {{ lg_apikey_edit.btn_cancel }}
              </v-btn>

              <v-btn color="success" type="submit">
                {{ lg_apikey_edit.btn_save }}
              </v-btn>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <LoadingDialog></LoadingDialog>
    <!-- Alert Dialog -->
    <v-dialog v-model="alertDlg" max-width="60%">
      <v-card>
        <v-card-title class="headline">{{
          this.titleMessageAlert
        }}</v-card-title>

        <v-card-text class="body">
          {{ this.messageAlert }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            text
            @click="alertDlg = false"
            col="12"
            lg="12"
            md="12"
            sm="12"
          >
            Close
          </v-btn>

          <!-- <v-btn outlined color="error" text @click="confirmDelete">
            Confirm
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Alert Dialog -->
  </v-container>
</template>
<script>
import api from "@/services/api";
import LoadingDialog from "../../components/cards/LoadingDialog.vue";

import { feature } from "../../services/constants";
import { lg_apikey_edit } from "../language/lg_apikey";

export default {
  name: "apikey-edit",
  beforeCreate() {
    localStorage.getItem(feature.LANGUAGE) === "th"
      ? (this.lg_apikey_edit = lg_apikey_edit.apikey_edit_th)
      : (this.lg_apikey_edit = lg_apikey_edit.apikey_edit_en);

    // console.log("lg_apikey_edit: " + JSON.stringify(this.lg_apikey_edit));
  },
  data: () => ({
    alertDlg: false,
    titleMessageAlert: "",
    messageAlert: "",
    apikey: { id: 0, api_key_name: "" },
  }),
  components: {
    LoadingDialog,
  },
  async mounted() {
    // get params id to show
    this.$store.state.loadMenu = true;
    this.apikey.id = this.$route.params.id;
    let result = await api.getApiKeyByID(this.$route.params.id);
    this.apikey = result.data;
    this.$store.state.loadMenu = false;
  },
  methods: {
    async submit() {
      // console.log(server.apikey_URL);
      // console.log(JSON.stringify(this.apikey));
      // alert(JSON.stringify(this.apikey));
      this.$store.state.loadMenu = true;
      const result = await api.checkNameForUpdateApiKey(
        this.apikey.id,
        this.apikey.api_key_name
      );
      if (result.data != null) {
        // this.titleMessageAlert = "กรุณาตรวจสอบข้อมูล";
        // this.messageAlert = "ApiKey นี้ถูกใช้งานแล้ว กรุณาเปลี่ยน ApiKey ใหม่";
        this.titleMessageAlert = this.lg_apikey_edit.titleMessageAlert;
        this.messageAlert = this.lg_apikey_edit.messageAlert;
        this.alertDlg = true;
        this.$store.state.loadMenu = false;
      } else {
        await api.updateApiKey(this.apikey.id, this.apikey);
        this.$router.back();
      }
    },
    cancel() {
      this.$router.back();
    },
  },
};
</script>

<style></style>
