export const lg_login = {
  login_th: {
    title: "เข้าสู่ระบบ Dmeets",
    email: "อีเมล",
    email_place: "exam@email.com",
    password: "พาสเวิด",
    password_place: "พาสเวิด",
    btn_register: "ลงทะเบียน",
    btn_forgot: "ลืมรหัสผ่าน ?",
    btn_login: "เข้าสู่ระบบ",
    messageAlert: "อีเมล หรือ พาสเวิด ไม่ถูกต้อง กรุณาตรวจสอบข้อมูล",
  },
  login_en: {
    title: "Login to Dmeets",
    email: "Email",
    email_place: "exam@email.com",
    password: "Password",
    password_place: "Password",
    btn_register: "Register",
    btn_forgot: "Forgot Password ?",
    btn_login: "Login",
    messageAlert:
      "Email or Password is incorrect, please check your information",
  },
};
