<template>
  <v-main>
  <v-container class="joinmeeting-container">
    <v-row class="ma-1" justify="center" >
      <v-col align="center" justify="center"  cols="12" xl="5" lg="5" md="6" sm="12" xs="12">
        <div>
          <h1 class="display-6 fw-bold lh-1 mb-3" v-bind:style="{ fontSize:'38px' }">
            {{this.lg_header_feature.pricingplans}}
          </h1>
          <br/>
            
          <div v-if='getlanguage === "th"'>
            <h2 align="left" v-bind:style="{ color: '#000000',fontWeight:'300 !important' }" class="font-weight-regular">
           {{this.lg_header_feature.details1}}
           <span v-bind:style="{ color: '#000000',fontWeight:'500 !important' }"> {{ this.lg_header_feature.subdetails11}} </span>
           <span > {{ this.lg_header_feature.subdetails12}} </span>
          </h2>
          <br/>

          <h2  align="left" v-bind:style="{ color: '#000000',fontWeight:'300 !important' }" class="font-weight-regular">
            <span v-bind:style="{ color: '#000000',fontWeight:'500 !important' }"> {{ this.lg_header_feature.details2}} </span>
           <span> {{ this.lg_header_feature.subdetails21}} </span>
           <span v-bind:style="{ color: '#000000',fontWeight:'500 !important' }"> {{ this.lg_header_feature.subdetails22}} </span>
           <span> {{ this.lg_header_feature.subdetails23}} </span>
           <span v-bind:style="{ color: '#000000',fontWeight:'500 !important' }"> {{ this.lg_header_feature.subdetails24}} </span>
           <span> {{ this.lg_header_feature.subdetails25}} </span>
           <span v-bind:style="{ color: '#000000',fontWeight:'500 !important' }"> {{ this.lg_header_feature.subdetails26}} </span>
           <span> {{ this.lg_header_feature.subdetails27}} </span>
          </h2>
          <br/>

          <h2 align="left" v-bind:style="{ color: '#000000',fontWeight:'300 !important' }" class="font-weight-regular">
           {{this.lg_header_feature.details3}}
           <span v-bind:style="{ color: '#000000',fontWeight:'500 !important' }"> {{ this.lg_header_feature.subdetails31}} </span>
           <span > {{ this.lg_header_feature.subdetails32}} </span>
           <span v-bind:style="{ color: '#000000',fontWeight:'500 !important' }"> {{ this.lg_header_feature.subdetails33}} </span>
           <span > {{ this.lg_header_feature.subdetails34}} </span>
          </h2>
          <br/>

          <h2 align="left" v-bind:style="{ color: '#000000',fontWeight:'300 !important' }" class="font-weight-regular">
           <span v-bind:style="{ color: '#000000',fontWeight:'500 !important' }"> {{ this.lg_header_feature.details4}} </span>
           <span > {{ this.lg_header_feature.subdetails41}} </span>
          </h2>
          <br/>
        </div>
        <div  v-else>
          <h2 align="left" v-bind:style="{ color: '#000000',fontWeight:'300 !important' }" class="font-weight-regular">
            {{this.lg_header_feature.details1}}
           <span v-bind:style="{ color: '#000000',fontWeight:'500 !important' }"> {{ this.lg_header_feature.subdetails11}} </span>
           <span > {{ this.lg_header_feature.subdetails12}} </span>
           <span v-bind:style="{ color: '#000000',fontWeight:'500 !important' }"> {{ this.lg_header_feature.subdetails13}} </span>
           <span > {{ this.lg_header_feature.subdetails14}} </span>
          </h2>
          <br/>

          <h2 align="left" v-bind:style="{ color: '#000000',fontWeight:'300 !important' }" class="font-weight-regular">
            {{this.lg_header_feature.details2}}
           <span v-bind:style="{ color: '#000000',fontWeight:'500 !important' }"> {{ this.lg_header_feature.subdetails21}} </span>
           <span> {{ this.lg_header_feature.subdetails22}} </span>
           <span v-bind:style="{ color: '#000000',fontWeight:'500 !important' }"> {{ this.lg_header_feature.subdetails23}} </span>
           <span> {{ this.lg_header_feature.subdetails24}} </span>
           <span v-bind:style="{ color: '#000000',fontWeight:'500 !important' }"> {{ this.lg_header_feature.subdetails25}} </span>
           <span> {{ this.lg_header_feature.subdetails26}} </span>
           <span v-bind:style="{ color: '#000000',fontWeight:'500 !important' }"> {{ this.lg_header_feature.subdetails27}} </span>
           <span> {{ this.lg_header_feature.subdetails28}} </span>
          </h2>
          <br/>

          <h2 align="left" v-bind:style="{ color: '#000000',fontWeight:'300 !important' }" class="font-weight-regular">
           {{this.lg_header_feature.details3}}
           <span v-bind:style="{ color: '#000000',fontWeight:'500 !important' }"> {{ this.lg_header_feature.subdetails31}} </span>
           <span > {{ this.lg_header_feature.subdetails32}} </span>
           <span v-bind:style="{ color: '#000000',fontWeight:'500 !important' }"> {{ this.lg_header_feature.subdetails33}} </span>
           <span > {{ this.lg_header_feature.subdetails34}} </span>
          </h2>
          <br/>

          <h2 align="left" v-bind:style="{ color: '#000000',fontWeight:'300 !important' }" class="font-weight-regular">
          <span v-bind:style="{ color: '#000000',fontWeight:'500 !important' }"> {{ this.lg_header_feature.details4}} </span>
          <span > {{ this.lg_header_feature.subdetails41}} </span>
          </h2>
          <br/>
        </div>
          <br/>
          <v-row v-bind:style="{ alignContent:'center',align:'left' }" class="ml-1 mt-4 d-flex justify-center align-center justify-md-start align-md-start"  >
          <v-btn
                class="mb-1 white--text"
                height="45"
                @click="onClickMenu('/contact')"
                v-bind:style="{ minWidth: 'auto', backgroundColor: '#01334D',marginRight: '10px' }"
              >
                <h2>{{ this.lg_header_feature.buttonenterprise }}</h2>
              </v-btn>
            </v-row>
          <br />
        </div>
      </v-col>
      <v-col align="center" justify="center" cols="12" xl="5" lg="5" md="6" sm="12" xs="12">
       <v-row align="center" justify="center">
            <v-img 
            :max-height="widthheightimage"
          :max-width="widthheightimage"
            align-center 
            justify-center
             src="../assets/feature.jpg" contain />
        
     </v-row>
      </v-col>
    </v-row>

    <v-row class="ma-1 homepricing_paragraph" justify="center" >
      <v-col align="center" justify="center"  cols="12" xl="5" lg="5" md="6" sm="12" xs="12">
        <div>
          <h1 class="display-6 fw-bold lh-1 mb-3" v-bind:style="{ fontSize:'38px' }">
            {{this.lg_header_feature.title_plan}}
          </h1>
          <br />
        </div>
      </v-col>
      <v-col align="center" justify="center" cols="12" xl="5" lg="5" md="6" sm="12" xs="12">
      </v-col>
    </v-row>

    <v-row class="ma-1 homepricing_paragraph" justify="center" >
      <v-col align="center" justify="center"  cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
        <div>
          <h1 class="display-6 fw-bold lh-1 mb-3" v-bind:style="{ fontSize:'30px' }">
            {{this.lg_header_feature.titlefreeplan}}
          </h1>

          <div v-if='getlanguage === "th"'>
            <h2 align="left" v-bind:style="{ color: '#000000',fontWeight:'300 !important' }" class="font-weight-regular">
          <span > {{ this.lg_header_feature.titlefreeplandetails1}} </span>
          <span v-bind:style="{ color: '#000000',fontWeight:'500 !important' }"> {{ this.lg_header_feature.titlefreeplandetails2}} </span>
          <span > {{ this.lg_header_feature.titlefreeplandetails3}} </span>
          </h2>
          <br/>
          </div>

          <div  v-else>
            <h2 align="left" v-bind:style="{ color: '#000000',fontWeight:'300 !important' }" class="font-weight-regular">
          <span > {{ this.lg_header_feature.titlefreeplandetails1}} </span>
          <span v-bind:style="{ color: '#000000',fontWeight:'500 !important' }"> {{ this.lg_header_feature.titlefreeplandetails2}} </span>
          <span > {{ this.lg_header_feature.titlefreeplandetails3}} </span>
          </h2>
          <br/>
          </div>

          <h2>
          <li align="left" v-bind:style="{ color: '#000000',fontWeight:'500 !important' }">{{ this.lg_header_feature.titlefreeplanoptions1 }}</li>
          <li align="left" v-bind:style="{ color: '#000000',fontWeight:'500 !important' }">{{ this.lg_header_feature.titlefreeplanoptions2 }}</li>
          <li align="left" v-bind:style="{ color: '#000000',fontWeight:'500 !important' }">{{ this.lg_header_feature.titlefreeplanoptions3 }}</li>
        </h2>

        </div>
      </v-col>
      <v-col align="center" justify="center" cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
        <div>
          <h1 class="display-6 fw-bold lh-1 mb-3" v-bind:style="{ fontSize:'30px' }">
            {{this.lg_header_feature.titleproplan}}
          </h1>

         <div v-if='getlanguage === "th"'>
            <h2 align="left" v-bind:style="{ color: '#000000',fontWeight:'300 !important' }" class="font-weight-regular">
          <span v-bind:style="{ color: '#000000',fontWeight:'500 !important' }"> {{ this.lg_header_feature.titleproplandetails1}} </span>
          <span > {{ this.lg_header_feature.titleproplandetails2}} </span>
          </h2>
          <br/>
          </div>

          <div  v-else>
            <h2 align="left" v-bind:style="{ color: '#000000',fontWeight:'300 !important' }" class="font-weight-regular">
          <span > {{ this.lg_header_feature.titleproplandetails1}} </span>
          <span v-bind:style="{ color: '#000000',fontWeight:'500 !important' }"> {{ this.lg_header_feature.titleproplandetails2}} </span>
          </h2>
          <br/>
          </div>

          <h2>
          <li align="left" v-bind:style="{ color: '#000000',fontWeight:'500 !important' }">{{ this.lg_header_feature.titleproplanoptions1 }}</li>
          <li align="left" v-bind:style="{ color: '#000000',fontWeight:'500 !important' }">{{ this.lg_header_feature.titleproplanoptions2 }}</li>
          <li align="left" v-bind:style="{ color: '#000000',fontWeight:'500 !important' }">{{ this.lg_header_feature.titleproplanoptions3 }}</li>
          <li align="left" v-bind:style="{ color: '#000000',fontWeight:'500 !important' }">{{ this.lg_header_feature.titleproplanoptions4 }}</li>
          <li align="left" v-bind:style="{ color: '#000000',fontWeight:'500 !important' }">{{ this.lg_header_feature.titleproplanoptions5 }}</li>
          <li align="left" v-bind:style="{ color: '#000000',fontWeight:'500 !important' }">{{ this.lg_header_feature.titleproplanoptions6 }}</li>
          <li align="left" v-bind:style="{ color: '#000000',fontWeight:'500 !important' }">{{ this.lg_header_feature.titleproplanoptions7 }}</li>
        </h2>
          
        </div>
      </v-col>
      <v-col align="center" justify="center" cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
        <div>
          <h1 class="display-6 fw-bold lh-1 mb-3" v-bind:style="{ fontSize:'30px' }">
            {{this.lg_header_feature.titlepricingplan}}
          </h1>

          <div v-if='getlanguage === "th"'>
            <h2 align="left" v-bind:style="{ color: '#000000',fontWeight:'300 !important' }" class="font-weight-regular">
              <span v-bind:style="{ color: '#000000',fontWeight:'500 !important' }"> {{ this.lg_header_feature.titlepricingplandetails1}} </span>
          <span > {{ this.lg_header_feature.titlepricingplandetails2}} </span>
          <span v-bind:style="{ color: '#000000',fontWeight:'500 !important' }"> {{ this.lg_header_feature.titlepricingplandetails3}} </span>
          <span > {{ this.lg_header_feature.titlepricingplandetails4}} </span>
          <span v-bind:style="{ color: '#000000',fontWeight:'500 !important' }"> {{ this.lg_header_feature.titlepricingplandetails5}} </span>
          </h2>
          <br/>
          </div>

          <div  v-else>
            <h2 align="left" v-bind:style="{ color: '#000000',fontWeight:'300 !important' }" class="font-weight-regular">
          <span > {{ this.lg_header_feature.titlepricingplandetails1}} </span>
          <span v-bind:style="{ color: '#000000',fontWeight:'500 !important' }"> {{ this.lg_header_feature.titlepricingplandetails2}} </span>
          <span > {{ this.lg_header_feature.titlepricingplandetails3}} </span>
          <span v-bind:style="{ color: '#000000',fontWeight:'500 !important' }"> {{ this.lg_header_feature.titlepricingplandetails4}} </span>
          <span > {{ this.lg_header_feature.titlepricingplandetails5}} </span>
          <span v-bind:style="{ color: '#000000',fontWeight:'500 !important' }"> {{ this.lg_header_feature.titlepricingplandetails6}} </span>
          <span > {{ this.lg_header_feature.titlepricingplandetails7}} </span>
          <span v-bind:style="{ color: '#000000',fontWeight:'500 !important' }"> {{ this.lg_header_feature.titlepricingplandetails8}} </span>
          </h2>
          <br/>
          </div>
         
          <v-row v-bind:style="{ alignContent:'center',align:'left' }" class="d-flex justify-center align-center justify-md-start align-md-start"  >
          <v-btn
                class="mb-1 white--text"
                height="45"
                @click="onClickMenu('/contact')"
                v-bind:style="{ minWidth: 'auto', backgroundColor: '#01334D',marginRight: '10px' }"
              >
                <h2>{{ this.lg_header_feature.buttonenterprise }}</h2>
              </v-btn>
            </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</v-main>
</template>

<script>
import { getByMeetNameOnly } from "@/services/api_meetroom";
import api from "@/services/api";
import { lg_header_feature } from "./language/lg_header_feature.js";
import { feature } from "../services/constants.js";

export default {
  data: () => ({
    form: {
      meetId: undefined,
    },
    getlanguage:"",
  }),
  methods: {
    isNumber(evt) {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    onClickMenu(link) {
      this.$router.push(link).catch((err) => {});
    },
    
  },
   computed: {
  buttonsmall() {
     if(this.$vuetify.breakpoint.name === "xs"){
       return true;
     }
      },
      widthheightimage(){
 switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "350";
        case "sm":
          return "450";
        case "md":
          return "450";
        case "lg":
          return "500";
        case "xl":
          return "500";
      }
      },
    widthbutton() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "40%";
        case "sm":
          return "25%";
        case "md":
          return "25%";
        case "lg":
          return "25%";
        case "xl":
          return "20%";
      }
    },
    },
   async beforeCreate() {
      this.$store.state.page_reload = false;
      // alert(localStorage.getItem("language"))
      // console.log("language login : " + localStorage.getItem(feature.LANGUAGE));
      localStorage.getItem(feature.LANGUAGE) === "th"
        ? this.lg_header_feature = lg_header_feature.lg_header_feature_th
        : this.lg_header_feature = lg_header_feature.lg_header_feature_en;

        // console.log("lg_login: " + JSON.stringify(this.lg_login));
    },
    async mounted(){
      this.getlanguage = localStorage.getItem(feature.LANGUAGE);
      const paragraphs = document.querySelectorAll(".homepricing_paragraph");
document.addEventListener("scroll", function(){
  paragraphs.forEach((paragraph)=>{
    if(isInView(paragraph)){
      paragraph.classList.add("homepricing_paragraph--visible")
    }
  })
});

function isInView(element){
const rect = element.getBoundingClientRect();
return(
  rect.bottom > 0 &&
  rect.top < (window.innerHeight -100  || document.documentElement.clientHeight - 100 )
)
}
    },
};
</script>

<style scoped>
.textinform{
  width:75%;
}
@media (max-width: 599px) {
  .joinmeeting-container {
    margin-top:-150px;
  }
  .v-main {
    margin-top:-50px;
    height: 110% !important;
  }
}
@media (max-width: 959px) {
  .joinmeeting-container {
    margin-top:-180px !important;
  }
}

@media (min-width: 1260px) {
  .joinmeeting-container {
    margin-top: 5rem;
  }
  
}

.v-main {
    max-width: 100%;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to right bottom, #b3c8ee, #b2c9f0, #b1c9f3, #b0caf5, #afcbf8) !important;
}
.joinmeeting-container {
    margin-top: -10px;
  }

  .homepricing_paragraph{
  transform: translateY(20px);
  opacity: 0;
  transition: 2s ease;
}
.homepricing_paragraph--visible{
  transform: translateY(0);
  opacity: 1;
}
</style>
