<template>
  <v-container>
    <v-card class="mt-8">
      <v-data-table
        :search="search"
        :headers="lg_department_list.headers"
        :items="mDataArray"
        :items-per-page="7"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>{{ lg_department_list.title }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field
              v-model="search"
              append-icon="search"
              :label="lg_department_list.department_search"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn
              @click="$router.push('/department-create')"
              color="primary"
              dark
              class="mb-2"
            >
              <v-icon left>add</v-icon>
              <span>{{ lg_department_list.department_add }}</span>
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:item="{ item }">
          <tr :class="{ primary: item.id === selectedDepartmentId }">
            <td @click="editItem(item)">{{ item.id }}</td>

            <td @click="editItem(item)">{{ item.department }}</td>
            <td @click="editItem(item)">{{ item.tbl_company.company_name }}</td>

            <td>
              <v-icon class="mr-2" @click="editItem(item)" color="green">
                edit
              </v-icon>
              <span class="ma-1"></span>
              <v-icon @click="deleteItem(item)" color="red"> delete </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>

      <v-dialog v-model="confirmDeleteDlg" width="unset">
        <v-card>
          <v-card-title class="headline">{{
            lg_department_list.dialog_title
          }}</v-card-title>

          <v-card-text class="body">
            {{ lg_department_list.dialog_delete }}
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="confirmDeleteDlg = false">
              {{ lg_department_list.dialog_cancel }}
            </v-btn>

            <v-btn color="error" text @click="confirmDelete">
              {{ lg_department_list.dialog_confirm }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <LoadingDialog></LoadingDialog>
    </v-card>
  </v-container>
</template>

<script>
import api from "@/services/api";
import LoadingDialog from "../../components/cards/LoadingDialog.vue";
import { server } from "../../services/constants";

import { feature } from "../../services/constants";
import { lg_department_list } from "../language/lg_department";

export default {
  name: "department-config",
  beforeCreate() {
    localStorage.getItem(feature.LANGUAGE) === "th"
      ? (this.lg_department_list = lg_department_list.department_list_th)
      : (this.lg_department_list = lg_department_list.department_list_en);

    // console.log("lg_department_list: " + JSON.stringify(this.lg_department_list));
  },
  async mounted() {
    this.$store.state.loadMenu = true;
    this.loadDepartment();
  },
  components: {
    LoadingDialog,
  },
  data() {
    return {
      search: "",
      selectedDepartmentId: "",
      confirmDeleteDlg: false,
      mDataArray: [],
    };
  },
  methods: {
    async loadDepartment() {
      // let department_id = localStorage.getItem(server.DEPARTMENT_ID);
      let company_id = localStorage.getItem(server.COMPANYID);
      let role = localStorage.getItem(server.ROLE);
      let result = null;

      if (role == "Admin") {
        result = await api.getAllDepartment();
      } else if (role == "Company Admin") {
        result = await api.getAllDepartmentByCompanyId(company_id);
      }

      // console.log(JSON.stringify(result.data));

      this.mDataArray = result.data;
      this.$store.state.loadMenu = false;
    },
    editItem(item) {
      this.$router.push(`/department-edit/${item.id}`);
      // alert(`/stock-edit/${item.id}`);
    },
    deleteItem(item) {
      this.selectedDepartmentId = item.id;
      this.confirmDeleteDlg = true;
      // alert(`/Department-delete/${this.selectedDepartmentId}`);
    },
    async confirmDelete() {
      this.confirmDeleteDlg = false;
      // this.$store.state.loadMenu = true;
      const resdel = await api.deleteDepartment(this.selectedDepartmentId);
      if(resdel.status == 200 || resdel.status == 201){
       await this.loadDepartment();
      }
    },
  },
};
</script>

<style></style>
