<template>
  <v-card :loading="loading" class="mx-auto my-6" max-width="600">
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-card-title>{{this.lg_footer_userrequest.title}}</v-card-title>

    <form ref="form" @submit.prevent="sendEmail">
      <label>{{this.lg_footer_userrequest.name}}</label>
      <input
        type="text"
        v-model="form.name"
        name="name"
        :placeholder="this.lg_footer_userrequest.placeholdername"
      />
      <label>{{this.lg_footer_userrequest.email}}</label>
      <input
        type="email"
        v-model="form.email"
        name="email"
        :placeholder="this.lg_footer_userrequest.placeholderemail"
      />
      <label>{{this.lg_footer_userrequest.phonenumber}}</label>
      <input
        type="text"
        v-model="form.phone"
        name="phone"
        :placeholder="this.lg_footer_userrequest.placeholderphonenumber"
      />
      <label>{{this.lg_footer_userrequest.subject}}</label>
      <input
        type="text"
        v-model="form.subject"
        name="subject"
        :placeholder="this.lg_footer_userrequest.placeholderphonesubject"
      />
      <label>{{this.lg_footer_userrequest.message}}</label>
      <textarea
        name="message"
        v-model="form.message"
        cols="30"
        rows="5"
        :placeholder="this.lg_footer_userrequest.placeholdermessage"
      >
      </textarea>
      <v-card-actions>
        <input type="submit" :value="this.lg_footer_userrequest.send" />
      </v-card-actions>
    </form>
  </v-card>
</template>

<script>
import api from "@/services/api";
import { feature } from "../services/constants";
import { lg_footer_userrequest } from "./language/lg_footer_userrequest.js";

export default {
  name: "ContactUs",
  data() {
    return {
      form: {
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
        email_to: "admin@dcm.co.th",
      },
    };
  },
  methods: {
    async sendEmail(e) {
      try {
        this.$swal.showLoading();
        const response = await api.onUserRequest(this.form);
        this.$swal.close();
        if (response && response.data && response.data.status === "success") {
          this.$swal.fire("Success", response.data.message, "success");
          // Reset form field
          this.form.name = "";
          this.form.email = "";
          this.form.phone = "";
          this.form.subject = "";
          this.form.message = "";
        } else {
          this.$swal.fire("Error", response.data.message, "error");
        }
      } catch (error) {
        console.log({ error });
        this.$swal.close();
        this.$swal.fire("Error", error.message, "error");
      }
    },
  },
  async beforeCreate() {
      this.$store.state.page_reload = false;

      // alert(localStorage.getItem("language"))
      // console.log("language login : " + localStorage.getItem(feature.LANGUAGE));

      localStorage.getItem(feature.LANGUAGE) === "th"
        ? this.lg_footer_userrequest = lg_footer_userrequest.lg_footer_userrequest_th
        : this.lg_footer_userrequest = lg_footer_userrequest.lg_footer_userrequest_en;

        // console.log("lg_login: " + JSON.stringify(this.lg_login));
    },
};
</script>
//  https://dashboard.emailjs.com/admin

<style scoped>
* {
  box-sizing: border-box;
  margin-left: 10px;
  margin-right: 30px;
}

/* .container {
  display: block;
  margin:auto;
  text-align: center;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 20px;

  width: 50%;
} */

label {
  float: left;
}

input[type="text"],
[type="email"],
textarea {
  width: 100%;
  padding: 12px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

input[type="submit"] {
  background-color: #4caf50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: #45a049;
}
</style>
