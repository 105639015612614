import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getPackageConfig = (acc_plan) => {
  return httpClient.get(server.PACKAGE_URL + `?group=${acc_plan}`);
};

const list = () => {
  return httpClient.get("package-configs");
};

const update = (id, body) => {
  return httpClient.patch(`package-configs/${id}`, body);
};

export default {
  list,
  update,
};
