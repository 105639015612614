export const lg_register = {
  register_th: {
    title: "ลงทะเบียน สำหรับกรรมการ / ผู้ถือหุ้น / ผู้เข้าร่วมประชุมทั่วไป",
    tg_check: "สมาชิก",
    tg_uncheck: "ผู้เยี่ยมชม",
    sl_company: "เลือกบริษัท",
    sl_department: "เลือกแผนก",
    txt_fname: "ชื่อ",
    txt_lname: "นามสกุล",
    email: "อีเมล",
    email_place: "exam@email.com",
    dtp_birthday: "วัน-เดือน-ปี (ค.ศ.) เกิด",
    txt_phone: "หมายเลขโทรศัพท์มือถือ",
    txt_company_number: "หมายเลขสมาชิก (ถ้ามี)",
    txt_password: "รหัสผ่าน",
    txt_password_confirm: "ยืนยันรหัสผ่าน",
    btn_cancel: "ยกเลิก",
    btn_register: "ลงทะเบียน",
    dialog_check_data: "กรุณาตรวจสอบข้อมูล",
    dialog_email_used: "Email นี้ถูกใช้งานแล้ว กรุณากรอก Email ใหม่",
    dialog_empty_email: "กรุณากรอกข้อมูล Email",
    dialog_empty_password: "กรุณากรอกข้อมูล Password",
    dialog_company_code:
      "หมายเลขสมาชิก ต้องมีความยาว 6 ตัวอักษร,ต้องมีตัวเลขอย่าน้อง 1 ตัวเลข และ ต้องมีตัวอักษรอย่าน้อง 1 ตัวอักษร",
    dialog_empty_password_confirm: "กรุณาตรวจสอบ Confirm Password",
    dialog_save: "ลงทะเบียนสำเร็จ",
    dialog_save_success: "บัญชีของคุณถูกสร้างเรียบร้อยแล้ว",
    dialog_save_fail: "บันทึกข้อมูลไม่สำเร็จ",
    messageAlert: "ไม่สามารถลงทะเบียนได้ โปรดลองภายหลัง",
    company_tooltip:
      "หมายเลขสมาชิก คือ รหัสประจำตัวที่ระบบได้ออกให้สำหรับ Admin ของบริษัทท่าน กรณีบริษัทท่านยังไม่ได้รับหมายเลขสมาชิก หรือต้องการสมัครใหม่ กรุณาติดต่อ admin@dcm.co.th",
    rules_commond: "บังคับกรอกข้อมูล",
    rules_password: "ต้องการรหัสผ่าน",
    rules_password2:
      "อักขระอย่างน้อยแปดตัว อักษรตัวพิมพ์ใหญ่อย่างน้อยหนึ่งตัว อักษรตัวพิมพ์เล็กหนึ่งตัว ตัวเลขหนึ่งตัว และอักขระพิเศษหนึ่งตัว",
    rules_password_confirm: "รหัสผ่านไม่ตรงกัน",
    rules_phone: "เบอร์โทรไม่ถูกต้อง ใส่ 10 หลักเท่านั้น",
    // rules_code:"หมายเลขสมาชิกไม่ถูกต้อง อย่างน้อย 6 ตัวอักษร อย่างน้อยหนึ่งตัวอักษรและหนึ่งตัวเลข",
    rules_code:"หมายเลขสมาชิกไม่ถูกต้อง อย่างน้อย 6 ตัวอักษร",
    rules_email: "กรุณากรอกอีเมลที่ถูกต้อง",
  },
  register_en: {
    title: "Register for Dmeets",
    tg_check: "Member",
    tg_uncheck: "Visitor",
    sl_company: "Select Company",
    sl_department: "Select Department",
    txt_fname: "First Name",
    txt_lname: "Last Name",
    email: "Email",
    email_place: "exam@email.com",
    dtp_birthday: "Birthday (dd-mm-yyyy)",
    txt_phone: "Phone Number",
    txt_company_number: "Membership Number (if applicable)",
    txt_password: "Password",
    txt_password_confirm: "Confirm Password",
    btn_cancel: "Cancel",
    btn_register: "Submit",
    dialog_check_data: "Please check your information",
    dialog_email_used: "Email is already used",
    dialog_empty_email: "Please enter email",
    dialog_empty_password: "Please enter password",
    dialog_company_code:
      "Membership Number must be at least 6 characters, must have at least 1 number and 1 letter",
    dialog_empty_password_confirm: "Please check confirm password",
    dialog_save: "Registration Successful",
    dialog_save_success: "Your account has been successfully created.",
    dialog_save_fail: "Save data fail",
    messageAlert:
      "Cannot submit form, please try again later.",
    company_tooltip:
      "Membership Number is an identification code issued by the system for the Admin of your company. In the event that your company has not received the Membership Number or want to apply again, please contact admin@dcm.co.th",
    rules_commond: "This is required",
    rules_password: "Password is required",
    rules_password2:
      "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character",
    rules_password_confirm: "Password is not match",
    rules_phone: "Phone number is invalid, enter 10 digits only",
    // rules_code: "Membership Number is invalid, Atleast 6 characters , at least one letter and one number",
    rules_code: "Membership Number is invalid, Atleast 6 characters",
    rules_email: "Please enter correct email",
  },
};
