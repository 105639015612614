import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const addLogHost = (data) => {
  return httpClient.post(server.LOG_HOST, data);
};

export const getByMeetId = (id) => {
  return httpClient.get(server.LOG_HOST + `/id/${id}`);
};

export const updateLogHost = (log_meet_id, data) => {
  return httpClient.put(server.LOG_HOST + `/${log_meet_id}`, data);
};

export const getAllLogHost = () => {
  return httpClient.get(server.LOG_HOST);
};

export const getLogRegisInfo = () => {
  return httpClient.get(`https://api.ipregistry.co/?key=estlawdbaztknzsz`);
};
