import Vue from "vue";
// import "./zoombootstrap.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { imageUrl } from "@/services/constants";
import "material-design-icons-iconfont/dist/material-design-icons.css";
// import '@zoom/videosdk-ui-toolkit/dist/videosdk-ui-toolkit.css'
import Vue2Filters from "vue2-filters";
import ToggleButton from "vue-js-toggle-button";
import LoadScript from "vue-plugin-load-script";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import firebase from "firebase/app";
import { format, isValid, parse } from "date-fns";
import VueClipboard from "vue-clipboard2";
import SwitchLang from "vue-switch-lang";
import CountryFlag from "vue-country-flag";
import VueCookies from "vue-cookies";
import VTooltip from "v-tooltip";
import VueHtml2pdf from 'vue-html2pdf'

// import "firebase/firestore";
// import "firebase/auth";


import "./main.scss";

import {defineCustomElements} from "@dytesdk/ui-kit/loader";


// const firebaseConfig = {
//   apiKey: "AIzaSyAE62vxvMXTtlfbxjZAFzIgpnKVfm6WPkQ",
//   authDomain: "dmeets-14cc7.firebaseapp.com",
//   projectId: "dmeets-14cc7",
//   storageBucket: "dmeets-14cc7.appspot.com",
//   messagingSenderId: "601473842072",
//   appId: "1:601473842072:web:91ba24bdd0810a288689ed",
// };
// Initialize Firebase
// firebase.initializeApp(firebaseConfig);

Vue.config.productionTip = false;
Vue.use(LoadScript);
Vue.use(VueSweetalert2);
VueClipboard.config.autoSetContainer = true; // add this line
Vue.use(VueClipboard);
Vue.use(SwitchLang);
Vue.component("SwitchLang");
Vue.component("country-flag", CountryFlag);
Vue.use(VueCookies);
Vue.use(VTooltip);
Vue.use(VueHtml2pdf)
// set global cookie  1= 1day / +7 is timezone
defineCustomElements();

// let day = 0*24;
// const setdateNow = new Date(new Date().setHours(new Date().getHours() +(day+7) )).toISOString().replace("T"," ").substr(0,19);
// Vue.$cookies.config(setdateNow)
// Vue.$cookies.set('sessionpopup',{name:"checkclickpopup",expired:setdateNow},setdateNow);
// Vue.$cookies.set('hover-time','1s');
Vue.config.productionTip = false
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

Vue.filter("dateFormat", function (value) {
  if (!value) return "-";

  const date = value.toDate();
  return isValid(date) ? format(date, "dd/MM/yyyy") : "-";
});

Vue.use(Vue2Filters);
Vue.use(ToggleButton);
Vue.filter("imageUrl", function (image) {
  return `${imageUrl}/${image}?timestamp=${Date.now()}`;
});
