export const lg_apikey_list = {
  apikey_list_th: {
    title: "API Key",
    apikey_search: "ค้นหา API Key",
    apikey_add: "เพิ่ม API Key",
    dialog_title: "ยืนยันการลบ",
    dialog_delete: "คุณแน่ใจหรือว่าต้องการลบ API Key นี้ ? คุณไม่สามารถกู้คืนได้หลังจาก คลิกยืนยัน",
    dialog_confirm: "ยืนยัน",
    dialog_cancel: "ยกเลิก",
    headers: [
      { text: "รหัส API Key", value: "id" },
      { text: "ชื่อ API Key", value: "api_key_name" },
      { text: "Action", value: "action" },
    ],
  },
  apikey_list_en: {
    title: "API Key",
    apikey_search: "Search API Key",
    apikey_add: "Add API Key",
    dialog_title: "Confirm Delete",
    dialog_delete: "Are you sure you want to delete this API Key ? You can't restore it after clicking Confirm",
    dialog_confirm: "Confirm",
    dialog_cancel: "Cancel",
    headers: [
      { text: "API Key Id", value: "id" },
      { text: "API Key Name", value: "api_key_name" },
      { text: "Action", value: "action" },
    ],
  },
};

export const lg_apikey_add = {
  apikey_add_th: {
    title: "เพิ่มข้อมูล การตั้งค่า  API Key",
    apikey_name: "ชื่อ API Key",
    btn_save: "บันทึก",
    btn_cancel: "ยกเลิก",
    titleMessageAlert: "กรุณาตรวจสอบข้อมูล",
    messageAlert: "ชื่อ API Key นี้ถูกใช้งานแล้ว กรุณาเปลี่ยนชื่อ API Key ใหม่",
  },
  apikey_add_en: {
    title: "Add API Key",
    apikey_name: "API Key Name",
    btn_save: "Save",
    btn_cancel: "Cancel",
    titleMessageAlert: "Please check your information",
    messageAlert: "This API Key Name is already in used. Please change the API Key Name",
  },
};

export const lg_apikey_edit = {
  apikey_edit_th: {
    title: "แก้ไขข้อมูล การตั้งค่า  API Key",
    apikey_id: "รหัส API Key",
    apikey_name: "ชื่อ API Key",
    btn_save: "บันทึก",
    btn_cancel: "ยกเลิก",
    titleMessageAlert: "กรุณาตรวจสอบข้อมูล",
    messageAlert: "ชื่อ API Key นี้ถูกใช้งานแล้ว กรุณาเปลี่ยนชื่อ API Key ใหม่",
  },
  apikey_edit_en: {
    title: "Edit API Key",
    apikey_id: "API Key ID",
    apikey_name: "API Key Name",
    btn_save: "Save",
    btn_cancel: "Cancel",
    titleMessageAlert: "Please check your information",
    messageAlert: "This API Key name is already used. Please change API Key Name",
  },
};
