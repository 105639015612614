<template>

  <v-app-bar app v-resize="onResize"  :height="header_height"  dark fixed >
     <template v-if="alertnavigate">
         
  <v-app-bar app class="mt-0" height="50"  color="#ffffff" dark fixed >

  <v-menu v-if="$vuetify.breakpoint.xsOnly" >

        <template v-slot:activator="{ on }">
          
         <v-btn  icon  @click="closealertnavigate">
              <v-icon  class="mx-2" color="black">mdi-window-close</v-icon>
            </v-btn>
        
        </template>
      </v-menu>
      <v-row  align="center" >
     
     
   <v-col cols="2"  align="center">
        <div v-if="!$store.getters['isLogin']">
          <v-img
            src="../../assets/logo.png"
            max-height="40"
            max-width="40"
            @click="onClickMenu('/')"
           
          ></v-img>
        </div>
      
         </v-col>
      

  
   
 <v-col cols="7" align="center" class="text-left mt-4 ">
  
 
    <h4 class="black--text ">Dmeets {{this.showOSName}}</h4>
    <p class="black--text  ">Better experience with our app</p>

  </v-col>
   <v-col cols="3"  align="center">
  <template >
    <a :href="linkstore" target="_blank" class="black--text ">
      Download
    </a>
  </template >
 </v-col>
     <!-- <v-toolbar-title  v-if="!$vuetify.breakpoint.xsOnly">Toolbar Mobile Menu</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn text to="create">
          <span class="mr-2" v-if="activeUser">Create Post</span>
        </v-btn>
        <v-btn text to="/">
          <span class="mr-2" v-if="activeUser">All Posts</span>
        </v-btn>
      </v-toolbar-items> -->

     </v-row>
    </v-app-bar>
      </template>
 
      
      <v-row  align="center" :class="heightrowmenu" >
      <v-col align="center"  cols="4" xl="7" lg="7" md="8" sm="12" xs="4" v-bind:style="{ padding: '0' }">
      <v-row align="center">
      <v-col align="center" cols="12"  xl="2" lg="2" md="2" sm="12" xs="6">
     
         <!--
         <v-menu v-if="$vuetify.breakpoint.xsOnly" >

        <template v-slot:activator="{ on }">
          <v-btn  @click="$store.state.navMenuMainpage = !$store.state.navMenuMainpage" icon >
            <v-icon >mdi-reorder-horizontal</v-icon>
          </v-btn>
        </template>
        </v-menu>
        -->
    
      <v-row  class="justify-center"  align="center">
       <template  v-if="$vuetify.breakpoint.xsOnly" >
        <v-btn  @click="$store.state.navMenuMainpage = !$store.state.navMenuMainpage" icon >
            <v-icon >mdi-reorder-horizontal</v-icon>
          </v-btn>
           </template>
        <template  v-if="!$store.getters['isLogin']">
       
          <v-img
            src="../../assets/logo.png"
            max-height="60"
            max-width="60"
            @click="onClickMenu('/')"
           
          ></v-img>
        </template>
        </v-row>
         </v-col>
          <v-col v-if="!$vuetify.breakpoint.xsOnly" :align="menualign"  cols="12"  xl="10" lg="10" md="10" xs="10">
        <template >
 <v-btn text dark @click="onClickMenu('/')">
              <h3 v-if="!$store.getters['isLogin']">{{this.lg_header_menu.home}}</h3>
              <h4 v-if="$store.getters['isLogin']">{{this.lg_header_menu.home}}</h4>
            </v-btn>
            <v-btn text dark @click="onClickMenu('/feature')">
              <h3 v-if="!$store.getters['isLogin']">{{this.lg_header_menu.feature}}</h3>
              <h4 v-if="$store.getters['isLogin']">{{this.lg_header_menu.feature}}</h4>
            </v-btn>
            <v-btn text dark @click="onClickMenu('/contact')">
              <h3 v-if="!$store.getters['isLogin']">{{this.lg_header_menu.contact}}</h3>
              <h4 v-if="$store.getters['isLogin']">{{this.lg_header_menu.contact}}</h4>
            </v-btn>
            <v-btn  light @click="onClickMenu('/joinmeetid')" color="#cbe9f8" v-bind:style="{ color: 'black' }" >
              <h3 v-if="!$store.getters['isLogin']">{{this.lg_header_menu.joinmeetbyid}}</h3>
              <h4 v-if="$store.getters['isLogin']">{{this.lg_header_menu.joinmeetbyid}}</h4>
            </v-btn>
             </template>
              </v-col>
               </v-row>
         </v-col>

  <v-col align="center" cols="8"  xl="5" lg="5" md="4" sm="12"  xs="10" >
   <v-row align="center" >
   <v-col align="center"  xl="12" lg="12" md="12" sm="12"  xs="12">
    <template>
      <v-menu>
      <template v-slot:activator="{ attrs, on }">
        <!--  :x-small="!buttonsizesmall" -->
        <v-btn
        class="positionflag"
              text
          v-bind="attrs"
          v-on="on"
          v-bind:style="{ paddingLeft: '0', marginRight: '0.5rem' }"
        >
           <country-flag :country="defaultlanguage" size="normal" />
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="(item,idx) in chooselanguage"
          :key="idx"
          link
           @change="onChange(item.flag)"
        >
           <country-flag :country="item.flag" size="normal" />
        </v-list-item>
      </v-list>
    </v-menu>
          
          <v-btn
              outlined
              dark
               height="30"
              :x-small="!buttonsizesmall"
              v-if="!$store.getters['isLogin']"
              @click="onClickMenu('/login-account')"
               v-bind:style="{ marginRight: '0.5rem' }"
            >
              <h3>{{this.lg_header_menu.signin}}</h3>
            </v-btn>
           
            <v-btn
              color="#ffffff"
              light
              height="30"
              :x-small="!buttonsizesmall"
               class="ml-1"
              v-if="!$store.getters['isLogin']"
              @click="onClickMenu('/register-account')"
            >
              <h3 v-bind:style="{ color: '#205295' }">{{this.lg_header_menu.registerformeeting}}</h3>
            </v-btn>
             <v-btn
              v-if="$store.getters['isLogin']"
              icon
              @click.prevent="onClickLogOff"
            >
              <v-icon>mdi-export</v-icon>
            </v-btn>
              </template >
      </v-col>
             </v-row>
  </v-col>
     <!-- <v-toolbar-title  v-if="!$vuetify.breakpoint.xsOnly">Toolbar Mobile Menu</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn text to="create">
          <span class="mr-2" v-if="activeUser">Create Post</span>
        </v-btn>
        <v-btn text to="/">
          <span class="mr-2" v-if="activeUser">All Posts</span>
        </v-btn>
      </v-toolbar-items> -->

     </v-row>
    </v-app-bar>
    
</template>

<script>
import api from "@/services/api";
import { feature } from "../../services/constants";
import SwitchLang from "vue-switch-lang";
import {lg_header_menu} from '../../views/language/lg_header_menu.js';

export default {
  name: "HeaderResponsive",
  data: () => ({
    linkstore:"",
    showOSName:"Unknown OS",
    clickclosenavigate:false,
    alertnavigate:true,
    heightrowmenu:"",
    buttonsizesmall:false,
    iconalign:"left",
    menualign:"left",
    buttonlign:"left",
    defaultlanguage: "",
      // regexlang: "th",
      chooselanguage: [
        { name: "en", flag: "gb" },
        { name: "th", flag: "th" },
      ],
    drawer: false,
    text_fontsize: 30,
    dialog: false,
    mLoc: "center",
    mLoc2: "center",
    header_rotate: "",
    header_mt: "",
    acc_plan: "",
    date_exire: 0,
    role: "",
    id: "",
    menu: [
      { icon: "home", title: "หน้าหลัก" },
      { icon: "info", title: "ฟีเจอร์" },
      { icon: "info", title: "ติดต่อเรา" },
    ],
    initLang: {
      title: "en",
      flag: "gb",
    },
    options: [
      {
        title: "en",
        flag: "gb",
      },
      {
        title: "th",
        flag: "th",
      },
    ],
  }),
  components: {
    SwitchLang
  },
  async mounted() {
   
     this.onResize()
    this.$forceUpdate();
    // this.$router.go(); // Refreshes page

    this.acc_plan = localStorage.getItem("acc_plan");
    this.role = localStorage.getItem("role");
    this.id = localStorage.getItem("user_id");

    // alert(this.id);
    // alert(this.acc_plan);
    // alert(this.role);

    const res = await api.getDateExpire(this.id);

    this.date_exire = res.data.value_expire;
    // alert(this.date_exire);
    // console.log("date_exire : " + this.date_exire);
  },
  computed: {
    version() {
      return process.env.VUE_APP_VERSION;
    },
    title() {
      return process.env.VUE_APP_TITLE;
    },
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 70;
        case "sm":
          return 70;
        case "md":
          return 60;
        case "lg":
          return 70;
        case "xl":
          return 80;
      }
    },
    header_height() {
      if(this.clickclosenavigate == true){
         this.heightrowmenu = "";
switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 70;
        case "sm":
          return 220;
        case "md":
          return 70;
        case "lg":
          return 70;
        case "xl":
          return 70;
           }
      }else{

     
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 140;
        case "sm":
          return 220;
        case "md":
          return 70;
        case "lg":
          return 70;
        case "xl":
          return 70;
           }
      }
    },
  },
  methods: {
    
    onChange(lang) {
      // Do something
      // alert(lang.title);
      this.defaultlanguage = lang;
      localStorage.setItem(feature.LANGUAGE, lang);
      // alert(localStorage.getItem(feature.LANGUAGE));
      // console.log("language header : " + localStorage.getItem(feature.LANGUAGE));
      this.$router.go();
    },
    onResize: function () {
      
      if (this.$vuetify.breakpoint.xsOnly) {
       
        this.iconalign="left";
        this.buttonsizesmall = false;
        if(this.clickclosenavigate !== true){
           this.heightrowmenu = "mt-10";
 this.alertnavigate = true;
        }else{
           this.alertnavigate = false;
        }
      
      }else if(this.$vuetify.breakpoint.smOnly){
          this.alertnavigate = false;
        this.heightrowmenu = "";
         this.menualign="center";
         this.buttonlign="center";
         this.buttonsizesmall = true;
      }
      
       else {
          this.alertnavigate = false;
          this.heightrowmenu = "";
        // this.iconalign="center";
         this.menualign="left";
          this.buttonlign="left";
            this.buttonsizesmall = true;
      }
    },
    closealertnavigate(){
      this.clickclosenavigate=true;
   this.alertnavigate = false;
    },
    onClickLogOff() {
      this.$store.dispatch("doLogout");
      // alert("Test");
    },
    onClickMenu(link) {
      this.$store.state.navMenu = false;
      this.$router.push(link).catch((err) => {});
    },
  
    
  },
   async beforeCreate() {

      this.$store.state.page_reload = false;
      this.defaultlanguage =  localStorage.getItem(feature.LANGUAGE);
      // alert(localStorage.getItem("language"))
      // console.log("language login : " + localStorage.getItem(feature.LANGUAGE));

      localStorage.getItem(feature.LANGUAGE) === "th"
        ? this.lg_header_menu = lg_header_menu.lg_header_menu_th
        : this.lg_header_menu = lg_header_menu.lg_header_menu_en;

        // console.log("lg_login: " + JSON.stringify(this.lg_login));
    },
    
  async beforeUpdate(){
 this.onResize()
  },
     async created() {
        if (navigator.userAgent.indexOf("Win") != -1) this.showOSName = "Windows";
  if (navigator.userAgent.indexOf("Mac") != -1) this.showOSName = "Macintosh";
  if (navigator.userAgent.indexOf("Linux") != -1) this.showOSName = "Linux";
  if (navigator.userAgent.indexOf("Android") != -1){
   this.showOSName = "Android";}
  //  https://play.google.com/store/apps/details?id=app.dcm.dmeet&fbclid=IwAR0KEttfME6UpeDSpoV8C_udhLktb1DNou1DcC1dsUuceCgGfBKypSmRgwc
   this.linkstore = "https://play.google.com/store/apps/details?id=com.dcm.dmeetszoom.dcm_dmeetszoom"; 
  if (navigator.userAgent.indexOf("like Mac") != -1){ 
  this.showOSName = "iOS"
  this.linkstore = "https://apps.apple.com/th/app/dmeets/id6479974253"
  };


      this.$store.state.page_reload = false;
  this.defaultlanguage =  localStorage.getItem(feature.LANGUAGE);
  this.onResize()
    },
 
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
.v-toolbar__content{
  height: 180px;
}
.v-application p {
    font-size: 8px;
}
}
.theme--dark.v-app-bar.v-toolbar.v-sheet {
    background-color: #205295;
}

/* .v-application .positionflag{
    background-color: unset;
    margin-bottom: 0.5rem;
    margin-right: 1rem;
    height: unset;
    box-shadow: none;
} */

.v-application .positionflag{
    padding-bottom: 5px;
    padding-right: 0px;
}
</style>
