export const lg_vote_report = {
  vote_report_th: {
    title: "รายงานผลโหวต",
    search_conditions: "เงื่อนไขในการค้นหา",
    search_user: "หัวข้อโหวต",
    search_topic: "ชื่อการประชุม",
    search_date: "วันที่",
    headers: [
      { text: "ลำดับ", value: "id" },
      { text: "วันที่สร้าง", value: "created_at", align: "center" },
      { text: "ชื่อการประชุม", value: "meet_topic" },
      { text: "หัวข้อโหวต", value: "question" },
      { text: "ตัวเลือก", value: "options", sortable: false },
      { text: "จำนวนผู้ลงคะแนน", value: "voted", sortable: false },
      { text: "Report", value: "action", sortable: false },
    ],
  },
  vote_report_en: {
    title: "Vote Report",
    search_conditions: "Search Conditions",
    search_user: "Vote Question",
    search_topic: "Meeting Name",
    search_date: "Date",
    headers: [
      { text: "No.", value: "id" },
      { text: "Date", value: "created_at", align: "center" },
      { text: "Meeting Name", value: "meet_topic" },
      { text: "Vote Question", value: "question" },
      { text: "Vote Options", value: "options", sortable: false },
      { text: "Vote Count", value: "voted", sortable: false },
      { text: "Report", value: "action", sortable: false },
    ],
  },
};
