<template lang="html">
  <v-container fill-height fluid>
    <v-row >
      <v-card :style="widthcard">
        <v-card-title >{{this.lg_forgotpassword.title}}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form @submit.prevent="submit" v-model="valid" ref="form">
            <!-- Username field -->
            <v-text-field
              :label="this.lg_forgotpassword.label"
              :placeholder="this.lg_forgotpassword.placeholder"
              v-model="account.username"
              required
              outlined
              dense
            />


            <v-alert
              v-model="$store.getters['altlogin']"
              dense
              outlined
              type="error"
            >
              {{ messageAlert }}
            </v-alert>

            <v-divider></v-divider>
            <!-- Buttons justify-space-between  -->
            <v-layout>
              <v-btn
              :small="buttonsmall"
                outlined 
                text 
                @click.prevent="$router.push('/login-account')"
                >{{this.lg_forgotpassword.backbutton}}</v-btn>

                <v-btn
                :small="buttonsmall"
                  class="ml-5"
                  type="submit"
                  :class="{
                    'primary white--text': valid,
                    disabled: !valid,
                  }"
                  >{{this.lg_forgotpassword.send}}</v-btn
                >
            </v-layout>
          </v-form>
        </v-card-text>
      </v-card>
    </v-row>

    <LoadingDialog></LoadingDialog>

  </v-container>
</template>

<script lang="js">

import api from "@/services/api";
import LoadingDialog from "@/components/cards/LoadingDialog";
import { server } from "../services/constants";
import { feature } from "../services/constants";
import { lg_forgotpassword } from "./language/lg_forgotpassword.js";

  export default  {
    name: 'forgot',
    props: [],
    created() {
    },
    components: {
      LoadingDialog,
    },
    mounted () {
      localStorage.setItem(server.MEET_STA, "false");
      if (api.isLoggedIn()){
        this.$router.push("/main-page")
      }
    },
    data () {
          return {
            messageAlert: "Email หรือ Password ไม่ถูกต้อง กรุณาตรวจสอบข้อมูล",
            account:{
              username:""
            },
            valid: false,
            e1: true,
            email: '',
            emailRules: [
              (v) => !!v || 'E-mail is required!!!',
            ],
          }
        },
        methods: {
          async submit () {
            this.$swal.showLoading();
            if (this.$refs.form.validate()) {
                 try {
                const username = this.account.username
                const body = {username}
                const res = await api.forgot(body)
                this.$swal.close();
                if(res.data && res.data.link) {
                    this.$swal.fire("Success", res.data.message, "success");
                    this.$router.push("/main-page")
                } else {
                   this.$swal.fire("Error", res.data.message, "error");
                }
              }
              catch (error) {
                console.log(error);
                this.$swal.fire("Error", error.message, "error");
              }
            }
          },
          clear () {
            this.$refs.form.reset()
          }
        },
    computed: {
       widthcard() {
      switch (this.$vuetify.breakpoint.name) {
         case "xs":
          return "width:80%";
        case "sm":
            return "width:50%";
        case "md":
           return "width:40%";
        case "lg":
           return "width:30%";
        case "xl":
            return "width:30%";
      }
    },
 buttonsmall() {
     if(this.$vuetify.breakpoint.name === "xs"){
       return true;
     }
      },
    },
     beforeCreate() {
       window.scrollTo( screen.width/2, screen.height/2 );
      this.$store.state.page_reload = false;
      this.$store.state.altLogin = false;

      // alert(localStorage.getItem("language"))
      // console.log("language login : " + localStorage.getItem(feature.LANGUAGE));

      localStorage.getItem(feature.LANGUAGE) === "th"
        ? this.lg_forgotpassword = lg_forgotpassword.lg_forgotpassword_th
        : this.lg_forgotpassword = lg_forgotpassword.lg_forgotpassword_en;

        // console.log("lg_login: " + JSON.stringify(this.lg_login));
    },
}
</script>

<style scoped lang="scss">
.buttons {
  display: flex;
  justify-content: flex-end;
}
.v-progress-circular {
  margin: 1rem;
}
.row {
    padding-top: 40px ;
    padding-bottom: 40px ;
    align-items: center;
    justify-content: center ;
} 


.v-card__title {
    justify-content: center;
}
.layout{ 
  margin-top: 20px ;
  justify-content: center;
}
.fill-height {
    height: 100%;
}
.v-text-field.v-text-field--enclosed {
    margin-right: 25px;
    margin-left: 25px;
}
</style>
