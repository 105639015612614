<template>
  <v-dialog v-model="visible" width="500" scrollable persistent>
    <form @submit.prevent="onSubmit">
      <v-card>
        <v-card-text class="py-5" v-if="question">
          <p class="question-text mb-3">{{ question.question }}</p>
          <div v-if="question.isMultiple">
            <v-checkbox
              v-for="(option, i) in question.options"
              :key="`option-${i}`"
              v-model="selecteds"
              :label="option.value"
              :value="option.id"
              dense
            ></v-checkbox>
          </div>
          <div v-else>
            <v-radio-group v-model="selected">
              <v-radio
                v-for="(option, i) in question.options"
                :key="`option-${i}`"
                :label="option.value"
                :value="option.id"
                class="mb-3"
                required
              ></v-radio>
            </v-radio-group>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text type="submit">
            Save
          </v-btn>
          <v-btn color="red" text @click="onClose">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </v-dialog>
</template>

<script>
import firebase from "firebase/app";
import uuid from "short-uuid";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    roomId: {
      type: String
    },
    userId: {
      type: String
    },
    user: {
      type: String
    },
    questions: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    question() {
      return this.questions.length > 0 ? this.questions[0] : null;
    }
  },
  data: () => ({
    selected: null,
    selecteds: [],
    form: {
      question: null,
      options: [{ value: null, id: uuid().new() }],
      isMultiple: false
    }
  }),
  methods: {
    onClose() {
      this.$emit("close", this.question);
    },
    addOption() {
      this.form = {
        ...this.form,
        options: [...this.form.options, { value: null, id: uuid().new() }]
      };
    },
    async onSubmit() {
      try {
        const { userId, roomId, question, selected, selecteds } = this;
        this.$swal.showLoading();
        await firebase
          .firestore()
          .collection("vote-report")
          .add({
            userId,
            roomId,
            questionId: question.id,
            answer: selected,
            answers: selecteds
          });
        this.$swal.close();
        this.onClose();
      } catch (error) {
        this.$swal.close();
        this.$swal.fire("Error", error.message, "error");
      }
    },
    removeOption(id) {
      this.form = {
        ...this.form,
        options: this.form.options.filter((o) => o.id !== id)
      };
    }
  },
  created() {
    this.$swal.close();
  }
};
</script>

<style lang="scss">
.question-text {
  font-size: 20px;
}
</style>
