<template>
  <v-dialog v-model="visible" width="800" scrollable persistent>
    <v-card>
      <v-card-text class="py-5" v-if="vote">
        <p class="question-text mb-3">{{ vote.question }}</p>

        <v-tabs v-model="tab">
          <v-tabs-slider></v-tabs-slider>

          <v-tab href="#report">
            Report
          </v-tab>

          <v-tab href="#status" v-if="!hideStatus">
            Vote status
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item value="report">
            <div class="py-5" v-if="chartData">
              <bar-chart :chartData="chartData" />
            </div>
          </v-tab-item>
          <v-tab-item value="status" v-if="!hideStatus">
            <v-simple-table fixed-header v-if="members">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Name
                    </th>
                    <th class="text-left">
                      Role
                    </th>
                    <th class="text-left">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(member, index) in members"
                    :key="`member-${index}`"
                  >
                    <td width="300">{{ member.displayname }}</td>
                    <td width="100">
                      {{ member.moderator ? "Moderator" : "Member" }}
                    </td>
                    <td width="100">
                      <v-icon v-if="member.answer" color="green"
                        >mdi-check-circle</v-icon
                      >
                      <v-icon v-else color="grey">mdi-check-circle</v-icon>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="sendReport" v-if="!hideSendButton">
          Send report to user
        </v-btn>
        <v-btn color="red" text @click="onClose">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import firebase from "firebase/app";

import BarChart from "../charts/HorizontalBarChart.vue";
export default {
  components: {
    BarChart
  },
  props: {
    hideSendButton: {
      type: Boolean,
      default: false
    },
    hideStatus: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: false
    },
    vote: {
      type: Object,
      default: () => null
    },
    roomId: {
      type: String
    },
    userId: {
      type: String
    }
  },
  computed: {
    members() {
      const { vote } = this;
      if (!vote) return [];
      const { reports } = vote;
      return reports;
    },
    chartData() {
      const { vote } = this;
      if (!vote) return null;
      const { options, reports, isMultiple } = vote;
      let labels = [];
      let values = [];
      let colors = [];
      for (const option of options) {
        const { id } = option;
        labels.push(option.value);
        colors.push("#078fc3");
        if (isMultiple) {
          const validReports = reports.filter((r) => r.answers?.includes(id));
          values.push(validReports.length);
        } else {
          const validReports = reports.filter((r) => r.answer === id);
          values.push(validReports.length);
        }
      }
      return {
        labels: labels,
        datasets: [
          {
            data: values,
            backgroundColor: colors
          }
        ]
      };
    }
  },
  data: () => ({
    tab: null
  }),
  methods: {
    async sendReport() {
      await firebase
        .firestore()
        .collection(`events`)
        .doc(this.roomId)
        .set({
          roomId: this.roomId,
          type: "SHOW_REPORT",
          createdDate: firebase.firestore.FieldValue.serverTimestamp(),
          vote: this.vote,
          userId: this.userId
        });
    },
    onClose() {
      this.$emit("close");
    }
  },
  created() {
    this.$swal.close();
  }
};
</script>

<style lang="scss">
.question-text {
  font-size: 20px;
}
</style>
