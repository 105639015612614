<template>
  <v-container>
     <v-row class="justify-center align-end pt-10 pb-10">
    <v-card class="mt-5 ml-5 mr-5" :style="widthcard">
      <v-card-title primary-title>
        {{ lg_account_detail_edit.title }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form @submit.prevent="submit">
          <v-text-field
            :label="lg_account_detail_edit.txt_name"
            v-model="account.acc_fullname"
            outlined
          />
          <v-text-field
            label="เลขบัตรประจำตัวประชาชน / Passport"
            v-model="account.acc_id_card"
            outlined
            v-if="false"
          />
          <v-text-field
            :label="lg_account_detail_edit.txt_email"
            v-model="account.username"
            disabled
            outlined
          />

          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateFormatted"
                :label="lg_account_detail_edit.txt_birthday"
                persistent-hint
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                outlined
                @blur="date = parseDate(dateFormatted)"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              no-title
              @input="menu1 = false"
            ></v-date-picker>
          </v-menu>

          <v-text-field
            :label="lg_account_detail_edit.txt_phone"
            v-model="account.acc_tel"
            outlined
          />

          <v-divider></v-divider>
          <!-- <span>{{ JSON.stringify(this.account) }}</span> -->
          <!-- <span>{{ account }}</span> -->
          <v-row  class="justify-center  mt-5 ">
            <v-btn outlined text @click.prevent="$router.push('/account-detail')">{{
              lg_account_detail_edit.btn_cancel
            }}</v-btn>
            <v-btn type="submit" color="primary" class="ml-6 mb-2">{{
              lg_account_detail_edit.btn_save
            }}</v-btn>
            <!-- <v-btn @click.prevent="registerMember" color="success"
                >Register</v-btn
              > -->
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
       </v-row>
    <!-- Alert Dialog -->
    <v-dialog v-model="alertDlg" max-width="60%">
      <v-card>
        <v-card-title class="headline">{{
          this.titleMessageAlert
        }}</v-card-title>

        <v-card-text class="body">
          {{ this.messageAlert }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            text
            @click="alertDlg = false"
            col="12"
            lg="12"
            md="12"
            sm="12"
          >
            Close
          </v-btn>

          <!-- <v-btn outlined color="error" text @click="confirmDelete">
            Confirm
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Alert Dialog -->

    <!-- Complete Dialog -->
    <v-dialog v-model="completeDlg" persistent max-width="60%">
      <v-card>
        <v-card-title class="headline">{{
          this.titleMessageAlert
        }}</v-card-title>

        <v-card-text class="body">
          {{ this.messageAlert }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            text
            @click="onclickcompleteDlg()"
            col="12"
            lg="12"
            md="12"
            sm="12"
          >
            Close
          </v-btn>

          <!-- <v-btn outlined color="error" text @click="confirmDelete">
                Confirm
              </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Complete Dialog -->

    <LoadingDialog></LoadingDialog>
  </v-container>
</template>

<script>
import api from "@/services/api";
import { server } from "@/services/constants";
import LoadingDialog from "@/components/cards/LoadingDialog.vue";

import { feature } from "../../services/constants";
import { lg_account_detail_edit } from "../language/lg_account_detail";

export default {
  name: "account-resetpassword",
  beforeCreate() {
    localStorage.getItem(feature.LANGUAGE) === "th"
      ? (this.lg_account_detail_edit = lg_account_detail_edit.account_detail_th)
      : (this.lg_account_detail_edit =
          lg_account_detail_edit.account_detail_en);

    if (this.resultJson == null) {
      this.checkData = false;
    }
    this.$store.state.headerfullname = false;
  },
  async mounted() {
    this.$store.state.loadMenu = true;
    this.loadAccountData();
  },
  data(vm) {
    return {
      resultJson: null,
      resultJsonCompanyName: null,
      checkData: false,
      alertDlg: false,
      completeDlg: false,
      titleMessageAlert: "",
      messageAlert: "",
      account: {
        id: 0,
        username: "",
        company_id: 0,
        acc_fullname: "",
        acc_id_card: "",
        acc_tel: "",
        acc_birth: null,
        role: "",
      },
      date: new Date().toISOString().substr(0, 10),
      dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
      menu1: false,
    };
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  components: {
    LoadingDialog,
  },
   computed: {
    widthcard() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "width:80%";
        case "sm":
          return "width:70%";
        case "md":
          return "width:60%";
        case "lg":
          return "width:50%";
        case "xl":
          return "width:50%";
      }
    },
       },
  methods: {
    onclickcompleteDlg() {
      this.completeDlg = false;
     this.$store.state.headerfullname = true;
      this.$router.push("/account-detail");
    },
    async submit() {
      // this.$router.push(`/account-edit-member/${this.username}`);

      this.$store.state.loadMenu = true;
      // this.titleMessageAlert = "กรุณาตรวจสอบข้อมูล";
      this.titleMessageAlert = this.lg_account_detail_edit.dialog_check_data;
      this.account.acc_birth = this.date;
      // alert(JSON.stringify(this.account));

      // alert("Save");
      const result_update_account = await api.updateAccount(
        this.account.id,
        this.account
      );

      // alert(JSON.stringify(result_update_account));
      if (result_update_account != null) {
        this.$store.state.loadMenu = false;
        // alert("result_update_account.data != null");
        // this.titleMessageAlert = "บันทึกข้อมูล";
        this.titleMessageAlert = this.lg_account_detail_edit.dialog_check_save;
        // this.messageAlert = "บันทึกข้อมูลสำเร็จ";
        this.messageAlert =
          this.lg_account_detail_edit.dialog_check_save_success;
        this.completeDlg = true;
        // this.$router.push("/main-page");

        this.titleMessageAlert = "Update User";
        // this.messageAlert =
        //   "หลังจาก Update ข้อมูลส่วนตัว ท่านควรทำการ LogOut แล้ว LogIn ใหม่";
        this.messageAlert = this.lg_account_detail_edit.messageAlert;
        this.alertDlg = true;
         localStorage.setItem(server.FULLNAME, this.account.acc_fullname);
      } else {
        // alert("result_update_account.data == null");
        this.$store.state.loadMenu = false;
      }
    },
    async loadAccountData() {
      this.username = localStorage.getItem(server.USERNAME);
      // alert(JSON.stringify(username));
      this.resultJson = await api.checkRegister(this.username);
      // alert(JSON.stringify(this.resultJson.data.company_id));

      this.account.id = this.resultJson.data.id;
      this.account.company_id = this.resultJson.data.company_id;
      this.account.acc_fullname = this.resultJson.data.acc_fullname;
      this.account.acc_id_card = this.resultJson.data.acc_id_card;
      this.account.username = this.resultJson.data.username;
      this.account.acc_birth = this.resultJson.data.acc_birth;
      this.dateFormatted = this.resultJson.data.acc_birth;
      this.account.acc_tel = this.resultJson.data.acc_tel;
      this.account.role = this.resultJson.data.role;

      this.checkData = true;
      this.$store.state.loadMenu = false;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${year}-${month}-${day}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>

<style></style>
