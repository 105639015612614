export const lg_footer = {
    lg_footer_th: {
        privacy_policy: "นโยบายความเป็นส่วนตัว",
        information_security_policy:"นโยบายความมั่นคงปลอดภัยสารสนเทศ",
        information_technology_security_policy:"นโยบายความปลอดภัยเทคโนโลยีสารสนเทศ",
        terms_and_conditions:"เงื่อนไขและระเบียบการ",
        request_form:"ขอให้สิทธิเจ้าของข้อมูล",
    },
    lg_footer_en: {
        privacy_policy: "Privacy Policy",
        information_security_policy:"Information Security Policy",
        information_technology_security_policy:"Information Technology Security Policy",
        terms_and_conditions:"Terms and Conditions",
        request_form:"Request The Rights Of Data Owner Form",
    },
  };
