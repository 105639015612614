<template>
  <div v-resize="onResize">
    <v-app-bar
      app
      color="#205295"
      dense
      dark
      :height="header_height"
      justify-center
      align-center
    >
      <v-layout :class="header_rotate" align-center>
        <div v-if="$store.getters['isLogin']" class="hidden-sm-and-down">
          <div :class="header_mt" v-if="this.acc_plan === 'FREE'">
            <!-- class="hidden-sm-and-down" -->
            <v-img
              src="../../assets/logo.png"
              :max-height="width"
              :max-width="width"
              sm="12"
              cols="12"
            ></v-img>
          </div>

          <!-- <div :class="header_mt" v-else>
            <v-img
              src="../../assets/logo_at_law.png"
              :max-height="width"
              :max-width="width"
              sm="12"
              cols="12"
            ></v-img>
          </div> -->
        </div>

        <div v-if="!$store.getters['isLogin']">
          <v-img
            src="../../assets/logo.png"
            :max-height="width"
            :max-width="width"
            sm="12"
            cols="12"
          ></v-img>
        </div>

        <v-row v-bind:style="{ width: '100%' }">
          <v-col cols="2" xl="2" lg="2" md="2" sm="1" xs="1" :align="mLoc">
            <v-app-bar-nav-icon
              v-if="$store.getters['isLogin']"
              @click="$store.state.navMenu = !$store.state.navMenu"
            >
            </v-app-bar-nav-icon>
            <!-- <v-btn text dark @click="onClickMenu('/')">
             <h3 v-if="!$store.getters['isLogin']">{{this.lg_header_menu.home}}</h3>
              <h4 v-if="$store.getters['isLogin']">{{this.lg_header_menu.home}}</h4>
            </v-btn>
            <v-btn text dark @click="onClickMenu('/feature')">
              <h3 v-if="!$store.getters['isLogin']">{{this.lg_header_menu.feature}}</h3>
              <h4 v-if="$store.getters['isLogin']">{{this.lg_header_menu.feature}}</h4>
            </v-btn>
            <v-btn text dark @click="onClickMenu('/contact')">
              <h3 v-if="!$store.getters['isLogin']">{{this.lg_header_menu.contact}}</h3>
              <h4 v-if="$store.getters['isLogin']">{{this.lg_header_menu.contact}}</h4>
            </v-btn>
            <v-btn text dark @click="onClickMenu('/joinmeetid')">
              <h3 v-if="!$store.getters['isLogin']">{{this.lg_header_menu.joinmeetbyid}}</h3>
              <h4 v-if="$store.getters['isLogin']">{{this.lg_header_menu.joinmeetbyid}}</h4>
            </v-btn> -->
           
          </v-col>
          <v-spacer></v-spacer>

    <v-col cols="10" :align="mLoc2" xl="10" lg="10" md="10" sm="4" xs="4">     
    <v-menu>
      <template v-slot:activator="{ attrs, on }">
        <!--  :x-small="!buttonsizesmall" -->
        <v-btn
        class="positionflag"
              text
          v-bind="attrs"
          v-on="on"
          v-bind:style="{ paddingLeft: '0', marginRight: '0.5rem' }"
        >
           <country-flag :country="defaultlanguage" size="normal" />
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="(item,idx) in chooselanguage"
          :key="idx"
          link
           @change="onChange(item.flag)"
        >
           <country-flag :country="item.flag" size="normal" />
        </v-list-item>
      </v-list>
    </v-menu>

            <div v-if="$store.getters['isLogin']" class="hidden-md-and-up" v-bind:style="{ display: 'inline' }">
              <v-btn
                id="acc-menu"
                icon
              >
                <v-icon>mdi-account</v-icon>
              </v-btn>

              <v-menu activator="#acc-menu" offset-y>
                <v-list>
                  <v-list-item>
                    <v-btn text text-transform-none @click="onClickMenu('/account-detail')">
                      <h4  v-if="$store.getters['isLogin']">
                        {{ showfullname }} (
                        {{ $store.getters["role"] }} )
                      </h4>
                    </v-btn>
                  </v-list-item>
                  <!-- <v-list-item v-if="this.acc_plan === 'FREE'">
                    <v-btn text text-transform-none v-if="this.acc_plan === 'FREE'">
                      <h4 style="color: yellow" v-if="$store.getters['isLogin']">
                        Free ( {{ this.date_exire }}-Day )
                      </h4>
                    </v-btn>
                  </v-list-item> -->
                  <v-list-item>
                    <v-btn text text-transform-none>
                      <h4 v-if="$store.getters['isLogin']">
                        {{ $store.getters["company_name"] }}
                      </h4>
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>

          <div class="hidden-sm-and-down" v-bind:style="{ display: 'inline' }">
            <v-btn text text-transform-none @click="onClickMenu('/account-detail')">
              <h4 v-if="$store.getters['isLogin']">
                {{ showfullname }} (
                {{ $store.getters["role"] }} )
              </h4>
            </v-btn>

            <!-- <v-btn text text-transform-none v-if="this.acc_plan === 'FREE'">
              <h4 style="color: yellow" v-if="$store.getters['isLogin']">
                Free ( {{ this.date_exire }}-Day )
              </h4>
            </v-btn> -->
        

            <v-btn text text-transform-none>
              <h4 v-if="$store.getters['isLogin']">
                {{ $store.getters["company_name"] }}
              </h4>
            </v-btn>
          </div>

            <v-btn
              v-if="$store.getters['isLogin']"
              icon
              @click.prevent="onClickLogOff"
            >
              <v-icon>mdi-export</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-layout>

      <!-- class="hidden-md-and-up" -->
      <!-- <v-toolbar-title>{{ title }} V{{ version }}</v-toolbar-title> -->
    </v-app-bar>
  </div>
</template>

<script>
import api from "@/services/api";
import { server } from "../../services/constants";
import { feature } from "../../services/constants";
import SwitchLang from "vue-switch-lang";
import {lg_header_menu} from '../../views/language/lg_header_menu.js';

export default {
  name: "Header",
  data: () => ({
    drawer: false,
    text_fontsize: 30,
    dialog: false,
    mLoc: "left",
    header_rotate: "",
    header_mt: "",
    acc_plan: "",
    date_exire: 0,
    role: "",
    id: "",
    menu: [
      { icon: "home", title: "หน้าหลัก" },
      { icon: "info", title: "ฟีเจอร์" },
      { icon: "info", title: "ติดต่อเรา" },
    ],
      defaultlanguage: "",
      // regexlang: "th",
      chooselanguage: [
        { name: "en", flag: "gb" },
        { name: "th", flag: "th" },
      ],
  }),
  components: {
    SwitchLang,
  },
  async mounted() {
    this.$forceUpdate();
    // this.$router.go(); // Refreshes page
    this.acc_plan = localStorage.getItem("acc_plan");
    this.role = localStorage.getItem("role");
    this.id = localStorage.getItem("user_id");
    // alert(this.id);
    // alert(this.acc_plan);
    // alert(this.role);
    const res = await api.getDateExpire(this.id);
    this.date_exire = res.data.value_expire;
    // alert(this.date_exire);
    // console.log("date_exire : " + this.date_exire);
  },
  computed: {
     showfullname() {
    return localStorage.getItem(server.FULLNAME);
  },
    version() {
      return process.env.VUE_APP_VERSION;
    },
    title() {
      return process.env.VUE_APP_TITLE;
    },
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 60;
        case "sm":
          return 60;
        case "md":
          return 60;
        case "lg":
          return 60;
        case "xl":
          return 60;
      }
    },
    header_height() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 70;
        case "sm":
          return 70;
        case "md":
          return 70;
        case "lg":
          return 70;
        case "xl":
          return 70;
      }
    },
    mLoc2(){
  switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "end";
        case "sm":
          return "end";
        case "md":
          return  "end";
        case "lg":
          return  "end";
        case "xl":
          return  "end";
      }
    },
  },
  methods: {
    onChange(lang) {
      // Do something
      // alert(lang.title);
      this.defaultlanguage = lang;
      localStorage.setItem(feature.LANGUAGE, lang);
      // alert(localStorage.getItem(feature.LANGUAGE));
      // console.log("language header : " + localStorage.getItem(feature.LANGUAGE));
      this.$router.go();
    },
    onResize: function () {
      // console.log(
      //   "Window width: " +
      //     window.innerWidth +
      //     ", breakpoint width: " +
      //     this.$vuetify.breakpoint.width +
      //     ", breakpoint name: " +
      //     this.$vuetify.breakpoint.name
      // );
      if (window.innerWidth < 600) {
        this.header_rotate = "column";
        this.header_mt = "mt-5";
        this.mLoc = "left";
      } else {
        this.header_rotate = "row";
        this.header_mt = "mt-0";
        this.mLoc = "left";
      }
    },
    onClickLogOff() {
      this.$store.dispatch("doLogout");
      // alert("Test");
    },
    onClickMenu(link) {
      // this.$store.state.navMenu = false;
      this.$router.push(link).catch((err) => {});
    },
  },
   async beforeCreate() {
      this.$store.state.page_reload = false;
      this.defaultlanguage =  localStorage.getItem(feature.LANGUAGE);
      // alert(localStorage.getItem("language"))
      // console.log("language login : " + localStorage.getItem(feature.LANGUAGE));

      localStorage.getItem(feature.LANGUAGE) === "th"
        ? this.lg_header_menu = lg_header_menu.lg_header_menu_th
        : this.lg_header_menu = lg_header_menu.lg_header_menu_en;

        // console.log("lg_login: " + JSON.stringify(this.lg_login));
    },
    async created() {
      this.$store.state.page_reload = false;
  this.defaultlanguage =  localStorage.getItem(feature.LANGUAGE);
  this.onResize()
    },
};
</script>

<style scoped>
.v-application .positionflag{
    padding-bottom: 5px;
    padding-right: 0px;
}
</style>