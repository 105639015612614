<template>
  <v-container>
     <v-row class="justify-center align-end pt-10 pb-10">
    <v-card class="mt-5 ml-5 mr-5" :style="widthcard">
      <v-card-title primary-title> {{ lg_account_detail.title }} </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form @submit.prevent="submit">
          <v-text-field
            :label="lg_account_detail.txt_company"
            v-model="this.account.company_name"
            disabled
            outlined
          />
          <v-text-field
            :label="lg_account_detail.txt_name"
            v-model="this.account.acc_fullname"
            disabled
            outlined
          />
          <v-text-field
            label="เลขบัตรประจำตัวประชาชน / Passport"
            v-model="this.account.acc_id_card"
            disabled
            outlined
            v-if="false"
          />
          <v-text-field
            :label="lg_account_detail.txt_email"
            v-model="this.account.username"
            disabled
            outlined
          />
          <v-text-field
            :label="lg_account_detail.txt_birthday"
            v-model="this.account.acc_birth"
            disabled
            outlined
          />
          <v-text-field
            :label="lg_account_detail.txt_phone"
            v-model="this.account.acc_tel"
            disabled
            outlined
          />

          <v-text-field
            :label="lg_account_detail.txt_permission"
            v-model="this.account.role"
            disabled
            outlined
          />

          <v-divider></v-divider>

          <!-- <span>{{ account }}</span> -->
         <!-- <v-row class="justify-space-between px-3 pt-5 pb-5">
            <v-btn outlined text @click.prevent="$router.push('/main-page')">{{
              lg_account_detail.btn_cancel
            }}</v-btn>-->
              <v-layout justify-center class="mt-5">
            <v-btn justify-center type="submit" color="primary">{{
              lg_account_detail.btn_edit
            }}</v-btn>
              </v-layout>
            <!-- <v-btn @click.prevent="registerMember" color="success"
                >Register</v-btn
              > -->
    
        </v-form>
      </v-card-text>
    </v-card>
   </v-row>

    <LoadingDialog></LoadingDialog>
  </v-container>
</template>

<script>
import api from "@/services/api";
import { server } from "@/services/constants";
import LoadingDialog from "@/components/cards/LoadingDialog.vue";

import { feature } from "../services/constants";
import { lg_account_detail } from "./language/lg_account_detail";

export default {
  name: "account-detail",
  beforeCreate() {
    localStorage.getItem(feature.LANGUAGE) === "th"
      ? (this.lg_account_detail = lg_account_detail.account_detail_th)
      : (this.lg_account_detail = lg_account_detail.account_detail_en);

    if (this.resultJson == null) {
      this.checkData = false;
    }
  },
  mounted() {
    this.$store.state.loadMenu = true;
    this.loadAccountData();
  },
  data() {
    return {
      resultJson: null,
      resultJsonCompanyName: null,
      checkData: false,
      username: "",
      account: {
        id: 0,
        username: "",
        company_id: 0,
        company_name: "",
        acc_fullname: "",
        acc_id_card: "",
        acc_tel: "",
        acc_birth: "",
        role: "",
      },
    };
  },
  components: {
    LoadingDialog,
  },
  computed: {
    widthcard() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "width:80%";
        case "sm":
          return "width:70%";
        case "md":
          return "width:60%";
        case "lg":
          return "width:50%";
        case "xl":
          return "width:50%";
      }
    },
       },
  methods: {
    async submit() {
      this.$router.push(`/account-edit-member`);
    },
    async loadAccountData() {
      this.username = localStorage.getItem(server.USERNAME);
      // alert(JSON.stringify(username));
      this.resultJson = await api.checkRegister(this.username);
      // alert(JSON.stringify(this.resultJson.data.company_id));
      this.resultJsonCompanyName = await api.getCompanyByID(
        this.resultJson.data.company_id
      );
      // alert(JSON.stringify(this.resultJsonCompanyName.data));
      this.account.id = this.resultJson.data.id;
      this.account.company_id = this.resultJson.data.company_id;
      this.account.acc_fullname = this.resultJson.data.acc_fullname;
      this.account.acc_id_card = this.resultJson.data.acc_id_card;
      this.account.username = this.resultJson.data.username;
      this.account.acc_birth = this.resultJson.data.acc_birth;
      this.account.acc_tel = this.resultJson.data.acc_tel;
      this.account.role = this.resultJson.data.role;
      this.account.company_name = this.resultJsonCompanyName.data.company_name;

      this.checkData = true;
      this.$store.state.loadMenu = false;
    },
  },
};
</script>

<style></style>
