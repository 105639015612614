import Vue from "vue";
import VueRouter from "vue-router";
// import Login from "@/views/Login.vue";
// import Register from "@/views/Register.vue";
// import Stock from "@/views/Stock.vue";
// import StockCreate from "@/views/StockCreate.vue";
// import StockEdit from "@/views/StockEdit.vue";
// import Report from "@/views/Report.vue";
// import About from "@/views/About.vue";

import Mainpage from "@/views/Mainpage.vue";
import Contract from "@/views/Contract.vue";
import Feature from "@/views/Feature.vue";
import CreateMeetingRoom from "@/views/CreateMeetingRoom2.vue";
import ZoomCreateMeetingRoom from "@/views/ZoomCreateMeetingRoom.vue";
import LoginAccount from "@/views/LoginAccount.vue";
import RegisterAccount from "@/views/RegisterAccount.vue";
import AccountDetail from "@/views/AccountDetail.vue";
import LicensDetail from "@/views/LicensDetail.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import UserRequest from "@/views/UserRequest.vue";
import Pdf1 from "@/views/Pdf1.vue";
import Pdf2 from "@/views/Pdf2.vue";
import Pdf3 from "@/views/Pdf3.vue";
import Pdf4 from "@/views/Pdf4.vue";
import JoinMeetID from "@/views/JoinMeetID.vue";

import CompanyConfig from "@/views/admin/CompanyConfig.vue";
import CompanyCreate from "@/views/admin/CompanyCreate.vue";
import CompanyEdit from "@/views/admin/CompanyEdit.vue";

import AppIdConfig from "@/views/admin/AppIdConfig.vue";
import AppIdCreate from "@/views/admin/AppIdCreate.vue";
import AppIdEdit from "@/views/admin/AppIdEdit.vue";

import ApiKeyConfig from "@/views/admin/ApiKeyConfig.vue";
import ApiKeyCreate from "@/views/admin/ApiKeyCreate.vue";
import ApiKeyEdit from "@/views/admin/ApiKeyEdit.vue";

import ListAccount from "@/views/ListAccount.vue";
import AccountEditAdmin from "@/views/admin/AccountEdit.vue";
import AccountEditMember from "@/views/member/AccountEditMember.vue";
import AccountEditPassword from "@/views/member/AccountEditPassword.vue";

import MeetPage from "@/views/MeetPage2.vue";
import ListRoom from "@/views/ListRoom.vue";
import ListMeetData from "@/views/ListMeetData.vue";
import VoteReportsPage from "@/views/VoteReportsPage.vue";
import ZoomMeetPage from "@/views/ZoomMeetPage.vue";
import ListRoomZoomJoin from "@/views/ListRoomZoomJoin.vue";

import DepartmentConfig from "@/views/admin/DepartmentConfig.vue";
import DepartmentEdit from "@/views/admin/DepartmentEdit.vue";
import DepartmentCreate from "@/views/admin/DepartmentCreate.vue";

import PrivacyPolicy from "@/views/PrivacyPolicy.vue";

import VerifyUser from "@/views/VerifyUser.vue";

import MeetingRecording from "@/views/MeetingRecording.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/meeting-recording",
    name: "MeetingRecording",
    component: MeetingRecording,
  },
  {
    path: "/verify-user/:meet_appid/:meet_id",
    name: "verify-user",
    component: VerifyUser,
  },
  {
    path: "/main-page",
    name: "main-page",
    component: Mainpage,
  },
  {
    path: "/department-edit/:id",
    name: "department-edit",
    component: DepartmentEdit,
  },
  {
    path: "/department-create",
    name: "department-create",
    component: DepartmentCreate,
  },
  {
    path: "/department-config",
    name: "department-config",
    component: DepartmentConfig,
  },
  {
    path: "/listmeeting",
    name: "listmeeting",
    component: ListMeetData,
  },
  {
    path: "/listroom",
    name: "listroom",
    component: ListRoomZoomJoin,
  },
  {
    path: "/account-resetpassword",
    name: "account-resetpassword",
    component: AccountEditPassword,
  },
  {
    path: "/meet/:appId/:roomName/:typeroom",
    name: "meet",
    component: ZoomMeetPage,
  },
  {
    path: "/account-edit-member",
    name: "account-edit-member",
    component: AccountEditMember,
  },
  {
    path: "/account-edit-admin/:id",
    name: "account-edit-admin",
    component: AccountEditAdmin,
  },
  {
    path: "/list-account",
    name: "list-account",
    component: ListAccount,
  },
  {
    path: "/apikey-edit/:id",
    name: "apikey-edit",
    component: ApiKeyEdit,
  },
  {
    path: "/apikey-create",
    name: "apikey-create",
    component: ApiKeyCreate,
  },
  {
    path: "/apikey-config",
    name: "apikey-config",
    component: ApiKeyConfig,
  },
  {
    path: "/appid-edit/:id",
    name: "appid-edit",
    component: AppIdEdit,
  },
  {
    path: "/appid-create",
    name: "appid-create",
    component: AppIdCreate,
  },
  {
    path: "/appid-config",
    name: "appid-config",
    component: AppIdConfig,
  },
  {
    path: "/company-edit/:id",
    name: "company-edit",
    component: CompanyEdit,
  },
  {
    path: "/company-create",
    name: "company-create",
    component: CompanyCreate,
  },
  {
    path: "/company-config",
    name: "company-config",
    component: CompanyConfig,
  },
  {
    path: "/account-detail",
    name: "account-detail",
    component: AccountDetail,
  },
  {
    path: "/licens-detail",
    name: "licens-detail",
    component: LicensDetail,
  },
  {
    path: "/login-account",
    name: "login-account",
    component: LoginAccount,
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPassword,
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: ResetPassword,
  },
  {
    path: "/register-account",
    name: "register-account",
    component: RegisterAccount,
  },
  {
    path: "/create-meet-room",
    name: "create-meet-room",
    component: ZoomCreateMeetingRoom,
  },

  {
    path: "/contact",
    name: "contact",
    component: Contract,
  },
  {
    path: "/feature",
    name: "feature",
    component: Feature,
  },
  {
    path: "/vote-reports",
    name: "VoteReportsPage",
    component: VoteReportsPage,
  },
  {
    path: "/user-request",
    name: "UserRequest",
    component: UserRequest,
  },
  {
    path: "/pdf1",
    name: "Pdf1",
    component: Pdf1,
  },
  {
    path: "/pdf2",
    name: "Pdf2",
    component: Pdf2,
  },
  {
    path: "/pdf3",
    name: "Pdf3",
    component: Pdf3,
  },
  {
    path: "/pdf4",
    name: "Pdf4",
    component: Pdf4,
  },
  {
    path: "/joinmeetid",
    name: "JoinMeetID",
    component: JoinMeetID,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/",
    redirect: "/main-page",
  },
  {
    path: "*",
    redirect: "/main-page", // page not found
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
});

export default router;