<template>
  <v-container>
    <v-row class="justify-center pt-5">
      <v-col cols="12">
        <v-card class="mx-auto pa-5" outlined>
          <v-card-title>{{ lg_appid_add.title }}</v-card-title>
          <v-divider></v-divider>
          <br />
          <v-form @submit.prevent="submit">
            <v-text-field
              outlined
              :label="lg_appid_add.appid_name"
              v-model="appid.app_id_name"
            ></v-text-field>
            <v-row>
              <v-spacer></v-spacer>
              <v-btn class="mr-4" @click.prevent="cancel">
                {{ lg_appid_add.btn_cancel }}
              </v-btn>

              <v-btn color="success" type="submit">
                {{ lg_appid_add.btn_save }}
              </v-btn>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <LoadingDialog></LoadingDialog>
    <!-- Alert Dialog -->
    <v-dialog v-model="alertDlg" max-width="60%">
      <v-card>
        <v-card-title class="headline">{{
          this.titleMessageAlert
        }}</v-card-title>

        <v-card-text class="body">
          {{ this.messageAlert }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            text
            @click="alertDlg = false"
            col="12"
            lg="12"
            md="12"
            sm="12"
          >
            Close
          </v-btn>

          <!-- <v-btn outlined color="error" text @click="confirmDelete">
            Confirm
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Alert Dialog -->
  </v-container>
</template>

<script>
import api from "@/services/api";
import LoadingDialog from "../../components/cards/LoadingDialog.vue";

import { feature } from "../../services/constants";
import { lg_appid_add } from "../language/lg_appid";

export default {
  name: "appid-create",
  beforeCreate() {
    localStorage.getItem(feature.LANGUAGE) === "th"
      ? (this.lg_appid_add = lg_appid_add.appid_add_th)
      : (this.lg_appid_add = lg_appid_add.appid_add_en);

    // console.log("lg_appid_add: " + JSON.stringify(this.lg_appid_add));
  },
  data: () => ({
    alertDlg: false,
    titleMessageAlert: "",
    messageAlert: "",
    appid: {
      app_id_name: "",
    },
  }),
  components: {
    LoadingDialog,
  },
  methods: {
    async submit() {
      // console.log(server.appid_URL);
      // console.log(JSON.stringify(this.appid));
      // alert(JSON.stringify(this.appid));
      this.$store.state.loadMenu = true;
      const result = await api.checkByNameAppId(this.appid.app_id_name);
      if (result.data != null) {
        // this.titleMessageAlert = "กรุณาตรวจสอบข้อมูล";
        // this.messageAlert = "AppId นี้ถูกใช้งานแล้ว กรุณาเปลี่ยน AppId ใหม่";
        this.titleMessageAlert = this.lg_appid_add.titleMessageAlert;
        this.messageAlert = this.lg_appid_add.messageAlert;
        this.alertDlg = true;
        this.$store.state.loadMenu = false;
      } else {
        await api.addAppId(this.appid);
        // this.$store.state.loadMenu = false;
        this.$router.back();
      }
    },
    cancel() {
      this.$router.back();
    },
  },
};
</script>

<style></style>
