export const lg_header_feature = {
  lg_header_feature_th: {
    pricingplans: "คุณสมบัติและราคา",
    titlefree: "พื้นฐาน",
    titlepro: "มืออาชีพ",
    titleenterprise: "องค์กร",
    pricesubtitlefree: "ฟรี",
    subtitlefree: " /เดือน",
    pricesubtitlepro: "150",
    subtitlepro: " ผู้ใช้ /เดือน",
    subtitleenterprise: "สำหรับลูกค้าธุรกิจ ปรับแต่งได้",
    lifree1: "ผู้ร่วมประชุม สูงสุด 25 คน",
    lifree2: "ไม่จำกัดการประชุมสูงสุด 50 นาที",
    lifree3: "ทีมแชท",
    lifree4: "ไม่ต้องใช้บัตรเครดิต",
    lipro1: "ผู้ร่วมประชุม สูงสุด 150 คน",
    lipro2: "ไม่จำกัดการประชุมสูงสุด 33 ชั่วโมง",
    lipro3: "ทีมแชท",
    lipro4: "บันทึกการประชุม",
    buttonfree: "สมัครตอนนี้",
    buttonpro: "ติดต่อเรา",
    lienterprise1: "ผู้ร่วมประชุม สูงสุด 150 คน/ประชุม 4 ชม",
    lienterprise2: "แชร์หน้าจอ",
    lienterprise3: "แชท",
    lienterprise4: "กำหนด password ก่อนเข้าห้องประชุม",
    lienterprise5: "บันทึกการประชุม",
    lienterprise6: "คำบรรยาย",
    buttonenterprise: "ติดต่อเรา",
    get_start:"เริ่มต้นใช้งาน",
    explore_features:"ดูคุณสมบัติ",
    details1:"ประชุมผ่านวิดีโอออนไลน์สำหรับธุรกิจของคุณด้วยแผนฟรีและแผนโปรของเรา ค้นพบคุณสมบัติและ",
    subdetails11:"ราคาที่เหมาะกับความต้องการของคุณ",
    subdetails12:"เพื่อยกระดับการทำงานของคุณ",
    details2:"แผนฟรี",
    subdetails21:"ปัจจุบันของเรามีการประชุมไม่จำกัดเวลาสำหรับ",
    subdetails22:"ผู้เข้าร่วมสูงสุด 10 คน",
    subdetails23:"พร้อม",
    subdetails24:"วิดีโอและเสียงระดับ HD",
    subdetails25:"และ",
    subdetails26:"การแชร์หน้าจอของโฮสต์",
    subdetails27:"ซึ่งเหมาะสำหรับการประชุมขนาดเล็กและการใช้งานส่วนตัว",
    btn_signup:"สมัครใช้งานฟรี",
    title_plan:"แผนฟรีและแผนโปร",
    details3:"สำหรับธุรกิจที่มีความต้องการเพิ่มเติม",
    subdetails31:"แผนโปร",
    subdetails32:"ของเรามีฟีเจอร์เพิ่มเติม เช่น",
    subdetails33:"ห้องร้องรับ การโหวตและการบันทึกประชุม การถอดเสียง ผู้จดบันทึกการประชุม และ ปลั๊กอินอื่นๆ",
    subdetails34:"สิ่งเหล่านี้จะช่วยให้มั่นใจว่าการประชุมทุกครั้งจะมีประสิทธิภาพ มีการบันทึกไว้อย่างครบถ้วนและปลอดภัย",
    details4:"ติดต่อเราเพื่ออัปเกรดเป็นโปร",
    subdetails41:"และปลดล็อคฟีเจอร์ของเราอย่างเต็มรูปแบบ เพื่อเพิ่มประสิทธิภาพในการประชุมของคุณ",
    titlefreeplan:"แผนฟรี",
    titleproplan:"แผนโปร",
    titlepricingplan:"ราคา",
    titlefreeplandetails1:"แผนฟรีของเรา สามารถให้คุณเปิดการประชมทั้งหมด",
    titlefreeplandetails2:"ไม่จำกัดครั้ง,",
    titlefreeplandetails3:"ร่วมถึงมีแชททีมและการแบ่งปันหน้าจอ",
    titleproplandetails1:"แผนโปร",
    titleproplandetails2:"ของเรา เหมาะสำหรับทีมขนาดใหญ่และธุรกิจทุกประเภท มีทุกอย่างในแผนฟรีพร้อมด้วย",
    titlepricingplandetails1:"แผนโปร",
    titlepricingplandetails2:"เริ่มต้นเพียง",
    titlepricingplandetails3:"$11/เดือน และสามารถปรับเปลี่ยน",
    titlepricingplandetails4:"ตามที่คุณต้องการได้ ติดต่อเราวันนี้เพื่อดูข้อเสนอ",
    titlepricingplandetails5:"ราคาของคุณ",
    titlefreeplanoptions1:"ผู้เข้าร่วมสูงสุด 10 คน",
    titlefreeplanoptions2:"การแบ่งปันหน้าจอเฉพาะโฮสต์",
    titlefreeplanoptions3:"ทีมแชทแบบกลุ่ม",
    titleproplanoptions1:"ผู้เข้าร่วมสูงสุด 300 คน",
    titleproplanoptions2:"การแบ่งปันหน้าจอ",
    titleproplanoptions3:"ทีมแชทแบบกลุ่มและแบบส่วนตัว",
    titleproplanoptions4:"การโหวต",
    titleproplanoptions5:"ห้องรองรับ",
    titleproplanoptions6:"การบันทึกและการถอดเสียงพูดด้วยระบบคลาวด์ (ตัวเลือกเสริม)",
    titleproplanoptions7:"ผู้จดบันทึกการประชุม (ตัวเลือกเสริม)",
  },
  lg_header_feature_en: {
    pricingplans: "Features and Pricing",
    titlefree: "Basic",
    titlepro: "Pro",
    titleenterprise: "Enterprise",
    pricesubtitlefree: "Free",
    subtitlefree: " /Month",
    pricesubtitlepro: "150",
    subtitlepro: " Users /Month",
    subtitleenterprise: "Customizable for Business Customers",
    lifree1: "Host up to 25 attendees",
    lifree2: "Unlimited meetings for up to 50 minutes",
    lifree3: "Team Chat",
    lifree4: "No credit card required",
    lipro1: "Host up to 150 attendees",
    lipro2: "Unlimited meetings for up to 33 hours",
    lipro3: "Team Chat",
    lipro4: "Record Minutes of Meeting",
    buttonfree: "Register Now",
    buttonpro: "Contact Us",
    lienterprise1: "Participants up to 150 people/4 hours meeting",
    lienterprise2: "Share screen",
    lienterprise3: "Chat",
    lienterprise4: "Set passwords for meeting rooms",
    lienterprise5: "Record Meetings",
    lienterprise6: "With subtitles",
    buttonenterprise: "Contact Us",
    get_start:"Get Started",
    explore_features:"Explore Features",
    details1:"Unlock seamless video conferencing for your business with our",
    subdetails11:"free and pro",
    subdetails12:"plans. Discover the features and",
    subdetails13:"pricing that fit your needs",
    subdetails14:"and take your remote collaboration to the next level.",
    details2:"Our current",
    subdetails21:"Free Plan",
    subdetails22:"offers",
    subdetails23:"unlimited meetings",
    subdetails24:"for up to",
    subdetails25:"10 participants,",
    subdetails26:"with HD video and audio, and",
    subdetails27:"host screen sharing.",
    subdetails28:"It's the perfect solution for small meetings and personal use.",
    btn_signup:"Sign Up Free",
    title_plan:"Free and Pro Plans",
    details3:"For businesses with more advanced needs, our",
    subdetails31:"Pro Plan",
    subdetails32:"includes additional features such as a",
    subdetails33:"waiting room, voting and polling, recording, transcription, a professional minute-taker and other plugins.",
    subdetails34:"This ensures that every meeting is productive, well-documented, and all key points are accurately captured.",
    details4:"Contact us to upgrade to Pro",
    subdetails41:"and unlock the full potential of our platform, enhancing the productivity and efficiency of your meetings.",
    titlefreeplan:"Free Plan",
    titleproplan:"Pro Plan",
    titlepricingplan:"Pricing",
    titlefreeplandetails1:"Our free plan offers",
    titlefreeplandetails2:"unlimited",
    titlefreeplandetails3:"video calls, team chat and screen sharing.",
    titleproplandetails1:"The pro plan unlocks advanced features for larger teams and businesses. Our",
    titleproplandetails2:"free plan plus.",
    titlepricingplandetails1:"The",
    titlepricingplandetails2:"pro plan",
    titlepricingplandetails3:"can start as low as",
    titlepricingplandetails4:"$11/month.",
    titlepricingplandetails5:"Our Pro plans",
    titlepricingplandetails6:"can be tailored",
    titlepricingplandetails7:"for each of our customers, contact us today to",
    titlepricingplandetails8:"get your price.",
    titlefreeplanoptions1:"Up to 10 participants",
    titlefreeplanoptions2:"Host-only Screen sharing",
    titlefreeplanoptions3:"Public Team Chat",
    titleproplanoptions1:"Up to 300 participants",
    titleproplanoptions2:"Screen sharing for all",
    titleproplanoptions3:"Public AND Private Team Chat",
    titleproplanoptions4:"Vote and polling",
    titleproplanoptions5:"Waiting room",
    titleproplanoptions6:"Recording and Transcription (optional)",
    titleproplanoptions7:"Professional minute-taker (optional)",
  },
};
