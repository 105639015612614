export const lg_mainpage = {
  lg_mainpage_th: {
      title: "ยกระดับการสื่อสารทางธุรกิจของคุณ",
      subtitle1: "ให้การทำงานร่วมกันง่ายขึ้น และทำให้ลูกค้าของคุณประทับใจด้วย การประชุมระดับองค์กรที่ปลอดภัยของเรา แพลตฟอร์มของเราสามารถเข้าถึงได้จากอุปกรณ์ใดก็ได้ โดยนำเสนอการแชร์หน้าจอที่ราบรื่น คำอธิบายประกอบแบบเรียลไทม์ และการควบคุมการประชุมที่มีประสิทธิภาพ เพื่อให้การประชุมเสมือนจริงของคุณมีประสิทธิภาพและเป็นมืออาชีพ",
      subtitle2: "ทั้งหมดนี้รวมอยู่ในที่เดียวใน Dmeets และใช้งานได้ฟรี",
      privacy_consent:"การให้ความยินยอมเรื่องความเป็นส่วนตัว",
      text1:"DCM. จะปฏิบัติต่อข้อมูลส่วนบุคคลใด ๆ ที่ท่านได้ให้ไว้เป็นความลับและจะประมวลผลข้อมูลดังกล่าวตามเงื่อนไขและนโยบายความเป็นส่วนตัวของ DCM. เท่านั้น",
      text2:"ข้าพเจ้าได้อ่านและตกลงกับเงื่อนไขและนโยบายความเป็นส่วนตัวของ DCM.",
      accept:"ยอมรับ",
      notaccept:"ไม่ยอมรับ",
      ourpricingplans:"รายละเอียดราคา",
      titlemeeterror:"ผิดพลาด",
      contentmeeterror:"สิ้นสุดการประชุม",
      contentmeetiderror:"Meeting ID ไม่ถูกต้อง",
      titlefeature:"คุณสมบัติ",
      high_quality_video:"วิดีโอคุณภาพสูง",
      high_quality_video_details:"วิดีโอที่คมชัดพร้อมรองรับ 1080p HD รับประกันประสบการณ์ระดับมืออาชีพ",
      intuitive_interface:"อินเทอร์เฟซที่ใช้งานง่าย",
      intuitive_interface_details:"อินเทอร์เฟซที่ใช้งานง่ายของเราทำให้การโฮสต์ เข้าร่วม และจัดการวิดีโอเป็นเรื่องง่าย",
      seamless_collaboration:"การทำงานร่วมกันอย่างราบรื่น",
      seamless_collaboration_details:"แชร์หน้าจอของคุณพร้อมกัน เหมาะสำหรับการเปรียบเทียบเอกสารเคียงข้างกัน และรวบรวมข้อมูลหรือโหวตได้ทันทีด้วยโพลแบบโต้ตอบ",
      pricing:"ราคา",
      pricing_details:"เริ่มต้นใช้งานฟรีด้วยแผนพื้นฐานของเรา เหมาะสำหรับการใช้ส่วนบุคคลหรือทีมขนาดเล็ก หรืออัปเกรดเป็นแผนโปรที่คุ้มค่าและเหมาะสมสำหรับธุรกิจขนาดใหญ่ มีคุณสมบัติเพิ่มเติม เช่น การประชุมที่ยาวนานกว่า เพิ่มจำนวนผู้เข้าร่วม และการควบคุมการดูแลระดับสูง",
      get_in_touch:"ติดต่อเรา",
      get_in_touch_details1:"หากมีคำถามหรือข้อเสนอแนะ ทีมงานของเราพร้อมให้บริการคุณ แค่ส่งอีเมลไปที่",
      get_in_touch_details2:"หรือไปที่หน้า",
      contact_us:"ติดต่อเรา",
      get_in_touch_details3:"แล้วเราจะติดต่อกลับให้เร็วที่สุด",
      

    },
    lg_mainpage_en: {
      title: "Enhance Your Online Meetings with Dmeets",
      subtitle1: "Boost business communication with Dmeets' secure, enterprise-grade video conferencing solution. Our platform features professional minute-takers, seamless screen sharing, real-time annotation, and robust meeting controls. Accessible from any device, Dmeets ensures your virtual meetings are productive and professional. Perfect for businesses seeking top-notch online meeting solutions.",
      subtitle2: "All in Dmeets and it's free to use.",
      privacy_consent:"Privacy Consent",
      text1:"DCM will treat any personal information you provide confidential and will only process such information in accordance to DCM Terms and Privacy Policy.",
      text2:"I have read and agree to DCM Terms and Privacy Policy.",
      accept:"Accept",
      notaccept:"Deny",
      ourpricingplans:"Our Pricing Plans",
      titlemeeterror:"Error",
      contentmeeterror:"The meeting was finished",
      contentmeetiderror:"Meeting ID Incorrect",
      titlefeature:"Features of Dmeets Online Meetings",
      high_quality_video:"High-Quality Video",
      high_quality_video_details:"Experience crystal-clear video with 1080p HD support, ensuring a professional and engaging online meeting.",
      intuitive_interface:"Intuitive Interface",
      intuitive_interface_details:"Easily host, join, and manage video calls with our user-friendly interface.",
      seamless_collaboration:"Seamless Collaboration",
      seamless_collaboration_details:"Share your screen, compare complex documents side by side, and gather instant insights with interactive polls for efficient collaboration.",
      pricing:"Pricing",
      pricing_details:"Start for free with our basic plan for personal use or small teams. Upgrade to our affordable Pro plan for expanded enterprise-grade features like longer meetings, more participants, and advanced admin controls.",
      get_in_touch:"Get in Touch",
      get_in_touch_details1:"Have a question or feedback? Our friendly customer support team is here to assist you. Just send an email to",
      get_in_touch_details2:"or visit our",
      contact_us:"Contact Us",
      get_in_touch_details3:"page and we'll get back to you as soon as possible.",

    },
  };
  